import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { PrescriberAddressBook, PrescriberAlternateAddress, PrescriberDetailItem, PrescriberDetailWebResponse, PrescriberInfoAll, PrescriberSearchItem, VerifyProviderResponse } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { PrescriberAddressBookRequest, PrescriberDetailWebRequest, VerifyProviderRequest, changeDefaultPrescriberRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { PrescriberSearchCriteria } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { Observable } from 'rxjs';
import { Status } from 'src/app/modals/webresponsestatus';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyProviderService {

    
  private _SELECTED_PRESCRIBER_KEY : string="selectedPrescriber";
  public get SELECTED_PRESCRIBER_KEY() : string {
    return this._SELECTED_PRESCRIBER_KEY;
  }

  
  private _PRESCRIBER_RESULTS : string = "prescribersearchresults";
  public get PRESCRIBER_RESULTS() : string {
    return this._PRESCRIBER_RESULTS;
  }
  
  
  private _SEARCH_CRITERIA_KEY : string="prescribersearchcriteria";
  public get SEARCH_CRITERIA_KEY() : string {
    return this._SEARCH_CRITERIA_KEY;
  }

  
  private _SELECTED_PRESCRIBER_DETAIL_KEY : string="prescriberdetail";
  public get SELECTED_PRESCRIBER_DETAIL_KEY() : string {
    return this._SELECTED_PRESCRIBER_DETAIL_KEY;
  }
  public set SELECTED_PRESCRIBER_DETAIL_KEY(v : string) {
    this._SELECTED_PRESCRIBER_DETAIL_KEY = v;
  }

  constructor(private http: HttpClient, private sentenseCasePipe:StringToSentenceCase, private logger: LoggerService, private profileService:ProfileService) {}
  getRecentPresbrDtls(webrequest:VerifyProviderRequest): Promise<VerifyProviderResponse> {
    debugger;
    const promise: Promise<VerifyProviderResponse> = new Promise<VerifyProviderResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getRecentPresbrDtlsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
         this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getPresbrDtls(webrequest:PrescriberDetailWebRequest): Promise<PrescriberDetailWebResponse> {
    debugger;
    const promise: Promise<PrescriberDetailWebResponse> = new Promise<PrescriberDetailWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.prescriberDetailUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
         this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getPrescriberValidAlternativeAddresses(addresses:PrescriberAlternateAddress[]):PrescriberAlternateAddress[]{
    if(Array.isArray(addresses) && addresses.length>0){
      return addresses.filter((address:PrescriberAlternateAddress)=>{
        return address.contact!=undefined && address.contact.fax!=undefined && address.contact.fax.length==10?true:false;
      });
    }
    return [];
  }
  
  getAlternativeAddressLine3(altAddress:PrescriberAlternateAddress):string{
    if(altAddress!=undefined && altAddress.address!=undefined){
      if(altAddress.address.city!=undefined && altAddress.address.city.length>0){
        return this.sentenseCasePipe.transform(altAddress.address.city)+", "+altAddress.address.state+" "+altAddress.address.zip1;
      } else {
        return altAddress.address.state+" "+altAddress.address.zip1;
      }
    }
    return "";
  }

  getSelectedPrescriberAddress3(givenPrescriber?:PrescriberSearchItem):string{
    const prescriber: PrescriberSearchItem | undefined = (givenPrescriber!=undefined)?givenPrescriber:this.readSelectedPrescriber();
    if(prescriber!=undefined && typeof prescriber.address=="object"){
      if(prescriber.address.city!=undefined && prescriber.address.city.length>0){
        return this.sentenseCasePipe.transform(prescriber.address.city)+", "+prescriber.address.state+" "+prescriber.address.zip1;
      } else{
        return prescriber.address.state+" "+prescriber.address.zip1;
      }
    }
    return "";
  }

  storePrescriberResults(results:PrescriberSearchItem[]):void{
    if(Array.isArray(results)){
      sessionStorage.setItem(this.PRESCRIBER_RESULTS,JSON.stringify(results));
    }
  }

  readPrescriberResults():PrescriberSearchItem[] | undefined{
    const results:string | null = sessionStorage.getItem(this.PRESCRIBER_RESULTS);
    if(results!=null && results.length>0){
      return JSON.parse(results);
    }
    return undefined;
  }
  clearPrescriberResults():void{
    sessionStorage.removeItem(this.PRESCRIBER_RESULTS);
  }

  storePrescriberSearchCriteria(prescriber:PrescriberSearchCriteria):void{
    if(prescriber!=undefined){
      sessionStorage.setItem(this.SEARCH_CRITERIA_KEY, JSON.stringify(prescriber));
    }
  }

  readPrescriberSearchCriteria():PrescriberSearchCriteria | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SEARCH_CRITERIA_KEY);
    if(prescriber!=null && prescriber.length>0)
    {
      return JSON.parse(prescriber)
    }
    return undefined;
  }

  clearPrescriberSearchCriteri():void{
    sessionStorage.removeItem(this.SEARCH_CRITERIA_KEY);
  }

  storeSelectedPrescriber(prescriber:PrescriberSearchItem | undefined):void{
    if(prescriber!=undefined && prescriber!=null)
      sessionStorage.setItem('selectedPrescriber',JSON.stringify(prescriber));
  }

  readSelectedPrescriber():PrescriberSearchItem | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SELECTED_PRESCRIBER_KEY);
    if(prescriber != null){
      return JSON.parse(prescriber);
    }
    return undefined;
  }

  clearSelectedPrescriber():void{
    sessionStorage.removeItem(this.SELECTED_PRESCRIBER_KEY);
  }

  storeSelectedPrescriberDetail(prescriberdetail:PrescriberDetailItem):void{
    if(prescriberdetail!=undefined){
      sessionStorage.setItem(this.SELECTED_PRESCRIBER_DETAIL_KEY,JSON.stringify(prescriberdetail));
    }
  }

  readSelectedPrescriberDetail(): PrescriberDetailItem | undefined{
    const prescriber:string | null = sessionStorage.getItem(this.SELECTED_PRESCRIBER_DETAIL_KEY);
    if(prescriber!=null){
      return JSON.parse(prescriber);
    }
    return undefined;
  }

  clearSelectedPrescriberDetail():void{
    sessionStorage.removeItem(this.SELECTED_PRESCRIBER_DETAIL_KEY);
  }

  clearSession():void{
    this.clearPrescriberResults();
    this.clearPrescriberSearchCriteri();
    this.clearSelectedPrescriber();
    this.clearSelectedPrescriberDetail();
  }

  //  getAdrBkPrescribers(webrequest:PrescriberAddressBookRequest):Observable<PrescriberAddressBook>{
  //   debugger;
  //   //  const promise:Promise<PrescriberAddressBook> = new Promise<PrescriberAddressBook>((resolve,reject)=>{
  //     let httpParams: HttpParams = new HttpParams();
  //     httpParams = httpParams.append('adminPresbrId', webrequest.adminPresbrId);
  //       let header=new HttpHeaders({"endpoint":environment.getAdrBkPrescribersUrl.replace("{adminPresbrId}", webrequest.adminPresbrId)});
  //       const req = new HttpRequest('GET', environment.proxyurl, {
  //         reportProgress: true,
  //         responseType: 'json',
  //         headers:header,
  //         params:httpParams
  //       });
  //   return this.http.request(req);

  // }

  // getAdrBkPrescribers(webrequest:PrescriberAddressBookRequest): Promise<PrescriberAddressBook> {
  //   const promise: Promise<PrescriberAddressBook> = new Promise<PrescriberAddressBook>((resolve, reject) => {
  //       let header=new HttpHeaders({"endpoint":environment.getAdrBkPrescribersUrl.replace("{adminPresbrId}", webrequest.adminPresbrId)});
  //       this.http.get(environment.proxyurl,{headers:header}).subscribe((response: any) => {
  //     // this.resumePASubscription = this.http.post(environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid), null).subscribe((response: any) => {
  //       if (response != undefined && response.status != undefined
  //         && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
  //         resolve(response);
  //       } else {
  //         this.logger.log(response);
  //         reject(response);
  //       }
  //     }, (error: any) => {
  //       this.logger.error(error);
  //       reject(error);
  //     });
  //   });
  //   return promise;
  // } 

  // changeDefaultPrescriberForAdmin(webrequest:changeDefaultPrescriberRequest):Promise<Status>{
  //   const promise:Promise<Status> = new Promise<Status>((resolve,reject)=>{
  //     let header=new HttpHeaders({"endpoint":environment.changeDefaultprescriberUrl});
  //     this.http.post(environment.proxyurl,webrequest,{headers:header}).subscribe((response: any) => {
  //       debugger;
  //       if(response!=undefined && response.status!=undefined 
  //         && response.status.statusCode!=undefined
  //         && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT.toLowerCase()){
  //           resolve(response);
  //         } else {
  //         reject(response);
  //         }
  //     },(error:any)=>{
  //         reject(error);
  //     });
  //   });
  //   return promise;
  // }
  
}

