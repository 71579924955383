import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { approveOrDenyPrescriberRequest } from 'src/app/modals/verifyPrescriber/approveOrDenyPrescriberRequest';
import { approveOrDenyPrescriberResponse } from 'src/app/modals/verifyPrescriber/approveOrDenyPrescriberResponse';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyprescriberService {

  constructor(private http: HttpClient, private profileService:ProfileService ) { }
  url = environment.proxyurl

  postApproveOrDenyPrescriber(webrequest: approveOrDenyPrescriberRequest): Promise<approveOrDenyPrescriberResponse> {
    const promise: Promise<approveOrDenyPrescriberResponse> = new Promise<approveOrDenyPrescriberResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.approveOrDenyPrescriberUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.statusCode != undefined && response.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
}
