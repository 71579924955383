import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APPCONSTANTS, BOOLEAN_FLAG, PRESCRIBER_QUALIFIER, USER_ROLE } from 'src/app/constants/appconstants';
import { Prescriber, User } from 'src/app/modals/registration/SteptwoRegisterUserRequest';
import { SteponeLinkProvisionRequest } from 'src/app/modals/registration/SteponeLinkProvisionRequest';
import { SteponeLinkProvisionResponse } from 'src/app/modals/registration/SteponeLinkProvisionResponse';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { SteponePharmacyDetailsResponse } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { SteponePrescriberDetailsRequest } from 'src/app/modals/registration/SteponePrescriberDetailsRequest';
import { SteponePrescriberDetailsResponse } from 'src/app/modals/registration/SteponePrescriberDetailsResponse';
import { SteptwoRegisterUserRequest } from 'src/app/modals/registration/SteptwoRegisterUserRequest';
import { SteptwoUpdateStatusRequest } from 'src/app/modals/registration/SteptwoUpdateStatusRequest';
import { SteptwoUpdateStatusResponse } from 'src/app/modals/registration/SteptwoUpdateStatusResponse';
import { cancelRegistrationRequest } from 'src/app/modals/registration/cancelRegistrationRequest';
import { cancelRegistrationResponse } from 'src/app/modals/registration/cancelRegistrationResponse';

import { RegistrationhelperService } from 'src/app/services/registrationhelper/registrationhelper.service';
import { SteptwoRegisterUserResponse } from 'src/app/modals/registration/SteptwoRegisterUserResponse';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registersteptwo',
  templateUrl: './registersteptwo.component.html',
  styleUrls: ['./registersteptwo.component.css']
})
export class RegistersteptwoComponent implements OnInit {

  constructor(private registrationHelper: RegistrationhelperService, private fb: FormBuilder, private registrationService: registrationService, private router: Router) { }
  ngOnInit(): void {
    var temp = sessionStorage.getItem('loginuserdata');
    if (temp != undefined || temp != null) {

      this.loginUserData = JSON.parse(temp);
    }
    if (this.loginUserData != null && this.loginUserData.reActivateInd != undefined && this.loginUserData.reActivateInd == BOOLEAN_FLAG.YES) {
      this.userDeactivated = true;
    }
    this.newSession();
  }
  userDeactivated: boolean = false;
  loginUserData!: any;
  faxes: string[] = [];
  lastname: string = '';
  middleInitial: string = ''
  firstname: string = '';
  degree: string = '';
  NPINumber: string = '';
  address1: string = '';
  address2: string = '';
  state: string = '';
  zipCode: string = '';
  pharmacyName: string = '';
  city: string = '';
  userRoleFlag: string | null = '';
  userRoleValue!: number;

  filterStatus: string = '';
  errorPrescriberScenarioMessage: boolean = false;
  errorPharmacyScenarioMessage: boolean = false;

  openNoneErrorPopup: boolean = false;
  setNoneErrorPopup() {
    this.openNoneErrorPopup = true;
    this.filterStatus = 'none';
  }
  removeNoneErrorPopup() {
    this.openNoneErrorPopup = false;

  }


  selectedFax = this.fb.group({
    faxValue: ['', [Validators.required]]

  });
  noPharmacyFaxErrorCode: boolean = false;
  noPrescriberFaxErrorCode: boolean = false;
  flag!: boolean;
  isFormValid(): boolean {
    return this.selectedFax.valid;
  }


  newSession() {
    this.errorPharmacyScenarioMessage = false;
    this.errorPrescriberScenarioMessage = false;
    let faxDetails: string | null = sessionStorage.getItem('faxDetails');
    if (faxDetails == null) {
      // let checkErrorScenario1: string | null = sessionStorage.getItem('errorPrescriberScenario');
      // let checkErrorScenario2: string | null = sessionStorage.getItem('errorPharmacyScenario');
      // //if error scenario then no need to call 2 apis again
      // if (checkErrorScenario1 != null || checkErrorScenario2 != null) {
      //   if (checkErrorScenario1 != null) {
      //     this.errorPrescriberScenarioMessage = true;
      //   }
      //   else if (checkErrorScenario2 != null)
      //     this.errorPharmacyScenarioMessage = true;
      // }
      // else if (checkErrorScenario1 == null && checkErrorScenario2 == null) {

      var apiParamters = new SteponeLinkProvisionRequest();
      let tempobject = sessionStorage.getItem('selfregistration');
      if (tempobject != null) {
        const selfregistrationdetails = JSON.parse(tempobject);
        apiParamters.provisioningRequestId = selfregistrationdetails.currentpage.linReqId;
      }
      apiParamters.presbrId = '';
      apiParamters.reActivateInd = BOOLEAN_FLAG.NO;

      this.registrationService.postLinkProvision(apiParamters).then((data: SteponeLinkProvisionResponse) => {
        if (data.status.statusCode == 'Success') {

          sessionStorage.setItem('linkProvisionResponse', JSON.stringify(data));
          if (data.role == 10 || data.role == 20) {
            //remove user selected role
            if (sessionStorage.getItem('selectedPrescriberRole') != null) {
              sessionStorage.removeItem('selectedPrescriberRole');
            }
            //save user selected role
            if (data.role == 10) {
              sessionStorage.setItem('selectedPrescriberRole', '10');
              this.userRoleValue = USER_ROLE.PRESCRIBER;
            }
            else if (data.role == 20) {
              sessionStorage.setItem('selectedPrescriberRole', '20');
              this.userRoleValue = USER_ROLE.ADMIN;
            }

            var prescriber = new SteponePrescriberDetailsRequest();
            prescriber.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
            prescriber.instanceId = data.instcID;
            prescriber.prescriberId = data.npi;
            this.registrationService.postPrescriberDetails(prescriber).then((data: SteponePrescriberDetailsResponse) => {
              if (data.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
                if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
                  sessionStorage.setItem('faxDetails', JSON.stringify(data));
                  console.log('fax details are saved now');
                  let prevDataCheck2 = sessionStorage.getItem('userRole');
                  if (prevDataCheck2 != null) {
                    sessionStorage.removeItem('userRole');
                  }
                  sessionStorage.setItem('userRole', 'prescriber');
                  this.displayFax();
                  this.userRoleFlag = sessionStorage.getItem('userRole');
                }
                else {
                  // sessionStorage.setItem('errorPrescriberScenario', 'true');
                  this.errorPrescriberScenarioMessage = true;
                }

              }
              else {
                // sessionStorage.setItem('errorPrescriberScenario', 'true');
                this.errorPrescriberScenarioMessage = true;
              }
            });
          }
          else if (data.role == 11 || data.role == 13) {
            //remove user selected role
            if (sessionStorage.getItem('selectedPharmacyRole') != null) {
              sessionStorage.removeItem('selectedPharmacyRole');
            }
            //save user selected role
            if (data.role == 11) {
              sessionStorage.setItem('selectedPharmacyRole', '11');
              this.userRoleValue = USER_ROLE.PHARMACIST;
            }
            else if (data.role == 13) {
              sessionStorage.setItem('selectedPharmacyRole', '13');
              this.userRoleValue = USER_ROLE.SPECIALTYPHARMACIST;
            }
            var pharmacy = new SteponePharmacyDetailsRequest();
            pharmacy.pharmacyIdQualifier = PRESCRIBER_QUALIFIER.NPI;
            pharmacy.instanceId = data.instcID;
            pharmacy.pharmacyId = data.npi;
            this.registrationService.postPharmacyDetails(pharmacy).then((data: SteponePharmacyDetailsResponse) => {

              if (data.status.statusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
                sessionStorage.setItem('faxDetails', JSON.stringify(data));
                console.log('fax details are saved now');
                let prevDataCheck2 = sessionStorage.getItem('userRole');
                if (prevDataCheck2 != null) {
                  sessionStorage.removeItem('userRole');
                }
                sessionStorage.setItem('userRole', 'pharmacy');
                this.displayFax();
                this.userRoleFlag = sessionStorage.getItem('userRole');
              }
              else {
                // sessionStorage.setItem('errorPharmacyScenario', 'true');
                this.errorPharmacyScenarioMessage = true;
              }
            });
          }
        }
      });

    }
    else if (faxDetails != null) {
      this.storeUserSelectedRole();
      console.log('fax details found');
      this.displayFax();
      this.userRoleFlag = sessionStorage.getItem('userRole');
    }
  }

  storeUserSelectedRole() {
    let userRole: string | null = sessionStorage.getItem('userRole');
    if (userRole == 'prescriber') {
      let roleValue: string | null = sessionStorage.getItem('selectedPrescriberRole');
      if (roleValue == USER_ROLE.PRESCRIBER.toString()) {
        this.userRoleValue = USER_ROLE.PRESCRIBER;
      }
      else if (roleValue == USER_ROLE.ADMIN.toString()) {
        this.userRoleValue = USER_ROLE.ADMIN;
      }

    }
    else if (userRole == 'pharmacy') {
      let roleValue: string | null = sessionStorage.getItem('selectedPharmacyRole');
      if (roleValue == USER_ROLE.PHARMACIST.toString()) {
        this.userRoleValue = USER_ROLE.PHARMACIST;
      }
      else if (roleValue == USER_ROLE.SPECIALTYPHARMACIST.toString()) {
        this.userRoleValue = USER_ROLE.SPECIALTYPHARMACIST;
      }

    }
  }

  //popup code
  showPopup: boolean = false;
  showErrorPopup: boolean = false;

  openPopup() {
    this.showPopup = true;
  }
  closePopup() {
    this.showPopup = false;
  }
  onYesClick() {
    //write the code
    this.closePopup();
    this.onCancelRegistration();
  }
  onNoClick() {
    //write the code
    this.closePopup();
  }
  openErrorPopup() {
    this.showErrorPopup = true;
  }
  closeErrorPopup() {
    this.showErrorPopup = false;
  }

  underline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.add('underlined');
  }

  removeUnderline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.remove('underlined');
  }

  onCancelRegistration() {

    var cancelRequest1 = new cancelRegistrationRequest();
    // var cancelRequest2 = new SteponeRegisterOptumIdStatusRequest();
    let tempobject = sessionStorage.getItem('selfregistration');
    if (tempobject != null) {
      const userInfoSessionObject = JSON.parse(tempobject);
      cancelRequest1.optumID = userInfoSessionObject.ohid;
      // cancelRequest2.optumID=userInfoSessionObject.ohid;
      cancelRequest1.uuid = userInfoSessionObject.uuid;
      if (this.userDeactivated == true) {
        cancelRequest1.linkRequestId = '0';
      }
      else {
        cancelRequest1.linkRequestId = userInfoSessionObject.currentpage.linReqId;
      }
    }

    cancelRequest1.isRegistraionLinkCancel = true;
    cancelRequest1.pcmsStatusCode = '5'
    cancelRequest1.linkStatusCode = '5';
    console.log(cancelRequest1);

    this.registrationService.postCancelRegistration(cancelRequest1).then((data: cancelRegistrationResponse) => {
      if (data.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        console.log('registration in step 2 cancelled');
        const cancelReg = sessionStorage.getItem('cancelReg');
        if (cancelReg == null) {
          sessionStorage.setItem('cancelReg', 'true');
        }
        // sessionStorage.removeItem('errorPrescriberScenario');
        // sessionStorage.removeItem('errorPharmacyScenario');
        sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('faxDetails');
        this.router.navigateByUrl('/register/stepone');
        this.registrationHelper.getRegistrationStatus();
      }
      else {
        this.openErrorPopup();

      }
    });

  }
  displayFax() {
    let userRole: string | null = sessionStorage.getItem('userRole');
    if (userRole == 'prescriber') {
      let prescriberDetails = new SteponePrescriberDetailsResponse();
      let tempobject1 = sessionStorage.getItem('faxDetails');
      if (tempobject1 != null) {
        prescriberDetails = JSON.parse(tempobject1);
        console.log(prescriberDetails);
        this.lastname = prescriberDetails.response.prescriberDetailItem.lastName;
        this.lastname = this.lastname.toLowerCase();
        this.firstname = prescriberDetails.response.prescriberDetailItem.firstName;
        this.firstname = this.firstname.toLowerCase();
        this.middleInitial = prescriberDetails.response.prescriberDetailItem.middleInitial;
        this.degree = prescriberDetails.response.prescriberDetailItem.primaryDegree;
        for (let i = 0; i < prescriberDetails.response.prescriberDetailItem.identifiers.length; i++) {
          if (prescriberDetails.response.prescriberDetailItem.identifiers[i].qualifier == "01") {
            this.NPINumber = prescriberDetails.response.prescriberDetailItem.identifiers[i].id;
          }
        }
        this.address1 = prescriberDetails.response.prescriberDetailItem.address.address1;
        this.address1 = this.address1.toLowerCase();
        this.address2 = prescriberDetails.response.prescriberDetailItem.address.city;
        this.address2 = this.address2.toLowerCase();
        this.state = prescriberDetails.response.prescriberDetailItem.address.state;
        this.zipCode = prescriberDetails.response.prescriberDetailItem.address.zip1;
      }
      console.log(this.firstname, this.lastname, this.degree, this.NPINumber, this.address1, this.address2, this.state, this.zipCode);

      let tempobject2 = sessionStorage.getItem('faxDetails');
      if (tempobject2 != null) {
        var prescriberFax: SteponePrescriberDetailsResponse = JSON.parse(tempobject2);

        for (let i = 0; i < prescriberFax.response.prescriberDetailItem.alternateAddresses.length; i++) {
          if (prescriberFax.response.prescriberDetailItem.alternateAddresses[i].contact.fax != "0") {
            let faxslice = prescriberFax.response.prescriberDetailItem.alternateAddresses[i].contact.fax;
            faxslice = faxslice.slice(0, 3) + '-' + faxslice.slice(3, 6) + '-' + faxslice.slice(6);
            //condition to check if the fax doesnt already exit in faxes array
            if (this.faxes.indexOf(faxslice) == -1) {
              this.faxes.push(faxslice);
            }
          }
        }
        if (this.faxes.length == 0) {
          this.noPrescriberFaxErrorCode = true;
        }
      }
    }
    else if (userRole == 'pharmacy') {

      let pharmacyDetails = new SteponePharmacyDetailsResponse();
      let tempobject1 = sessionStorage.getItem('faxDetails');
      if (tempobject1 != null) {
        pharmacyDetails = JSON.parse(tempobject1);
        //console.log(pharmacyDetails);
        this.pharmacyName = pharmacyDetails.pharmacyDetailItems[0].pharmacyName;
        this.address1 = pharmacyDetails.pharmacyDetailItems[0].storeAddress.address1;
        this.address1 = this.address1.toLowerCase();
        this.city = pharmacyDetails.pharmacyDetailItems[0].storeAddress.city;
        this.city = this.city.toLowerCase();
        this.state = pharmacyDetails.pharmacyDetailItems[0].storeAddress.state;
        this.zipCode = pharmacyDetails.pharmacyDetailItems[0].storeAddress.zip1;
      }
      console.log(this.pharmacyName, this.address1, this.city, this.state, this.zipCode);
      let tempobject2 = sessionStorage.getItem('faxDetails');
      if (tempobject2 != null) {
        var pharmacyFax: SteponePharmacyDetailsResponse = JSON.parse(tempobject2);
        if (pharmacyFax.pharmacyDetailItems[0].fax == "0") {
          this.noPharmacyFaxErrorCode = true;
        }
        else {
          for (let i = 0; i < pharmacyFax.pharmacyDetailItems.length; i++) {
            // this.faxes.push(pharmacyFax.pharmacyDetailItems[i].fax);
            let faxslice = pharmacyFax.pharmacyDetailItems[i].fax;
            faxslice = faxslice.slice(0, 3) + '-' + faxslice.slice(3, 6) + '-' + faxslice.slice(6);
            this.faxes.push(faxslice);
          }
        }
      }
    }
  }

  onBackClick() {
    var paramters = new SteptwoUpdateStatusRequest();
    let tempobject = sessionStorage.getItem('selfregistration');
    if (tempobject != null) {
      const selfregistrationdetails = JSON.parse(tempobject);
      if (this.userDeactivated == true) {
        paramters.linkReqID = '0';
      }
      else {
        paramters.linkReqID = selfregistrationdetails.currentpage.linReqId;
      }

      paramters.uuid = selfregistrationdetails.uuid;
      paramters.optumID = selfregistrationdetails.ohid;
    }
    paramters.presbrId = '';
    if (this.userDeactivated == true) {
      paramters.reActivateInd = BOOLEAN_FLAG.YES;
    }
    else {
      paramters.reActivateInd = BOOLEAN_FLAG.NO;
    }
    paramters.presQualTypeID = PRESCRIBER_QUALIFIER.NPI;
    tempobject = sessionStorage.getItem('linkProvisionResponse');
    if (tempobject != null) {
      const linkProvisonparameters = JSON.parse(tempobject);
      paramters.instcID = linkProvisonparameters.instcID;
      paramters.npi = linkProvisonparameters.npi;
    }
    let userRole: string | null = sessionStorage.getItem('userRole');
    if (userRole == 'prescriber') {
      let roleValue: string | null = sessionStorage.getItem('selectedPrescriberRole');
      if (roleValue != null) {
        paramters.role = roleValue;
      }
    }
    else if (userRole == 'pharmacy') {
      let roleValue: string | null = sessionStorage.getItem('selectedPharmacyRole');
      if (roleValue != null) {
        paramters.role = roleValue;
      }

    }
    //call the service
    this.registrationService.postUpdateStatus(paramters).then((data: SteptwoUpdateStatusResponse) => {
      if (data.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        // sessionStorage.removeItem('errorPrescriberScenario');
        // sessionStorage.removeItem('errorPharmacyScenario');
        sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('faxDetails');
        this.router.navigateByUrl('/register/stepone');
        this.registrationHelper.getRegistrationStatus();
      }
      else {
        this.openErrorPopup();

      }
    });

  }
  onNextClick() {
    //check if user slected a fax  
    if (this.selectedFax.value.faxValue == "" || this.selectedFax.value.faxValue == null) {
      this.openErrorPopup();
    }
    //if user selectes a fax then...
    else {
      var input = new SteptwoRegisterUserRequest();
      var input1 = new SteponeLinkProvisionRequest();
      input.prescriber = new Prescriber();
      input.user = new User();

      var tempobject1 = sessionStorage.getItem('userRole');


      if (tempobject1 == 'prescriber') {
        tempobject1 = sessionStorage.getItem('selectedPrescriberRole');
        let selectFaxAddress = new SteponePrescriberDetailsResponse();
        let tempobject3 = sessionStorage.getItem('faxDetails');
        if (tempobject3 != null) {
          selectFaxAddress = JSON.parse(tempobject3);
        }
        let faxcleared = this.selectedFax.value.faxValue;
        faxcleared = faxcleared.slice(0, 3) + faxcleared.slice(4, 7) + faxcleared.slice(8);

        for (let i = 0; i < selectFaxAddress.response.prescriberDetailItem.alternateAddresses.length; i++) {
          if (faxcleared == selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].contact.fax) {
            input.prescriber.lastName = selectFaxAddress.response.prescriberDetailItem.lastName;
            input.prescriber.educationQualification = selectFaxAddress.response.prescriberDetailItem.primaryDegree;
            input.prescriber.streetAddress = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.address1;
            input.prescriber.city = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.city;
            input.prescriber.state = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.state;
            input.prescriber.zipCode = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.zip1;
            input.prescriber.firstName = selectFaxAddress.response.prescriberDetailItem.firstName;
            input.prescriber.phoneNumber = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].contact.phone1;
            input.prescriber.email = "" + selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].contact.email;
            input.prescriber.addrLine2 = "" + selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.address2;
            input.prescriber.country = "" + selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.country;
            if (this.userRoleValue == USER_ROLE.ADMIN) {
              input.user.lastName = selectFaxAddress.response.prescriberDetailItem.lastName;
              input.user.firstName = selectFaxAddress.response.prescriberDetailItem.firstName;
              input.user.streetAddress = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.address1;
              input.user.city = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.city;
              input.user.state = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.state;
              input.user.zipCode = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.zip1;
              input.user.phoneNumber = selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].contact.phone1;
              input.user.addrLine2 = "" + selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.address2;
              input.user.country = "" + selectFaxAddress.response.prescriberDetailItem.alternateAddresses[i].address.country;
              input.user.email = "";
              input.user.educationQualification = "";
            }
          }
        }

      }
      else if (tempobject1 == 'pharmacy') {
        tempobject1 = sessionStorage.getItem('selectedPharmacyRole');
        let selectFaxAddress = new SteponePharmacyDetailsResponse();
        let tempobject3 = sessionStorage.getItem('faxDetails');
        if (tempobject3 != null) {
          selectFaxAddress = JSON.parse(tempobject3);
        }
        console.log(selectFaxAddress)
        let faxcleared = this.selectedFax.value.faxValue;
        faxcleared = faxcleared.slice(0, 3) + faxcleared.slice(4, 7) + faxcleared.slice(8);

        for (let i = 0; i < selectFaxAddress.pharmacyDetailItems.length; i++) {
          if (faxcleared == selectFaxAddress.pharmacyDetailItems[i].fax) {
            input.prescriber.lastName = "";
            input.prescriber.educationQualification = "";
            input.prescriber.streetAddress = selectFaxAddress.pharmacyDetailItems[i].storeAddress.address1;
            input.prescriber.city = selectFaxAddress.pharmacyDetailItems[i].storeAddress.city;
            input.prescriber.state = selectFaxAddress.pharmacyDetailItems[i].storeAddress.state;
            input.prescriber.zipCode = selectFaxAddress.pharmacyDetailItems[i].storeAddress.zip1;
            input.prescriber.firstName = "";
            input.prescriber.phoneNumber = selectFaxAddress.pharmacyDetailItems[i].primaryPhone;
            input.prescriber.email = "";
            input.prescriber.addrLine2 = "" + selectFaxAddress.pharmacyDetailItems[i].storeAddress.address2;
            input.prescriber.country = "" + selectFaxAddress.pharmacyDetailItems[i].storeAddress.country

          }
        }

      }
      var tempobject = sessionStorage.getItem('selfregistration');
      if (tempobject != null) {
        var userInfoSessionObject = JSON.parse(tempobject);
      }
      if (this.userDeactivated == true) {
        input.linkRequestId = '0';
      }
      else {
        input.linkRequestId = userInfoSessionObject.currentpage.linReqId;
      }
      input1.provisioningRequestId = userInfoSessionObject.currentpage.linReqId;

      if (this.userDeactivated == true) {
        input.reActivateInd = BOOLEAN_FLAG.YES;
      }
      else {
        input.reActivateInd = BOOLEAN_FLAG.NO;
      }
      if (this.userRoleValue == USER_ROLE.ADMIN) {
        input.user.optumID = userInfoSessionObject.ohid;
        input.user.uuid = userInfoSessionObject.uuid;
        input.prescriber.optumID = "";
        input.prescriber.uuid = "";
      }
      else {
        input.prescriber.optumID = userInfoSessionObject.ohid;
        input.prescriber.uuid = userInfoSessionObject.uuid;
      }
      if (tempobject1 != null) {
        input.role = JSON.parse(tempobject1);
      }
      tempobject = sessionStorage.getItem('linkProvisionResponse');
      if (tempobject != null) {

        var linkProvisonparameters = JSON.parse(tempobject);
      }
      input.prescriber.instanceId = linkProvisonparameters.instcID;
      if (this.userRoleValue == USER_ROLE.ADMIN) {
        input.user.npi = linkProvisonparameters.npi;
        input.user.presbrQualTypeId = "";
        input.user.instanceId = "";
      }
      input.prescriber.presbrQualTypeId = PRESCRIBER_QUALIFIER.NPI;
      input.prescriber.npi = linkProvisonparameters.npi;

      let faxcleared = this.selectedFax.value.faxValue;
      faxcleared = faxcleared.slice(0, 3) + faxcleared.slice(4, 7) + faxcleared.slice(8);
      if (this.userRoleValue == USER_ROLE.ADMIN) {
        input.user.faxNumber = '' + faxcleared;
      }

      input.prescriber.faxNumber = '' + faxcleared;

      input1.presbrId = "";
      input1.reActivateInd = BOOLEAN_FLAG.NO;







      //write the api call funtion code here

      this.registrationService.postRegisterUser(input).then((data: SteptwoRegisterUserResponse) => {
        if (data.status.statusCode == 'Success') {
          this.registrationHelper.getRegistrationStatus().then((resp) => {
            if (resp == true) {
              this.registrationService.postLinkProvision(input1).then((data1: SteponeLinkProvisionResponse) => {
                if (data1.status.statusCode == 'Success') {
                  let prevDataCheck1 = sessionStorage.getItem('linkProvisionResponse');
                  if (prevDataCheck1 != null) {
                    sessionStorage.removeItem('linkProvisionResponse');
                  }
                  sessionStorage.setItem('linkProvisionResponse', JSON.stringify(data1));
                  this.router.navigateByUrl('/register/stepthree');
                }
                else {
                  this.openErrorPopup();
                }

              });
            }
          });
        }
        else {
          this.openErrorPopup();
        }


      });

    }
  }
  loginToPreCheckMyScript() {
    // window.open("https://precheckmyscript-stage.uhcprovider.com");
    window.location.href = "https://localhost/rxlinkapp-msepa/";

  }


  goToPrivacyLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.privacyurl, "_blank");
  }
  goToTermsLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.termsurl, "_blank");
  }
  goToContactusLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.contactusurl, "_blank");
  }
  //end brace
}


