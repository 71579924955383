export class Status{
    statusCode!:string;
	statusDesc!:string;
	respCode!:string;
	respMessage!:string;
	responseDescriptionCode!:string;
	dbStatusCode!:string;
	dbStatusDesc!:string;
	errorMessage!:string;
	customErrorCode!:string;
}

export interface ResponseRootObj{
	presbrFavId:number;
	status:Status;
}