import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
// import { SavedPARequest, EPAPoolWebRequest, EPACancellationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { SavedPAResponse, SavedPARequestBody, PAInitiationPatient, PAInitiationMedicationPrescribed, PAInitiationPrescriber, SavedPAResumeDetails, PARefIDWebResponse, EpaInitiationResponse, SavedPABody, SavedPAAttachment, PAReferenceID, EpaCancellationWebResponse, EpaResponse, PaattchmentResponse, EpaInitiationAckWebResponse, PAInitiationResponse, ResubmitSavedPA } from 'src/app/modals/epa/priorauthresponse';
import { SavedPAMedicationPrescribed, SavedPADrugCoded, SavedPADrugQuantity, SavedPAQSRequest, SavedPASolicitedModel, PAInitiationWebRequest, EpaRunnerInitiationEvent, EPAPoolWebRequest, SavedPARequest, EPACancellationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { MedicationViewModal, QuestionsetViewmodal, Patient, BenefitsCoordination, Prescriber, QuestionSet, QuestionSetOperation, Question, UserGivenAnswer, AnswerValue, PATIENT_GENDER, PatientGender } from 'src/app/modals/epa/questionsetviewmodal';
// import { APPCONSTANTS, BOOLEAN_FLAG, EPA_TRANSACTIon_STATUS } from 'src/app/constants/appconstant';
import { TimestamptoServerTime, ServertimeToStandardDate, } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { Status } from 'src/app/modals/webresponsestatus';
// import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
// import { PATIENT_GENDER, PatientGender } from 'src/app/modals/epa/questionsetviewmodal';
import { ServertimeToDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
// import { PAInitiationWebRequest, EpaRunnerInitiationEvent } from 'src/app/modals/epa/priorauthrequest';
// import { EpaInitiationAckWebResponse, PAInitiationResponse } from 'src/app/modals/epa/priorauthresponse';
import { PrescriberAddress, PrescriberAlternateAddress } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { LoggerService } from '../logger/logger.service';
import { APPCONSTANTS, BOOLEAN_FLAG, EPA_TRANSACTIon_STATUS } from 'src/app/constants/appconstants';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { json } from 'body-parser';
import { VerifyProviderService } from '../verifyProvider/verify-provider.service';

@Injectable({
  providedIn: 'root'
})
export class EpaprocessorService {

  private saveSubscription!: Subscription;
  private paRefSubscription!: Subscription;
  private paInitiationSubscription!: Subscription;
  private resumePASubscription!: Subscription;
  private fetchQSSubscription!: Subscription;
  private paCancellationResponseSubscription!: Subscription;
  private paCancellationSubscription!: Subscription;
  private cancelPASubscription!: Subscription;
  private fetchPAResponseSubscription!: Subscription;
  private paSubmittedResumeSubscription!: Subscription;
  private downloadSubscription!: Subscription;
  private resubmitPASubscription!: Subscription;

  epaSubscription: BehaviorSubject<EpaRunnerInitiationEvent> = new BehaviorSubject<EpaRunnerInitiationEvent>(new EpaRunnerInitiationEvent());

  constructor(private http: HttpClient,
    private timestampToServerTime: TimestamptoServerTime,
    private loginProfile: ProfileService,
    private memberService: MembersearchService,
    private servertimeToDisplayString: ServertimeToDisplayString,
    private prescriberSearch: PrescriberSearchService,
    private logger: LoggerService,
    private providerHelper:VerifyProviderService,
    private profileService: ProfileService) { }


  private extractPatientFromSavedPADetails(_patient: PAInitiationPatient): Patient {
    const patient: Patient = new Patient();
    patient.memberId = _patient.identification.patientAccountNumber;
    patient.firstName = _patient.name.firstName;
    patient.lastName = _patient.name.lastName;
    patient.gender = _patient.gender;
    patient.dateOfBirth = this.timestampToServerTime.transform(_patient.dateOfBirth.date);
    patient.addressLine1 = _patient.address.addressLine1;
    patient.city = _patient.address.city;
    patient.stateProvince = _patient.address.stateProvince;
    patient.postalCode = _patient.address.postalCode;
    patient.countryCode = _patient.address.countryCode;

    const member: MemberSearchV5Item | undefined = this.memberService.getSelectedMember();
    if (member != undefined) {
      patient.instanceId = member.instanceId;
      patient.accountId = member.accountId;
      patient.carrierId = member.carrierId;
      patient.groupId = member.groupId;
    }

    return patient;
  }
  private extractMedicationFromSavedPADetails(_medication: PAInitiationMedicationPrescribed): MedicationViewModal {
    const medication: MedicationViewModal = new MedicationViewModal();
    medication.drugNDAname = _medication.drugDescription;
    medication.daysupply = _medication.daysSupply;
    medication.drugNDC = _medication.drugCoded.productCode.code;
    medication.drugQualifier = _medication.drugCoded.productCode.qualifier;

    medication.quantity = _medication.quantity.value;
    medication.quantityQualifier = _medication.quantity.codeListQualifier;
    medication.quantityCode = _medication.quantity.quantityUnitOfMeasure.code;
    return medication;
  }
  private extractPrescriberFromSavedPADetails(_prescriber: PAInitiationPrescriber): Prescriber {
    const prescriber: Prescriber = new Prescriber();
    prescriber.npi = _prescriber.identification.npi;
    prescriber.lastName = _prescriber.name.lastName;
    prescriber.firstName = _prescriber.name.firstName;
    prescriber.addressLine1 = _prescriber.address.addressLine1;
    prescriber.city = _prescriber.address.city;
    prescriber.stateProvince = _prescriber.address.stateProvince;
    prescriber.postalCode = _prescriber.address.postalCode;
    prescriber.countryCode = _prescriber.address.countryCode;
    prescriber.primaryTelephoneNumber = _prescriber.communicationNumbers.primaryTelephone.number;
    if (_prescriber.communicationNumbers.fax.length > 0)
      prescriber.faxNumber = _prescriber.communicationNumbers.fax[0].number;

    return prescriber;
  }
  storeQuestionIDsAnswered(questionIDList: string[]): void {
    if (questionIDList != undefined && Array.isArray(questionIDList)) {
      sessionStorage.setItem("questionsAnswered", JSON.stringify(questionIDList));
    }
  }

  getQuestionIDsAnswered(): string[] | null {
    const questionIDListstr: string | null = sessionStorage.getItem("questionsAnswered");
    if (questionIDListstr != undefined || questionIDListstr != null) {
      const questionIDList: string[] = JSON.parse(questionIDListstr);
      return questionIDList;
    }
    return null;
  }

  storeQuestionSetViewModal(modal: QuestionsetViewmodal): void {
    if (modal != undefined)
      sessionStorage.setItem("questionsetviewmodal", JSON.stringify(modal));
  }
  getQuestionSetViewModal(): QuestionsetViewmodal | null {
    const modalstr: string | null = sessionStorage.getItem("questionsetviewmodal");
    try {
      if (modalstr != undefined || modalstr != null) {
        const modalValue: QuestionsetViewmodal = JSON.parse(modalstr);
        return modalValue;
      }
    } catch (e) {
      //console.log("Error in reading question set view modal browser session");
    }

    return null;
  }

  extactQuestionSetModalFromSavedPA(savedPABody: SavedPABody, resumeDetails?: SavedPAResumeDetails): QuestionsetViewmodal {
    const questionset: QuestionsetViewmodal = new QuestionsetViewmodal();
    //savedPABody.parequest exists when questionnarie is resumed and saved into DB through either auto save or save for later atleast once.
    //savedPABody.painitiationResponse exists as soon as Questionnarie is received from PAS.

    if (savedPABody.painitiationResponse != undefined) {
      const savedPADetails: PAInitiationResponse = savedPABody.painitiationResponse;

      questionset.patient = this.extractPatientFromSavedPADetails(savedPADetails.patient.humanPatient);
      questionset.medication = this.extractMedicationFromSavedPADetails(savedPADetails.medicationPrescribed);
      questionset.prescriber = this.extractPrescriberFromSavedPADetails(savedPADetails.prescriber.nonVeterinarian);

      questionset.pacaseID = savedPABody.painitiationResponse.response.responseStatus.open.pacaseID;
      questionset.deadlineForReply = savedPADetails.response.responseStatus.open.deadlineForReply.dateTime;
      questionset.paAttachment = new SavedPAAttachment();
      questionset.questionSetHeader = savedPADetails.response.responseStatus.open.questionSet.header;
      questionset.questions = savedPADetails.response.responseStatus.open.questionSet.question;
      questionset.paReferenceID = new PAReferenceID();
      questionset.paReferenceID.paRefID = savedPABody.painitiationResponse.pareferenceID;

      questionset.benefitsCoordination = Array<BenefitsCoordination>();
      if (Array.isArray(savedPADetails.benefitsCoordination) && savedPADetails.benefitsCoordination.length > 0) {
        const benefitsCoordination: BenefitsCoordination = new BenefitsCoordination();
        benefitsCoordination.cardholderID = savedPADetails.benefitsCoordination[0].cardholderID;
        benefitsCoordination.pBMMemberID = savedPADetails.benefitsCoordination[0].pbmmemberID;
        questionset.benefitsCoordination.push(benefitsCoordination);
      }
    }
    else {
      const savedPADetails: SavedPARequestBody = savedPABody.parequest;

      questionset.patient = this.extractPatientFromSavedPADetails(savedPADetails.patient.humanPatient);
      questionset.medication = this.extractMedicationFromSavedPADetails(savedPADetails.medicationPrescribed);
      questionset.prescriber = this.extractPrescriberFromSavedPADetails(savedPADetails.prescriber.nonVeterinarian);

      if (resumeDetails != undefined) {
        questionset.pacaseID = resumeDetails.paCaseId;
        questionset.deadlineForReply = resumeDetails.deadlineReply;
        questionset.paAttachment = resumeDetails.paAttachment;
        questionset.paReferenceID = resumeDetails.paReferenceID;
      }
      questionset.questionSetHeader = savedPADetails.request.solicitedModel.questionSet.header;
      questionset.questions = savedPADetails.request.solicitedModel.questionSet.question;

      questionset.benefitsCoordination = Array<BenefitsCoordination>();
      if (Array.isArray(savedPADetails.benefitsCoordination) && savedPADetails.benefitsCoordination.length > 0) {
        const benefitsCoordination: BenefitsCoordination = new BenefitsCoordination();
        benefitsCoordination.cardholderID = savedPADetails.benefitsCoordination[0].cardholderID;
        benefitsCoordination.pBMMemberID = savedPADetails.benefitsCoordination[0].pbmmemberID;
        questionset.benefitsCoordination.push(benefitsCoordination);
      }
    }
    if (Array.isArray(questionset.questions) && questionset.questions.length > 0) {
      questionset.questions.forEach((question: Question, qindex: number) => {
        if (question.questionType.select != undefined && Array.isArray(question.questionType.select.answer)) {
          const transformedUserAnswer: UserGivenAnswer<AnswerValue>[] = [];
          question.questionType.select.answer.forEach((questionAnswer: UserGivenAnswer<AnswerValue>, Aindex: number) => {
            const transformedAnswerValue: AnswerValue = new AnswerValue();
            transformedAnswerValue.choiceID = questionAnswer.prescriberProvidedAnswer.choiceID;
            transformedAnswerValue.choiceText = questionAnswer.prescriberProvidedAnswer.choiceText || questionAnswer.prescriberProvidedAnswer.additionalFreeText;
            transformedUserAnswer.push({ prescriberProvidedAnswer: transformedAnswerValue });
          });
          question.questionType.select.answer = transformedUserAnswer;
        }
      });
    }
    return questionset;
  }

  extractSavedPARequestFromQuestionSetViewModal(questionsetviewmodal: QuestionsetViewmodal, operation: QuestionSetOperation): SavedPARequest {
    const webrequest: SavedPARequest = new SavedPARequest();
    webrequest.pAReferenceID = questionsetviewmodal.paReferenceID.paRefID;

    webrequest.benefitsCoordination = webrequest.benefitsCoordination.concat(questionsetviewmodal.benefitsCoordination);

    webrequest.patient = questionsetviewmodal.patient;

    webrequest.prescriber = questionsetviewmodal.prescriber;
    webrequest.prescriber.uuid = "" + this.loginProfile.loginuser?.UUID;
    webrequest.prescriber.presbrId = "" + this.loginProfile.loginuser?.prescriber?.presbrId;

    webrequest.medicationPrescribed = new SavedPAMedicationPrescribed();
    webrequest.medicationPrescribed.daysSupply = questionsetviewmodal.medication.daysupply;
    webrequest.medicationPrescribed.drugDescription = questionsetviewmodal.medication.drugNDAname;
    webrequest.medicationPrescribed.drugCoded = new Array<SavedPADrugCoded>();
    webrequest.medicationPrescribed.drugCoded.push({
      code: questionsetviewmodal.medication.drugNDC,
      qualifier: questionsetviewmodal.medication.drugQualifier
    });
    webrequest.medicationPrescribed.quantity = new Array<SavedPADrugQuantity>();
    webrequest.medicationPrescribed.quantity.push({
      value: questionsetviewmodal.medication.quantity,
      code: questionsetviewmodal.medication.quantityCode,
      codeListQualifier: questionsetviewmodal.medication.quantityQualifier
    });


    webrequest.request = new SavedPAQSRequest();
    webrequest.request.solicitedModel = new SavedPASolicitedModel();
    webrequest.request.solicitedModel.pacaseID = questionsetviewmodal.pacaseID;
    webrequest.request.solicitedModel.questionSet = new QuestionSet();
    webrequest.request.solicitedModel.questionSet.header = questionsetviewmodal.questionSetHeader;
    webrequest.request.solicitedModel.questionSet.question = questionsetviewmodal.questions;

    /***********************
     * Scenario1:
     * isAutoSave(false), isSaveForLater(false) : 3rd part of EPA transaction. Otherwords, Submit PA request.
     * isAutoSave(false), isSaveForLater(true): Save for later. Browser will redirect back to home screen.
     * isAutoSave(true), isSaveForLater(false): Save or update question set during auto save timer.
     * isAutoSave(true), isSaveForLater(true): Save or update question set during next question load.
     */
    switch (operation) {
      case QuestionSetOperation.NEW_QUESTION_LOADED:
        webrequest.isAutoSave = true;
        webrequest.isSaveForLater = true;
        break;
      case QuestionSetOperation.AUTOSAVE:
        webrequest.isAutoSave = true;
        webrequest.isSaveForLater = false;
        break;
      case QuestionSetOperation.SAVE_FOR_LATER:
        // Generally, when a PA case is resumed by user, it will be locked by that user ID. No other user can resume it though they have access to this PA case since it is locked for a user already.
        // If this operation is save for later, rest API unlocks this pa case so that any other user who have access to this PA case can resume it from now on.
        webrequest.isAutoSave = false;
        webrequest.isSaveForLater = true;
        break;
      case QuestionSetOperation.SUBMIT_PA_REQUEST:
        webrequest.isAutoSave = false;
        webrequest.isSaveForLater = false;
        break;
    }

    webrequest.isAttachmentExists = (questionsetviewmodal.paAttachment.fileName != undefined && !questionsetviewmodal.paAttachment.isDeleted) ? true : false;

    const questionIDsAnswered: string[] | null = this.getQuestionIDsAnswered();

    if (Array.isArray(questionIDsAnswered) && questionIDsAnswered.length > 0) {
      const lastQuestionIDAnswered: string = questionIDsAnswered[questionIDsAnswered.length - 1];
      if (lastQuestionIDAnswered == 'end') {
        webrequest.saveIndicator = BOOLEAN_FLAG.NO;
      } else {
        webrequest.saveIndicator = lastQuestionIDAnswered;
      }

      return webrequest;
    }

    return webrequest;

  }

  generatePaRefId(uuid: string): Promise<PARefIDWebResponse> {
    const promise: Promise<PARefIDWebResponse> = new Promise<PARefIDWebResponse>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.generatePARefIdUrl.replace("{UUID}", uuid) ,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, null, { headers: header }).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  cancelPARefGenerationSubscription(): void {
    if (this.paRefSubscription != undefined)
      this.paRefSubscription.unsubscribe();
  }
  private mapPatientDetailsToPAInitiationRequest(selectedMedicine: SelectedMedicine, webrequest: PAInitiationWebRequest): void {
    if (selectedMedicine != undefined && webrequest != undefined) {
      debugger;

      webrequest.patient.patientAccountNumber = selectedMedicine.memberDetailResponse.memberItems[0].memberId
      webrequest.patient.lastName = selectedMedicine.memberDetailResponse.memberItems[0].lastName;
      webrequest.patient.firstName = selectedMedicine.memberDetailResponse.memberItems[0].firstName;
      const gender: PatientGender | undefined = PATIENT_GENDER.get(selectedMedicine.memberDetailResponse.memberItems[0].demographics.gender);
      if (gender != undefined)
        webrequest.patient.gender = gender;
      webrequest.patient.dateOfBirth = this.servertimeToDisplayString.transform(selectedMedicine.memberDetailResponse.memberItems[0].demographics.dateOfBirth);
      webrequest.patient.addressLine1 = selectedMedicine.memberDetailResponse.memberItems[0].contact.address1;
      webrequest.patient.city = selectedMedicine.memberDetailResponse.memberItems[0].contact.city;
      webrequest.patient.stateProvince = selectedMedicine.memberDetailResponse.memberItems[0].contact.state;
      webrequest.patient.postalCode = selectedMedicine.memberDetailResponse.memberItems[0].contact.zip;
      webrequest.patient.countryCode = selectedMedicine.memberDetailResponse.memberItems[0].contact.country;
      webrequest.patient.instanceId = selectedMedicine.memberDetailResponse.memberItems[0].sourceSystemInstance;
      webrequest.patient.groupId = selectedMedicine.memberDetailResponse.memberItems[0].groupId;
      webrequest.patient.accountId = selectedMedicine.memberDetailResponse.memberItems[0].accountId;
      webrequest.patient.carrierId = selectedMedicine.memberDetailResponse.memberItems[0].carrierId;
    }
  }
  private mapDrugDetailsToPAInitiationRequest(selectedMedicine: SelectedMedicine, webrequest: PAInitiationWebRequest): void {
    if (selectedMedicine != undefined && webrequest != undefined) {

      webrequest.medicationPrescribed.drugDescription = selectedMedicine.drugSearchItem.detailedDrugName;
      webrequest.medicationPrescribed.daysSupply = selectedMedicine.Days.toString();
      webrequest.medicationPrescribed.drugCoded.push({ code: selectedMedicine.drugSearchItem.productId, qualifier: "ND" });
      debugger;
      webrequest.medicationPrescribed.quantity.push({ code: "C78476", value: selectedMedicine.Quantity, codeListQualifier: "38" });
      webrequest.medicationPrescribed.productIdQlfr = selectedMedicine.drugSearchItem.productIdQlfr;
    }
  }
  private mapProviderDetailsToPAInitiationRequest(selectedMedicine: SelectedMedicine, webrequest: PAInitiationWebRequest) {
    debugger;
    if (webrequest != undefined && selectedMedicine != undefined
      && ((selectedMedicine.selectedPrescriberDetail != undefined && Array.isArray(selectedMedicine.selectedPrescriberDetail.alternateAddresses)
        && selectedMedicine.selectedPrescriberDetail.selectedAlternativeAddress < selectedMedicine.selectedPrescriberDetail.alternateAddresses.length) || selectedMedicine.selectedPrescriber != undefined)) {

          //     /*********************************Login user mapping*************************** */
        webrequest.prescriber.uuid = "" + this.loginProfile.loginuser?.prescriber?.uuid;
        webrequest.prescriber.presbrId = "" + this.loginProfile.loginuser?.prescriber?.presbrId;

      if (selectedMedicine.selectedPrescriberDetail != undefined) {
        const selectedPrescriberAddress: PrescriberAlternateAddress = this.providerHelper.getPrescriberValidAlternativeAddresses(selectedMedicine.selectedPrescriberDetail.alternateAddresses)[selectedMedicine.selectedPrescriberDetail.selectedAlternativeAddress];

        
        /*****************Login user NPI might have multiple contactes.
         * Hence mapping the selected contact details except Fax and phone.
         * Because, User can still edit the fax & phone from UI to any random values.
         * So fax and phone numbers are being passed in change prescriber object.********** */
        webrequest.prescriber.npi = "" + selectedMedicine.selectedPrescriberDetail.identifiers.find((identifier: any) => identifier.qualifier=='01')?.id;
        webrequest.prescriber.lastName = "" + selectedMedicine.selectedPrescriberDetail.lastName;
        webrequest.prescriber.firstName = "" + selectedMedicine.selectedPrescriberDetail.firstName;
        webrequest.prescriber.primaryTelephoneNumber = "" + selectedMedicine.selectedPrescriberDetail.contact.phone1;
        webrequest.prescriber.faxNumber = "" + selectedMedicine.selectedPrescriberDetail.contact.fax;
        
        webrequest.prescriber.addressLine1 = selectedPrescriberAddress.address.address1;
        webrequest.prescriber.city = selectedPrescriberAddress.address.city;
        webrequest.prescriber.postalCode = selectedPrescriberAddress.address.zip1;
        webrequest.prescriber.stateProvince = selectedPrescriberAddress.address.state;
        webrequest.prescriber.countryCode = selectedPrescriberAddress.address.country;


        //     /***********NPI selected for ePA(Through NPI search in SMAP and NPI's accessible by login user in PCMS)******** */

        debugger;
        // if (sessionStorage.getItem("prescrbr") != undefined && (sessionStorage.getItem("epaResubmit") != undefined || sessionStorage.getItem("epaMemberResubmit") != undefined)) {
        //   const chgdPrescriber = JSON.parse("" + sessionStorage.getItem("prescrbr"));
        //   webrequest.changedPrescriber.firstName = chgdPrescriber.firstName;
        //   webrequest.changedPrescriber.lastName = chgdPrescriber.lastName;
        //   webrequest.changedPrescriber.addressLine1 = chgdPrescriber.address1;
        //   webrequest.changedPrescriber.city = chgdPrescriber.city;
        //   webrequest.changedPrescriber.npi = chgdPrescriber.npi;
        //   debugger;
        //   webrequest.changedPrescriber.presbrId = "" + this.loginProfile.loginuser?.prescriber?.presbrId;

        // } else {
          webrequest.changedPrescriber.presbrId = "" + this.loginProfile.loginuser?.prescriber?.presbrId;
          webrequest.changedPrescriber.firstName = selectedMedicine.selectedPrescriberDetail.firstName;
          webrequest.changedPrescriber.lastName = selectedMedicine.selectedPrescriberDetail.lastName;
          webrequest.changedPrescriber.addressLine1 = selectedPrescriberAddress.address.address1;
          webrequest.changedPrescriber.city = selectedPrescriberAddress.address.city;
          webrequest.changedPrescriber.stateProvince = selectedPrescriberAddress.address.state;
          webrequest.changedPrescriber.postalCode = selectedPrescriberAddress.address.zip1;
          webrequest.changedPrescriber.countryCode = selectedPrescriberAddress.address.country;
          webrequest.changedPrescriber.npi = "" + selectedMedicine.selectedPrescriberDetail.identifiers.find((identifier: any) => identifier.qualifier=='01')?.id;
          webrequest.changedPrescriber.primaryTelephoneNumber = selectedPrescriberAddress.contact.phone1;
          webrequest.changedPrescriber.faxNumber = selectedPrescriberAddress.contact.fax;
        // }

      } else {
        const selectedPrescriberAddress: PrescriberAddress=selectedMedicine.selectedPrescriber.address;

        // When the selectedPrescriberDetails is undefinded and we are mapping address from searched presciber
        /*****************Login user NPI might have multiple contactes.
         * Hence mapping the selected contact details except Fax and phone.
         * Because, User can still edit the fax & phone from UI to any random values.
         * So fax and phone numbers are being passed in change prescriber object.********** */
        webrequest.prescriber.npi = "" + selectedMedicine.selectedPrescriber.identifiers.find((identifier: any) => identifier.qualifier=='01')?.id;
        webrequest.prescriber.lastName = "" + selectedMedicine.selectedPrescriber.lastName;
        webrequest.prescriber.firstName = "" + selectedMedicine.selectedPrescriber.firstName;
        webrequest.prescriber.primaryTelephoneNumber = "" + selectedMedicine.selectedPrescriber.phone1;
        webrequest.prescriber.faxNumber = "" + selectedMedicine.selectedPrescriber.additionalFields.find((field: any) => field.fieldDescription == 'Fax')?.fieldStringValue;
        
        webrequest.prescriber.addressLine1 = selectedPrescriberAddress.address1;
        webrequest.prescriber.city = selectedPrescriberAddress.city;
        webrequest.prescriber.postalCode = selectedPrescriberAddress.zip1;
        webrequest.prescriber.stateProvince = selectedPrescriberAddress.state;
        webrequest.prescriber.countryCode = selectedPrescriberAddress.country;


        webrequest.changedPrescriber.firstName = selectedMedicine.selectedPrescriber.firstName;
        webrequest.changedPrescriber.lastName = selectedMedicine.selectedPrescriber.lastName;
        webrequest.changedPrescriber.addressLine1 = selectedMedicine.selectedPrescriber.address.address1;
        webrequest.changedPrescriber.city = selectedMedicine.selectedPrescriber.address.city;
        webrequest.changedPrescriber.npi = "" + selectedMedicine.selectedPrescriber.identifiers.find((identifier: any) => identifier.qualifier=='01')?.id;
        webrequest.changedPrescriber.presbrId = "" + this.loginProfile.loginuser?.prescriber?.presbrId;
        webrequest.prescriber.primaryTelephoneNumber = "" + selectedMedicine.selectedPrescriber.phone1;
        webrequest.prescriber.faxNumber = "" + selectedMedicine.selectedPrescriber.additionalFields.find((field: any) => field.fieldDescription == 'Fax')?.fieldStringValue;
        webrequest.changedPrescriber.stateProvince = selectedPrescriberAddress.state;
        webrequest.changedPrescriber.countryCode = selectedPrescriberAddress.country;

      }

    }
  }
  extractPAInitiationRequestFromSelectMadicine(selectedMedicine: SelectedMedicine): PAInitiationWebRequest {
    debugger;
    const webrequest: PAInitiationWebRequest = new PAInitiationWebRequest();
    const benefitsCoordination: BenefitsCoordination = new BenefitsCoordination();

    benefitsCoordination.cardholderID = selectedMedicine.memberDetailResponse.memberItems[0].memberId;
    benefitsCoordination.pBMMemberID = selectedMedicine.memberDetailResponse.memberItems[0].memberId;
    webrequest.benefitsCoordination.push(benefitsCoordination);
    this.mapPatientDetailsToPAInitiationRequest(selectedMedicine, webrequest);
    this.mapDrugDetailsToPAInitiationRequest(selectedMedicine, webrequest);
    this.mapProviderDetailsToPAInitiationRequest(selectedMedicine, webrequest);
    return webrequest;
  }
  submitPAInitiationRequest(webrequest: PAInitiationWebRequest): Promise<EpaInitiationAckWebResponse> {
    debugger;
    const promise: Promise<EpaInitiationAckWebResponse> = new Promise<EpaInitiationAckWebResponse>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.submitPAInitiationRequest,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
        if (response != undefined) {
          if (response.status == -1) {
            this.logger.log(response);
            reject(response);//connectionerrorfortimeout:The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.
          } else {
            if (response.status != undefined) {
              debugger;
              if (response.status.respCode == EPA_TRANSACTIon_STATUS.INITIATION_SUCCESS_RESPCODE) {
                debugger;
                resolve(response);
              } else {
                if (response.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
                  reject(EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR);
                } else {
                  if (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_ERROR_TEXT) {
                    reject(response.status.statusDesc);
                  } else {
                    if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.INITIATION_DB_ERROR) {
                      debugger;
                      reject(response.status.dbStatusDesc);
                    } else {
                      this.logger.log(response);
                      debugger;
                      reject(response);
                    }
                  }
                }
              }
            }
          }
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  cancelPAInitiationSubscription(): void {
    if (this.paInitiationSubscription != undefined)
      this.paInitiationSubscription.unsubscribe();
  }
  private fetchQuestionSet(webrequest: EPAPoolWebRequest, resolve: any, reject: any, count: number): void {
    let header = new HttpHeaders({ "endpoint": environment.poolForPAInitiationResponseUrl ,"X-CSRF-Token":this.profileService.getCsrfToken() });
    this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
      if (response != undefined && response.status != undefined) {
        if (response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          if (response.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
            reject(EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR);
          } else {
            //dbStatusCode
            //0(or)undefined -> Question set received from PAS.
            //1 ->this value not in use for 2nd Part of ePA transaction (or) for pooling.
            //2 ->Question set response not yet received from PAS.
            //3 ->This PA request is closed immediately during PA initiation by PAS.

            if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED) {

              const poolingMaxCount: number = environment.EPA_WAITING_TIME_SEC / environment.EPA_POOLING_INTERVAL;
              if (count < poolingMaxCount) {
                count++;
                setTimeout(() => {
                  this.fetchQuestionSet(webrequest, resolve, reject, count);
                }, (1000 * environment.EPA_POOLING_INTERVAL));
              } else {
                reject(EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED);
              }
            } else {
              if (response.status.dbStatusCode == undefined || response.status.dbStatusCode.toString() == EPA_TRANSACTIon_STATUS.POOLING_QS_RECEIVED) {
                resolve(response);
              } else {
                if (response.status.dbStatusCode.toString() == EPA_TRANSACTIon_STATUS.INITIATION_PA_IMMEDIATE_CLOSED) {
                  if (response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
                    reject(response.status.statusDesc);//Have to show what ever status description thrown by PAS in error modal when PA is immediately closed.
                  } else {
                    this.logger.log(response);
                    reject(response);
                  }
                } else {
                  this.logger.log(response);
                  reject(response);
                }
              }
            }
          }
        }
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  }
  cancelFetchQSSubscription(): void {
    if (this.fetchQSSubscription != undefined)
      this.fetchQSSubscription.unsubscribe();
  }
  poolPAInitiationResponse(paReferenceID: string): Promise<EpaInitiationResponse> {
    debugger;
    const webrequest: EPAPoolWebRequest = new EPAPoolWebRequest();
    webrequest.paReferenceID = paReferenceID.toString();
    const promise: Promise<EpaInitiationResponse> = new Promise<EpaInitiationResponse>((resolve, reject) => {
      this.fetchQuestionSet(webrequest, resolve, reject, 0)
    });
    return promise;
  }
  resumeSavedPA(paReferenceId: string, uuid: string): Promise<SavedPAResponse> {
    const promise: Promise<SavedPAResponse> = new Promise<SavedPAResponse>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
      let header = new HttpHeaders({ "endpoint": environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid) ,"X-CSRF-Token":this.profileService.getCsrfToken() });
      this.http.post(environment.proxyurl, null, { headers: header }).subscribe((response: any) => {
        // this.resumePASubscription = this.http.post(environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid), null).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  resumeNtfsSavedPA(paReferenceId: number, uuid: string, notificationId: number): Promise<SavedPAResponse> {
    const promise: Promise<SavedPAResponse> = new Promise<SavedPAResponse>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
      let header = new HttpHeaders({ "endpoint": environment.getNtfsSavedPADetailsUrl.replace("{parefId}", paReferenceId.toString()).replace("{uuid}", uuid).replace("{notificationId}", notificationId.toString()) ,"X-CSRF-Token":this.profileService.getCsrfToken() });
      this.http.post(environment.proxyurl, null, { headers: header }).subscribe((response: any) => {
        // this.resumePASubscription = this.http.post(environment.getSavedPADetailsUrl.replace("{parefId}", paReferenceId).replace("{uuid}", uuid), null).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  resubmitSavedPA(paReferenceId: string): Promise<ResubmitSavedPA> {
    const promise: Promise<ResubmitSavedPA> = new Promise<ResubmitSavedPA>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
      let header = new HttpHeaders({ "endpoint": environment.getResubmitSavedPADetailsUrl.replace("{parefId}", paReferenceId) ,"X-CSRF-Token":this.profileService.getCsrfToken() });
      this.http.post(environment.proxyurl, null, { headers: header }).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          reject(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }


  cancelResumePASubscription(): void {
    if (this.resumePASubscription != undefined)
      this.resumePASubscription.unsubscribe();
  }

  cancelActiveSavePAAction(): void {
    if (this.saveSubscription != undefined) {
      this.saveSubscription.unsubscribe();
    }
  }
  private fetchCancellationResponse(webrequest: EPAPoolWebRequest, resolve: any, reject: any, count: number): void {
    let header = new HttpHeaders({ "endpoint": environment.poolForPACancellationResponseUrl ,"X-CSRF-Token":this.profileService.getCsrfToken() });
    this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
      // this.paCancellationResponseSubscription = this.http.post(environment.poolForPACancellationResponseUrl, webrequest).subscribe((response: any) => {
      if (response != undefined) {
        if (response.status != undefined && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          if (response.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
            reject(EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR);
          } else {
            if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED) {
              const poolingMaxCount: number = environment.EPA_WAITING_TIME_SEC / environment.EPA_POOLING_INTERVAL;
              if (count < poolingMaxCount) {
                count++;
                setTimeout(() => {
                  this.fetchQuestionSet(webrequest, resolve, reject, count);
                }, (1000 * environment.EPA_POOLING_INTERVAL));
              } else {
                reject(EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED);
              }
            } else {
              if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.POOLING_RESP_RECEIVED) {
                resolve(response);
              } else {
                this.logger.log(response);
                reject(response);
              }
            }
          }
        } else {
          if (response.status != undefined && response.status.statusCode != undefined
            && response.status.statusCode.toLowerCase() != APPCONSTANTS.API_RESPONSE_SUCCESS_TXT && response.status.StatusDesc != undefined) {
            reject(response.status.StatusDesc);
          } else {
            reject(undefined);
          }
        }
      } else {
        reject(EPA_TRANSACTIon_STATUS.SERVICE_TIMEOUT);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  }
  cancelPACancellationSubscription(): void {
    if (this.paCancellationSubscription != undefined)
      this.paCancellationSubscription.unsubscribe();
  }
  cancelPACancellationResponseSubscription(): void {
    if (this.paCancellationResponseSubscription != undefined)
      this.paCancellationResponseSubscription.unsubscribe();
  }
  poolForPACancellationResponse(webrequest: EPACancellationWebRequest): Promise<EpaCancellationWebResponse> {
    const promise: Promise<EpaCancellationWebResponse> = new Promise<EpaCancellationWebResponse>((resolve, reject) => {
      this.fetchCancellationResponse(webrequest, resolve, reject, 0);
    });
    return promise;
  }
  cancelPA(webrequest: EPACancellationWebRequest): Promise<EpaCancellationWebResponse> {
    this.cancelActiveSavePAAction();
    const promise: Promise<EpaCancellationWebResponse> = new Promise<EpaCancellationWebResponse>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.paCancellationUrl ,"X-CSRF-Token":this.profileService.getCsrfToken() });
      this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
        // this.cancelPASubscription = this.http.post(environment.paCancellationUrl, webrequest).subscribe((response: any) => {
        if (response != undefined) {
          if (response.status == -1) {
            reject(EPA_TRANSACTIon_STATUS.SERVICE_TIMEOUT);//connectionerrorfortimeout:The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.
          } else {
            if (response.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
              reject(EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR);
            } else {
              if ((response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_ERROR_TEXT)
                || response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.CANCELLATION_DB_ERROR) {
                reject(EPA_TRANSACTIon_STATUS.CANCELLATION_DB_ERROR);
              } else {
                if (response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
                  if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.CANCELLATION_DB_ERROR_WITHREASON) {
                    reject(response.status.statusDesc);
                  } else {
                    if (response.status.respCode == EPA_TRANSACTIon_STATUS.INITIATION_SUCCESS_RESPCODE) {
                      resolve(response);
                    } else {
                      reject(response.status.respMessage);
                    }
                  }
                }
              }
            }
          }
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }
  cancelPACancelSubscription(): void {
    if (this.cancelPASubscription != undefined)
      this.cancelPASubscription.unsubscribe();
  }
  private fetchPAResponse(webrequest: EPAPoolWebRequest, resolve: any, reject: any, count: number): void {
    let header = new HttpHeaders({ "endpoint": environment.poolForPAResponseUrl ,"X-CSRF-Token":this.profileService.getCsrfToken() });
    this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
      // this.fetchPAResponseSubscription = this.http.post(environment.poolForPAResponseUrl, webrequest).subscribe((response: any) => {
      if (response != undefined && response.status != undefined) {
        if (response.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
          reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
        } else {
          if (response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
            if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED) {
              const poolingMaxCount: number = environment.EPA_WAITING_TIME_SEC / environment.EPA_POOLING_INTERVAL;
              if (count < poolingMaxCount) {
                count++;
                setTimeout(() => {
                  this.fetchPAResponse(webrequest, resolve, reject, count);
                }, (1000 * environment.EPA_POOLING_INTERVAL));
              } else {
                reject(EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED);
              }
            } else {
              if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.POOLING_RESP_RECEIVED) {
                resolve(response);
              } else {
                if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.PRIORAUTH_DENIED) {
                  if (response.paResponse != undefined && response.paResponse.paNote != undefined) {
                    this.logger.log(response);
                    reject(response);//PA Note will be displayed in approved status modal.
                  } else {
                    reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_DENIED);
                  }
                } else {
                  if (response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.PRIORAUTH_CLOSED || response.status.dbStatusCode == EPA_TRANSACTIon_STATUS.PRIORAUTH_CANCELLED) {
                    if (response.status.statusDesc != undefined) {
                      reject(response.status.statusDesc);
                    } else {
                      if (response.paResponse != undefined && response.paResponse.paNote != undefined) {
                        if (response.paResponse.paNote.toLowerCase().trim().indexOf("cannot find pa case id") != -1)
                          reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
                        else
                          reject(response.paResponse.paNote);//PA Note will be displayed in error modal
                      } else {
                        reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
                      }
                    }
                  } else {
                    reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
                  }
                }
              }
            }
          } else {
            reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
          }
        }
      } else {
        reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
      }
    }, (error: any) => {
      if (typeof error.status == "object" && error.status.customErrorCode == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR) {
        reject(EPA_TRANSACTIon_STATUS.PRIORAUTH_ERROR);
      } else {
        reject(error.status + ": " + error.statusText);
      }
    });
  }
  cancelFetchPAResponseSubscription(): void {
    if (this.fetchPAResponseSubscription != undefined)
      this.fetchPAResponseSubscription.unsubscribe();
  }
  poolPAResponse(paReferenceID: string): Promise<EpaResponse> {
    const webrequest: EPAPoolWebRequest = new EPAPoolWebRequest();
    webrequest.paReferenceID = paReferenceID.toString();
    const promise: Promise<EpaResponse> = new Promise<EpaResponse>((resolve, reject) => {
      this.fetchPAResponse(webrequest, resolve, reject, 0)
    });
    return promise;
  }
  saveOrUpdate(webrequest: SavedPARequest): Promise<Status> {
    this.cancelActiveSavePAAction();
    const promise: Promise<Status> = new Promise<Status>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.submitOrSavePARequestUrl  ,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest, { headers: header }).subscribe((response: any) => {
        // this.saveSubscription = this.http.post(environment.submitOrSavePARequestUrl, webrequest).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT.toLowerCase()) {
          resolve(response.status);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });

    return promise;
  }

  uploadAttachmentForPA(paReferenceId: string, file: File): Observable<HttpEvent<any>> {
    debugger;
    const formData: FormData = new FormData();
    // formData.append('parefId',paReferenceId);
    formData.append('file', file);
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('paReferenceId', paReferenceId);
    httpParams = httpParams.append('fileName', file.name);
    // httpParams = httpParams.append('link',environment.uploadFileUrl);
    let header = new HttpHeaders({ "endpoint": environment.uploadFileUrl.replace("{parefId}", paReferenceId).replace("{file}", file.name) ,"X-CSRF-Token":this.profileService.getCsrfToken()});
    const req = new HttpRequest('POST', environment.proxyurlFileUpload, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: header,
      params: httpParams
    });

    return this.http.request(req);
  }

  cancelAllSubscriptions(): void {
    this.cancelActiveSavePAAction();
    this.cancelFetchPAResponseSubscription();
    this.cancelFetchQSSubscription();
    this.cancelPACancelSubscription();
    this.cancelPACancellationResponseSubscription();
    this.cancelPAInitiationSubscription();
    this.cancelPARefGenerationSubscription();
    this.cancelResumePASubscription();
  }


  resumeCellClickPA(paCaseID: string): Promise<SavedPAResponse> {
    const promise: Promise<SavedPAResponse> = new Promise<SavedPAResponse>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{

      let header = new HttpHeaders({ "endpoint": environment.viewQuestionSetUrl.replace("{paCaseID}", paCaseID) ,"X-CSRF-Token":this.profileService.getCsrfToken() });

      this.http.post(environment.proxyurl, null, { headers: header }).subscribe((response: any) => {
        if (response != undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  downloadFile(paAttachId: string): Promise<PaattchmentResponse> {
    const promise: Promise<PaattchmentResponse> = new Promise<PaattchmentResponse>((resolve, reject) => {
      //this.http.get('./assets/mockresponses/resumeSavedPAResponse.json').subscribe((response:any)=>{
      let header = new HttpHeaders({"X-CSRF-Token":this.profileService.getCsrfToken() });
      this.downloadSubscription = this.http.post(environment.downloadFileUrl.replace("{paAttachId}", paAttachId), { headers: header }).subscribe((response: any) => {
        if (response != undefined && response.status != undefined && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;

  }
}

