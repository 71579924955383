import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
import { AddFavRootObject, AddMedicationPrescribed, Diagnosissearchwebrequest, DrugCoded, Drugsearchwebrequest, MedicationPrescribed, MemberSearchItem, RemoveFavRootObject } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { DiagnosisCodeSearchItem, DiagnosisCodeSearchResponses, DrugSearchItem, Drugsearchwebresponse, FavDrugStatusResponse, FavoriteDrugsDetail, FavoriteDrugsDetailResponse, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { DrugsearchService } from 'src/app/services/drugsearch/drugsearch.service';
import { fade, listAnimation, slideDown } from 'src/animations/animations';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { Pharmacy, PharmacySearchResponse } from 'src/app/modals/drugSearch/pharmacySearchresponse';
import { PharmacySearchRequest, PlanSearch } from 'src/app/modals/drugSearch/pharmaySearchrequest';
import { PharmacyhomeComponent } from '../routecontent/pharmacyhome/pharmacyhome.component';
import { CalculateQuantityService } from 'src/app/services/Quantity/calculate-quantity.service';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { MemberDetailWebRequesthemi } from 'src/app/modals/membersearch/memberdetailwebrequest';
import { PreferredPharmacyWebrequest } from 'src/app/modals/preferredpharmacy/preferredpharmacywebrequest';
import { PreferredPharmacyWebresponse } from 'src/app/modals/preferredpharmacy/preferredpharmacywebresponse';
import { Observable, from, fromEvent, of } from 'rxjs';
import { response } from 'express';
import * as e from 'express';
import { StringToSentenceCase } from 'src/app/pipes/stringconvertions/stringconversions.pipe';
import { ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';
import { MemberDetailService } from 'src/app/services/memberdetail/memberdetail.service';
import { Address } from 'src/app/modals/registration/SteponePharmacyRequest';
import { SteponePharmacyDetailsResponse, StoreAddress } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { SteponePharmacyDetailsRequest } from 'src/app/modals/registration/SteponePharmacyDetailsRequest';
import { PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';

@Component({
  selector: 'app-select-medication',
  templateUrl: './select-medication.component.html',
  styleUrls: ['./select-medication.component.css'],
  providers: [NgbTypeaheadConfig],
  animations: [
    listAnimation,
    fade,
    slideDown
  ]
})
export class SelectMedicationComponent implements OnInit, AfterViewInit, OnDestroy {
  

  
  // blnLastNameFocus: boolean = false;
  // blnMemberIdFocus: boolean = false;
  uniqueArr!: string[];
  diagnosisArry!: string[];
  invalidNDC=false;
  dropdown: DrugSearchItem[] = [];
  productList: DrugSearchItem[] = [];
  currentSelectedDrug!: DrugSearchItem[];
  selectedDrugItems: DrugSearchItem[] = [];
  selectedMedicine: SelectedMedicine[] = [];
  DrugsessionDetails:any;
  errorText = "";
  dispenseAsWritten = 0;

  blnMemberDetailPageSession:string = "false";
  
  qtyPageToggle: boolean = false;
  formLoading = false;

  searchByNDC = "";
  cacheNDC = "";
  searching = false;
  loading = false;

  searchFailed = false;
  searchingDiagnosisCode = false;

  diagnosisCodeChecked: string | undefined = "";
  searchDiagnosisClicked = false;

  newDate!:string;


  diagnosisList!: DiagnosisCodeSearchItem[];
  diagnosisCodeList!: string[];
  diagnosisSearchValue = "";
  submitedValidAddMedicationForm = false;

  blnDrugSearchFormSubmitted: boolean = true;
  blnFormSubmitted:boolean = false;
  blnValidDcSubmitted: boolean = false;
  blnZipCodeFocus: boolean = false;
  blnQuantityFocus:boolean =false;
  blnDiadnosisCodeFocus: boolean = false;

  blnShowErrorPharmacist:boolean = false;
  blnPharmacySelected:boolean = false;
  blnPharmacistChangeButton:boolean = false;
  blnNDCFocus!:boolean;
  blnInSuffientSearchCriteria: boolean = false;
  blnShowHide:boolean=true;
  blnMemberEdit:boolean = false;
  blnDrugEdit:boolean = false;

  medStrengthTitle = "";
  previousData = '';

  memberDetails:any;
  pharmacyDetails!:any;
  pharmacySelectedDetails!:any;
  errorMessage!:any;
  contextualErrorCode:boolean=true;

  quantityCalculated = "";
  quantityCalculate!: number;
  quantityDesc = "";
  quantityEntered = "";
  nosavedMessage!:string;
  paRefID:any;
  message:string="";
  
  currentPageNumber: number = 1;
  recordsPerPage: number = 5;
  totalPagesCount: number = -1;
  pharmacySearches: Pharmacy[] = new Array();
  zipCodeSearched = "";

  DAW: string='0';

  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
   

  private _selectedMiles!: number;
  drugDetails!: DrugSearchItem[];
  public get selectedMiles() : number {
    return this.selectedMiles;
  }
  public set selectedMiles(v : number) {
    this._selectedMiles = v;
  }

  // Form for adding claim details
  addMedicationForm:FormGroup = new FormGroup({
    drugSearchModel: new FormControl('', { validators: [Validators.required, Validators.minLength(3)], updateOn: 'blur' }),
    MedStrength: new FormControl('', { validators: [Validators.required] }),
    DiagnosisCode: new FormControl('', { validators: [Validators.minLength(3),Validators.pattern(/^([A-TV-Z][0-9][0-9AB]\.?[0-9A-TV-Z]{0,4})$/i)], updateOn: 'blur' }),
  })

  constructor(private servertimeToStandardDate:ServertimeToStandardDate,private stringToSentenceCase: StringToSentenceCase,private quantityService: CalculateQuantityService, config: NgbTypeaheadConfig,private memberSearchHelper: MembersearchService,private memberdetailsevice:MemberDetailService,private drugSearchHelper:DrugsearchService,private router: Router ,private fb: FormBuilder, private logger: LoggerService, private modalService: NgbModal,private drugsearchHelper:DrugsearchService,private registrationservice: registrationService, private profileService: ProfileService,
    ){
       // customize default values of typeaheads used by this component tree
    config.showHint = false;
    config.editable = false;
       //Saved and Recent Searches headers for DataTable
    this.firstHeader.content = "Product Id";
    this.firstHeader.sortable = true;

    this.secondHeader.content = "Medication Name";
    this.thirdHeader.content = "Action";

    this.savedSearchHeaders = [];
    this.savedSearchHeaders.push(this.secondHeader);
    this.savedSearchHeaders.push(this.thirdHeader);

    this.savedSearchHistory = [];

    // Recent Searches headers for Datatable
    this.recentSearchHeaders = [];
    this.recentSearchHeaders.push(this.firstHeader);
    this.recentSearchHeaders.push(this.secondHeader);
    this.recentSearchHeaders.push(this.thirdHeader);

    this.recentSearchHistory = [];
  
    //Pharmacy Search headers for DataTable

    this.pharmacyFirstHeader.content = "Pharmacy Name";
    // this.pharmacyFirstHeader.sortable = true;
    // this.pharmacyFirstHeader.sortAscending=true;
    // this.pharmacyFirstHeader.selectedForSort=true;
     this.pharmacyFirstHeader.clickable = true;
    this.pharmacySecondHeader.content = "Address";
    this.pharmacyThirdHeader.content = "Distance";
    this.pharmacyForthHeader.content = "&nbsp;" ;

    this.pharmacySearchHeaders = [];
    this.pharmacySearchHeaders.push(this.pharmacyFirstHeader);
    this.pharmacySearchHeaders.push(this.pharmacySecondHeader);
    this.pharmacySearchHeaders.push(this.pharmacyThirdHeader);
    this.pharmacySearchHeaders.push(this.pharmacyForthHeader);


    this.pharmacySearchHistory = [];

    //Diagnosis keyword Search headers for DataTable
    this.diagnosisFirstHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    this.diagnosisFirstHeader.clickable = false;
    this.diagnosisSecondHeader.content = "Diagnosis Code";
    this.diagnosisThirdHeader.content = "Diagnosis Description";

    this.diagnosisSearchHeaders.push(this.diagnosisFirstHeader);
    this.diagnosisSearchHeaders.push(this.diagnosisSecondHeader);
    this.diagnosisSearchHeaders.push(this.diagnosisThirdHeader);

    this.diagnosisSearchHistory = [];
    }

  savedSearchHistory!: TableRowData<FavoriteDrugsDetail>[];
  savedSearchesDrugCodes: string[] = new Array();
  savedSearchHeaders!: TableHeaderCellData[];

  recentSearchHeaders!: TableHeaderCellData[];
  recentSearchHistory!: TableRowData<DrugSearchItem>[];
  recentSearches: DrugSearchItem[] = new Array();
  recentSearchesArry: string[] = new Array();


  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("recentSearchesModal")
  recentSearch?: ElementRef<HTMLElement>;

  @ViewChild("savedSearches")
  savedSearch?: ElementRef<HTMLElement>;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<any>

  @ViewChild("actionRemove", { static: true })
  actionRemove?: TemplateRef<any>

  @ViewChild("actionSelect", { static: true })
  actionSelect?: TemplateRef<any>

  @ViewChild("recentDrugNameTemplate", { static: true })
  recentDrugNameTemplate?: TemplateRef<any>

  @ViewChild("recentDrugPrdouctId", { static: true })
  recentDrugPrdouctId?: TemplateRef<any>

  @ViewChild("actionRecentSearchRemove", { static: true })
  actionRecentSearchRemove?: TemplateRef<any>

  @ViewChild("actionRecentSearchSelect", { static: true })
  actionRecentSearchSelect?: TemplateRef<any>


  @ViewChild("diagnosisCode")
  diagnosisCode?: ElementRef<HTMLElement>;

  @ViewChild("addPharmacyTemplate")
  addPharmacyTemplate?: ElementRef<HTMLElement>;

  @ViewChild("diagnosisCodeCheckboxTemplate", { static: true })
  diagnosisCodeCheckboxTemplate?: TemplateRef<any>

  @ViewChild("diagnosisCodeTemplate", { static: true })
  diagnosisCodeTemplate?: TemplateRef<any>

  @ViewChild("diagnosisKeywordTemplate", { static: true })
  diagnosisKeywordTemplate?: TemplateRef<any>

  @ViewChild("pharmacyNameTemplate", { static: true })
  pharmacyNameTemplate?: TemplateRef<any>

  @ViewChild("pharmacyAddressTemplate", { static: true })
  pharmacyAddressTemplate?: TemplateRef<any>

  @ViewChild("pharmacydistanceTemplate", { static: true })
  pharmacydistanceTemplate?: TemplateRef<any>

  @ViewChild("actionPharmacySearchSelect", { static: true })
  actionPharmacySearchSelect?: TemplateRef<any>
  
  @ViewChild("errorContent")
  content?: ElementRef<HTMLElement>;

  @ViewChild("cancelPAConfirmation")
  cancelPAConfirmationModal?:ElementRef<HTMLElement>;

  @Input() blnInitiatePA!:boolean;
  @Input() pageType!:string;


   // Getters for formControls
   get drugSearchModel() {
    return this.addMedicationForm.get('drugSearchModel');
  }
  get MedStrength() {
    return this.addMedicationForm.get('MedStrength');
  }

  get DiagnosisCode() {
    return this.addMedicationForm.get('DiagnosisCode');
  }

  // Diagnosis Desciption Serach form
  diagnosisForm = this.fb.group({
    diagnosisDescription: new FormControl('', { validators: [Validators.minLength(2)], updateOn: 'blur' })
  });

  //Getters for Diagnosis Desciption Serach 
  get diagnosisDescription() {
    return this.diagnosisForm.get('diagnosisDescription');
  }
  // End Diagnosis Desciption

  //add pharmacy details form
  addPharmacyForm= this.fb.group({
    zipcode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^([0-9]+)$/i)]),
    distance: new FormControl('5', { validators: [Validators.required] }),
    pharmacyName: new FormControl('', [Validators.maxLength(15), Validators.pattern(/^(?!\d+$)([a-z0-9 .\-']+)$/i)]),
    Quantity: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(7), Validators.min(1)] }),
    Days: new FormControl('', { validators: [Validators.required, Validators.pattern('[0-9]+$'), Validators.maxLength(3), Validators.min(1)] }),
  });

   //Getters for Diagnosis Desciption Serach 
   get zipcode() {
    return this.addPharmacyForm.get('zipcode');
  }
  get distance(){
    return this.addPharmacyForm.get('distance');
  }
  get pharmacyName(){
    return this.addPharmacyForm.get('pharmacyName');
  }

  get Quantity() {
    return this.addPharmacyForm.get('Quantity')
  }

  get Days() {
    return this.addPharmacyForm.get('Days')
  }

  reloadSavedSearches() {
   //debugger;
    if (this.profileService.loginuser?.prescriber?.presbrId != undefined && this.profileService.loginuser.prescriber.uuid != undefined) {
      this.drugsearchHelper.getFavouriteDrugs(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.prescriber.uuid).then((data: FavoriteDrugsDetailResponse) => {

        if (data.favoriteDrugsDetail != undefined && data.favoriteDrugsDetail.length > 0) {
          //console.log("FavSearch:" + Object.values(data.favoriteDrugsDetail));
          this.loadSavedSearchHistory(data.favoriteDrugsDetail);
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
    }
  }
  // To load savedSearch items into DataTable
  private loadSavedSearchHistory(rawData: FavoriteDrugsDetail[]): void {
    //debugger;

    rawData.forEach((history: FavoriteDrugsDetail) => {
      const row: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      this.savedSearchesDrugCodes.push(history.drugCoded[0].code);
      const firstCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.drugNameTemplateRef;
      row.cells.push(firstCell);

      const secondCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.actionRemove;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<FavoriteDrugsDetail> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.actionSelect;
      row.cells.push(thirdCell);


      this.savedSearchHistory.push(row);
    });


  }

  // To load recentSearches items into DataTable 
  private loadRecentSearchHistory(rawData:DrugSearchItem[]): void {
    debugger;
    this.recentSearchHistory = [];
    this.updateTotalPagesCount();
    this.getCurrentPageMembers().forEach((history: DrugSearchItem) => {
      const row: TableRowData<DrugSearchItem> = new TableRowData<DrugSearchItem>();
      const firstCell: TableCellData<DrugSearchItem> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.recentDrugPrdouctId;
      row.cells.push(firstCell);

      const secondCell: TableCellData<DrugSearchItem> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.recentDrugNameTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<DrugSearchItem> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.actionRecentSearchSelect;
      row.cells.push(thirdCell);

      this.recentSearchHistory.push(row);
    });
  }
  // Show Saved Searches 
   showSavedSeraches() {
    debugger;
    this.logger.userAction("Opening Saved Searches.");
    this.savedSearchHistory = [];
    this.reloadSavedSearches();
    this.modalService.open(this.savedSearch, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});
    if(this.savedSearchHistory.length==0){
      
      this.nosavedMessage="No saved medications";
    }
   }

  // Show Recent Searches
   showRecentSeraches() {
    debugger;
   this.logger.userAction("Opening Recent Searches.");
    event?.preventDefault();
     this.recentSearchHistory = [];
     console.log(this.recentSearches);
    this.loadRecentSearchHistory(this.recentSearches);
    this.modalService.open(this.recentSearch, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});
    if(this.recentSearchHistory.length==0){
      
      this.message="No recent medications found.";
      //console.log(this.message);
    }
  }

   // To handling sorting event on savedSearches headers click
   onSavedHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

   }

   savedSearchId: string | undefined = '';
   onSavedCellClick(cellData: TableClickEvent<FavoriteDrugsDetail>) {
     debugger;
     if (cellData.column == 1 || cellData.event.target.getAttribute('name')=="remove") {
       this.logger.userAction("Removing Favourite Medication");
       //console.log(cellData.cell?.data?.drugDescription);
       this.removefavourite(cellData.cell?.data?.drugCoded[0].code);
     } else {
       // For Setting the Medication Name
       this.addMedicationForm.reset();
       this.logger.userAction("Selecting Favourite Medication");
       var productID: string | undefined = cellData.cell?.data?.drugCoded[0].code;
       //console.log("drugDetails:" + drugDetails);
      //  this.drugSearchModel?.setValue(cellData.cell?.data?.drugDescription);
 
       // For Setting the Mediaction Strength
       // var drugBrand!: any;
       // drugBrand = this.productList.find(a => a.displayName == this.addMedicationForm.get('drugSearchModel')?.value)?.brandName;
       let request: Drugsearchwebrequest = new Drugsearchwebrequest();
       request.ndc = "" + productID;
       this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
         ////debugger;
         if (response.status.respCode == '200') {
           this.dropdown = response.response.drugSearchItems;
           this.formLoading = false;
           this.MedStrength?.enable();
           var drugItem: DrugSearchItem[] = this.dropdown.filter(data => data.productId == productID);
           this.drugSearchModel?.setValue(drugItem[0].productNameExt);
           this.MedStrength?.setValue(drugItem[0].productId);
           this.DiagnosisCode?.enable();
           this.modalService.dismissAll();
           this.onChangeMediactionStrenth();
         } else {
           this.drugSearchModel?.setErrors({ 'incorrect': true });
           this.formLoading = false;
           this.dropdown = [];
         }
       }, (error: any) => {
         this.modalService.dismissAll();
         this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
       }).catch((reason: any) => {
         this.modalService.dismissAll();
         this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
       });
     }
   }

  ngAfterViewInit(): void {
    this.modalService.dismissAll();
  }

  selectedMember!: MemberDetailWebResponsehemi;

  ngOnInit(): void {
    debugger;
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    if(sessionStorage.getItem("blnMemberDetailPageSession")!=undefined){
    this.blnMemberDetailPageSession = ""+sessionStorage.getItem("blnMemberDetailPageSession")?"true":"false";
    }
    // this.DrugsessionDetails=JSON.parse(""+sessionStorage.getItem("selectedMedicine"));
    sessionStorage.removeItem("selectedMedicine");
    this.MedStrength?.disable();
    this.DiagnosisCode?.disable();    
    this.paRefID = sessionStorage.getItem("paRefID");
    console.log(this.paRefID);
    this.blnMemberEdit = false;
    this.memberDetails = this.memberSearchHelper.getSelectedMember();
    let memrequest: MemberDetailWebRequesthemi = new MemberDetailWebRequesthemi();
          memrequest.accountId = this.memberDetails.accountId;
          memrequest.id = this.memberDetails.memberId;
          memrequest.sourceSystemInstance = this.memberDetails.instanceId;
          memrequest.groupId = this.memberDetails.groupId;
          memrequest.carrierId = this.memberDetails.carrierId;
         this.memberdetailsevice.getMemberDetailshemi(memrequest).then((memresponse:MemberDetailWebResponsehemi)=>{
         console.log(memresponse);
         this.selectedMember = memresponse;
         this.newDate = this.servertimeToStandardDate.transform(this.memberDetails.dateOfBirth);
        //  this.selectedMedicine[0].memberDetailResponse = this.selectedMember;
         sessionStorage.getItem('mem')
      this.drugsearchHelper.getFavouriteDrugs(""+this.profileService.loginuser?.prescriber?.presbrId,""+this.profileService.loginuser?.prescriber?.uuid);
      if(this.profileService.loginuser.prescriber?.roleTypeId=="11"){
        debugger;
        let webrequest:SteponePharmacyDetailsRequest = new SteponePharmacyDetailsRequest();
        webrequest.instanceId = this.memberDetails.instanceId;
        webrequest.pharmacyId = this.profileService.loginuser.prescriber.npi;
        webrequest.pharmacyIdQualifier=  PRESCRIBER_QUALIFIER.NPI;
        this.registrationservice.postPharmacyDetails(webrequest).then((data:SteponePharmacyDetailsResponse)=>{
          debugger;
          console.log(data);
          if(data.status.statusCode=="200"){
            debugger;
            this.drugSearchHelper.clearSelectedPharmacy();
            this.drugSearchHelper.storeSelectedPharmacistPharmacy(data.pharmacyDetailItems[0]);
            sessionStorage.setItem("selectedPharmacyDetails",JSON.stringify(data.pharmacyDetailItems[0].storeAddress));
            this.pharmacyDetails= this.drugSearchHelper.getSelectedPharmacistPharmacy();
            this.pharmacySelectedDetails= JSON.parse(""+sessionStorage.getItem('selectedPharmacyDetails'));
            this.blnPharmacySelected=true;
            this.blnPharmacistChangeButton=true;
           console.log(this.pharmacyDetails);
          }
          else if(data.status.statusCode=="404"){
            this.blnPharmacySelected = true;
            this.blnShowErrorPharmacist = true;
          }
        }, (error: any) => {

        }).catch((error: any) => {
    
        });
    
      }
      else{
      let prefepharRequest:PreferredPharmacyWebrequest = new PreferredPharmacyWebrequest();
      prefepharRequest.accountId = this.memberDetails.accountId;
      prefepharRequest.carrierId = this.memberDetails.carrierId;
      prefepharRequest.fillDateFrom = memresponse.memberItems[0].memberEligibility[0].eligibilityStartDate;
      prefepharRequest.fillDateThru = memresponse.memberItems[0].memberEligibility[0].eligibilityEndDate;
      prefepharRequest.fillDateInd = 'Y';
      prefepharRequest.groupId = this.memberDetails.groupId;
      prefepharRequest.instanceId = this.memberDetails.instanceId;
      prefepharRequest.memberId = this.memberDetails.memberId;
      this.drugSearchHelper.getPreferredPharmacies(prefepharRequest).then((preffresponse:PreferredPharmacyWebresponse)=>{
        console.log(preffresponse); 
        // this.selectedMedicine[0].preferredPharmacyDetail = preffresponse;
      });
    }
  }, (error: any) => {

  }).catch((error: any) => {

  });

    
       console.log(this.memberDetails,this.memberDetails.effectiveFromDate);
    
    let webrequest:Drugsearchwebrequest = new Drugsearchwebrequest();
    webrequest.drugName =this.drugSearchModel?.value;
    webrequest.instanceId=this.memberDetails.instanceId;  
    // this.addMedicationForm.controls['drugSearchModel'].setValue( this.addMedicationForm.controls['drugSearchModel'].value);
    // this.addMedicationForm.controls['MedStrength'].setValue(this.addMedicationForm.controls['MedStrength'].value);
    // this.addPharmacyForm.controls['Quantity'].setValue(this.addPharmacyForm.controls['Quantity'].value);
    // this.addPharmacyForm.controls['Days'].setValue(this.addPharmacyForm.controls['Days'].value);
    if( sessionStorage.getItem("blnDrugEdit")!=null){
      debugger;
    this.blnDrugEdit = sessionStorage.getItem("blnMemberEdit")=="true"?true:false;
    // this.blnValidFormSubmitted = true;
    console.log(this.blnDrugEdit);
    }
    // if(""+this.router.navigateByUrl("/ePA/DrugSearch")){
    //    if(this.blnDrugEdit==false){
    //      debugger;
    //      this.drugSearchModel?.setValue(this.DrugsessionDetails.drugSearchItem.productNameExt);
    //      this.MedStrength?.setValue(this.DrugsessionDetails.drugSearchItem.prodNameAbbr);
    //      this.Quantity?.setValue(this.DrugsessionDetails.Quantity);
    //      this.Days?.setValue(this.DrugsessionDetails.Days);
    //    }
    //  }
    if(sessionStorage.getItem("recentDrugSearches")!=undefined){
      this.recentSearches=JSON.parse(""+sessionStorage.getItem("recentDrugSearches"));
      console.log(this.recentSearches);
    }
  }
  ngOnDestroy(): void {
  }

  // Diagnosis Code Keyword Serach Tables and Pagination
  diagnosisSearchHeaders: TableHeaderCellData[] = [];;
  diagnosisSearchHistory: TableRowData<DiagnosisCodeSearchItem>[] = [];

  private diagnosisFirstHeader: TableHeaderCellData = new TableHeaderCellData();
  private diagnosisSecondHeader: TableHeaderCellData = new TableHeaderCellData();
  private diagnosisThirdHeader: TableHeaderCellData = new TableHeaderCellData();

  noOfPages: number = 0;
  noOfRecords: number = 0;
  recordsDisplayedCount: string = "";
  currentPage = 1;
  diagnosisKeywordResposeObject: DiagnosisCodeSearchItem[] = [];

  // To load Diagniosis Keyword Search items to DataTable
  private loadDiagnosisHistory(rawData: DiagnosisCodeSearchItem[], diagnosisCode: string = ""): void {
    this.diagnosisSearchHistory = [];
    rawData.forEach((item: DiagnosisCodeSearchItem) => {
      const row: TableRowData<DiagnosisCodeSearchItem> = new TableRowData<DiagnosisCodeSearchItem>();
      const firstCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      if (item.diagnosisCode == diagnosisCode) {
        item.selected = true;
      } else {
        item.selected = false;
      }

      firstCell.data = item;
      firstCell.template = this.diagnosisCodeCheckboxTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.diagnosisCodeTemplate;
      secondCell.clickable = false;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<DiagnosisCodeSearchItem> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.diagnosisKeywordTemplate;
      row.cells.push(thirdCell);

      this.diagnosisSearchHistory.push(row);
    });


  }
  

  //  // To update Medication Strength dropdown on selecting NDC 
   onChangeNDC(value: string) {
    this.medStrengthTitle = "";
    if (this.drugSearchModel?.invalid) {
      this.MedStrength?.reset();
      this.MedStrength?.disable();
      this.quantityCalculated = "";
      this.dropdown = [];
      //console.log("Invalid Value:" + value)
    }else if(this.drugSearchModel?.valid == true) {
      this.cacheNDC = ""+this.drugSearchModel.value;
      this.qtyPageToggle = false;
      if (this.cacheNDC != this.drugSearchModel.value) {
        this.formLoading = true;
      }
      var drugBrand!: any;
      drugBrand =  this.addMedicationForm.get('drugSearchModel')?.value;
      if(isNaN(drugBrand)){
        this.dropdown = this.productList.filter(v=>v.productNameExt==drugBrand);
      }
          if (drugBrand == undefined || this.drugSearchModel?.errors) {
            this.dropdown = [];
            this.MedStrength?.reset();
            this.MedStrength?.disable();
          } else if (this.dropdown.length == 1) {
            this.MedStrength?.disable();
            this.MedStrength?.setValue(this.dropdown[0].productId);
            this.onChangeMediactionStrenth();
          } else {
            this.dropdown.forEach((item: DrugSearchItem) => {
              if (item.productId == this.drugSearchModel?.value) {
                //debugger;
                this.MedStrength?.disable();
                this.MedStrength?.setValue(item.productId);
                this.onChangeMediactionStrenth();
                this.searchByNDC = item.detailedDrugName;
              }
            });
          }
          // this.MedStrength?.setValue("");
          this.MedStrength?.enable();
          this.DiagnosisCode?.enable();
    } else {
      // console.log("Gone to else part:" + value);
    }
    return this.dropdown;
  }

  //  To update form details on selecting Medication Strength
  onChangeMediactionStrenth(productID: string = "") {
    debugger;
    if (productID == "") {
      productID = ""+this.addMedicationForm.get('MedStrength')?.value;
    }
    //console.log("MedStrength:" + productID);
    if (productID == "") {
      //console.log("default" + productID);
      this.qtyPageToggle = false;
      this.currentSelectedDrug = [];
    } else if (productID != "") {
      this.formLoading = true;
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content',windowClass:'loadingContent',backdrop:'static'});

      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.ndc = productID;
      request.instanceId = ""+this.memberSearchHelper.getSelectedMember()?.instanceId;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        //console.log("Selected ITem:" + response);
        this.currentSelectedDrug = response.response.drugSearchItems;
        this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
        this.formLoading = false;
        this.MedStrength?.enable();
        this.qtyPageToggle = true;
        this.calculateQuantity();

        // this.selectedMedicine[0].drugSearchItem = response.response.drugSearchItems[0];
        //Adding unique items to recent seraches array
        if (this.recentSearches.length == 0) {
          debugger;
          this.recentSearches.push(this.currentSelectedDrug[0]);
        } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
       }
        // End of Adding unique items to recent seraches array

        //console.log("Recent:" + JSON.stringify(this.recentSearches.length));
        this.modalService.dismissAll();
        productID = "";
      }, (error: any) => {
        ////debugger;
        this.currentSelectedDrug = this.dropdown.filter((data: DrugSearchItem) => data.productId == productID);
        this.medStrengthTitle = this.currentSelectedDrug[0].detailedDrugName;
        this.formLoading = false;
        this.MedStrength?.enable();
        this.qtyPageToggle = true;
        // Adding unique items to recent seraches array
        if (this.recentSearches.length == 0) {
          debugger;
          this.recentSearches.push(this.currentSelectedDrug[0]);
        } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
          this.recentSearches.push(this.currentSelectedDrug[0]);
        }
        // End of Adding unique items to recent seraches array

        // console.log("Recent:" + JSON.stringify(this.recentSearches.length));
        this.modalService.dismissAll();
        productID = "";
      }).catch((reason: any) => {
        ////debugger;
        productID = "";
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
      //  //console.log("Detailed Drug Name" + this.currentSelectedDrug[0].detailedDrugName);
      //console.log(this.currentSelectedDrug);
      //console.log("blank" + productID);
    }
  }

  itemSelected(event: any) {
    //console.log("Selected Event" + JSON.stringify(event));
    debugger;
    let medName:string="";
    if(event.item==undefined){
      medName=this.drugSearchModel?.value;
    }else{
      medName=event.item;
    }
    if(this.uniqueArr.length>0 && this.uniqueArr.filter(v=>v==medName).length>0){
      this.drugSearchModel?.patchValue(medName);
      this.onChangeNDC(medName);
    }else if(medName.length>2){
          this.invalidNDC=true;
          this.drugSearchModel?.setErrors({ 'incorrect': true });
    }
  }

  diagnosisSelected(event:any){
    debugger;
    let diagnosisCode:string="";
    if(event.item==undefined){
      diagnosisCode=this.DiagnosisCode?.value;
    }else{
      diagnosisCode=event.item;
    }
      if(this.diagnosisArry.length>0 && this.diagnosisArry.filter( v=> v==diagnosisCode).length>0){
      this.DiagnosisCode?.patchValue(diagnosisCode);
      }else if(this.DiagnosisCode?.value.length==0){
        this.searchFailed=false;
      }else{
        this.searchFailed=true;
      } 
  }
  // for seraching NDC and showing dropdown
  // searchNDC = (text$: any) =>
  // text$.pipe(
  //   debounceTime(200),
  //   distinctUntilChanged(),
  //   map((term: string) => term.length < 3 ? []
  //     : this.uniqueArr.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  // );


  searchNDC = (text$: any) =>
  text$.pipe(
    debounceTime(200),
    tap(()=>{this.searching=true; this.invalidNDC=false;}),
    distinctUntilChanged(),
    switchMap(async (term: string) => term.length > 2 ? await this.searchTerm(term)
      : []
  ),tap(()=>{this.searching=false;}));

  searchTerm(drugBrand:any): Promise<any>{
    const promise: Promise<string []> = new Promise<string[]>((resolve, reject) => {
    let request: Drugsearchwebrequest = new Drugsearchwebrequest();
    debugger;
        this.invalidNDC=false;
        this.searchByNDC ="";
        this.dropdown = [];
        this.uniqueArr=[];
        this.MedStrength?.reset();
        this.MedStrength?.disable();
        this.medStrengthTitle="";
      if(isNaN(drugBrand))
      request.drugName = drugBrand;
      else
      request.ndc = drugBrand;
      request.instanceId =""+this.memberSearchHelper.getSelectedMember()?.instanceId;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        debugger;
        if (response.status.respCode == '200') {
          //debugger;
          //console.log("Item  found:" + value);
          this.productList = response.response.drugSearchItems;
          // this.selectedMedicine[0].drugSearchItem = response.response.drugSearchItems[0];
          if(this.productList.length>1){
            debugger;
          this.uniqueArr = [...new Set(this.productList.map(data => data.productNameExt))];   
          resolve(this.uniqueArr.filter(v => v.toLowerCase().indexOf(drugBrand.toLowerCase()) > -1).slice(0, 10));   
          }else{
            debugger;
            this.uniqueArr = [...new Set(this.productList.map(data => data.productNameExt).concat([...new Set(this.productList.map(data => data.productId))]))];
           // resolve(this.uniqueArr.filter(v => v.toLowerCase().indexOf(drugBrand.toLowerCase()) > -1).slice(0, 10)); 
           this.searching = false;
           this.MedStrength?.disable();
           this.dropdown = this.productList;
           if(drugBrand==this.productList[0].productId){
            this.searchByNDC = this.productList[0].productId;
            this.drugSearchModel?.setValue(""+this.productList[0].productId);
            this.MedStrength?.setValue(this.productList[0].productId);
            this.onChangeNDC(drugBrand);
            // sessionStorage.setItem("DrugDetails",""+this.uniqueArr);

           }else{
            this.drugSearchModel?.setValue(""+this.uniqueArr[0]);
           }
           resolve([]);
          }
        } else {
          //console.log("Not  found:");
          //debugger;
          this.invalidNDC=true;
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
          this.uniqueArr=[];
          this.MedStrength?.reset();
          this.MedStrength?.disable();
          resolve(this.uniqueArr);
        }
      }, (error: any) => {
        //debugger;
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      }).catch((reason: any) => {
        //debugger;
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
      });
    
    
  });
  return promise;

}
  
     // for seraching Diagnosis Code and showing dropdown
  diagnosisSearch = (text$: any) =>
  text$.pipe(
    debounceTime(200),
    tap(()=>{this.loading=true;}),
    distinctUntilChanged(),
  //   map((term: string) => term.length < 3 ? []
  //     : this.diagnosisCodeList.filter(v => v.toLowerCase().startsWith(term.toLocaleLowerCase())).splice(0, 10))
  // );
  switchMap(async (term: string) => term.length > 2 ? await this.searchdiagnosisTerm(term)
      : []
  ),tap(()=>{this.loading=false;}));

  searchdiagnosisTerm(code:any): Promise<any>{
    debugger;
    this.DiagnosisCode?.setErrors(null);
    this.searchFailed=false;
    const promise: Promise<string []> = new Promise<string[]>((resolve, reject) => {
      let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
      webrequest.diagnosisCode = code;
      webrequest.diagnosisDescription = "";
      this.drugSearchHelper.diagnosisCodeSearch(webrequest).then((response: DiagnosisCodeSearchResponses) => {
        // this.selectedMedicine[0].diagnosisCodeSearchItem = response.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems[0];
     if(response.status.statusCode == "Success"){
      debugger;
     // this.invalidNDC=false;
     this.diagnosisList = response.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems;
      if(this.diagnosisList==undefined){
        this.searchingDiagnosisCode = false;
          //console.log("FailedTestCOde:" + this.diagnosisCodeList);
// check this one 
          this.searchFailed = true;
          this.dropdown = [];
          this.diagnosisArry = [];
          // this.DiagnosisCode?.setErrors({ "incorrect":true });
          resolve(this.diagnosisArry);
          // sessionStorage.setItem("diagnosisDetails",""+this.diagnosisArry);
      }else{
        this.diagnosisArry = [...new Set(this.diagnosisList.map(data => data.diagnosisCodeDetailedName = data.diagnosisCode.trim() + " " + data.diagnosisDescription.trim()))];   
        resolve(this.diagnosisArry.filter(v => v.toLowerCase().indexOf(code.toLowerCase()) > -1).slice(0, 10)); 
      }  
     }
     else{
      this.searchingDiagnosisCode = false;
          //console.log("FailedTestCOde:" + this.diagnosisCodeList);
          this.searchFailed = true;
          this.dropdown = [];
          this.diagnosisArry = [];
          resolve(this.diagnosisArry);
     }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
    });
  });
  return promise;
  }
     
  // //Fetcing Diagnosis Code if there is any change in the input field, minLength 3
  // onChangeDiagnosisInput(event: any, value: string) {
  //   //debugger;
  //   value=event.target.value;
  //   this.searchFailed = false;
  //   if (value.indexOf(' ') != -1)
  //     value = value.substring(0, value.indexOf(' '));

  //   //console.log(event);
  //   if (this.diagnosisSearchValue != value && value.length > 1) {
  //     //console.log("New :" + value + ",Old:" + this.diagnosisSearchValue);
  //     //console.log("changed");
  //     this.diagnosisSearchValue = value;
  //     // Value changed so hitting the API
  //     this.searchingDiagnosisCode = true;
  //     //console.log(event + "\n Value2:" + value);
  //     if(value.length>=3){
  //       //debugger;
  //     let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
  //     webrequest.diagnosisCode = value;
  //     webrequest.diagnosisDescription = "";
  //     this.drugSearchHelper.diagnosisCodeSearch(webrequest).then((response: DiagnosisCodeSearchResponses) => {
  //       if (response.diagnosisSearchResult.message.responseCode == "200") {
  //         this.diagnosisList = response.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems;
  //         this.diagnosisCodeList = this.diagnosisList.map((data: DiagnosisCodeSearchItem) => data.diagnosisCodeDetailedName = data.diagnosisCode.trim() + " " + data.diagnosisDescription.trim());
  //         this.searchingDiagnosisCode = false;
  //         if (this.diagnosisCodeList.length == 1) {
  //           this.DiagnosisCode?.patchValue(this.diagnosisCodeList[0]);
  //         }
  //       } else {
  //         this.searchingDiagnosisCode = false;
  //         //console.log("FailedTestCOde:" + this.diagnosisCodeList);
  //         this.searchFailed = true;
  //       }
  //       //console.log("TestCOde:" + this.diagnosisCodeList);
  //     }, (error: any) => {
  //       this.modalService.dismissAll();
  //       this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
  //     }).catch((reason: any) => {
  //       this.modalService.dismissAll();
  //       this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
  //     });
  //     }
  //   } else {
  //     //console.log("UnChanged");
  //     //console.log("New :" + value + ",Old:" + this.diagnosisSearchValue);
  //   }
  // }

  // Show Modal for Searching Diagnosis Keyword
  showDiagnosisCode() {
    this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});
  }

  goto(routerUrl:string){
    this.logger.userAction("Goto Home Page");
    this.router.navigateByUrl("/"+ routerUrl);
  }

  // Show diagnosis keyword serach table with pagination
  searchDiagnosisKeyword() {
    this.logger.userAction("Search Diagnosis Clicked.");
    this.searchDiagnosisClicked = true;
    this.noOfPages = 0;
    this.noOfRecords = 0;
    this.recordsDisplayedCount = "";
    this.currentPage = 1;
    this.diagnosisCodeChecked = "";
    this.diagnosisKeywordResposeObject = [];
    if (this.diagnosisDescription?.value != "") {
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content',windowClass:'loadingContent' ,backdrop:'static'});
      let webrequest: Diagnosissearchwebrequest = new Diagnosissearchwebrequest();
      webrequest.diagnosisCode = "";
      webrequest.diagnosisDescription = ""+this.diagnosisDescription?.value;
      this.logger.log("Diagnosis Search:" + JSON.stringify(webrequest));
      this.drugSearchHelper.diagnosisDescriptionSearch(webrequest).then((data: DiagnosisCodeSearchResponses) => {
        if (data.diagnosisSearchResult.message.responseCode == '200') {
          this.diagnosisSearchHistory = [];
          this.diagnosisKeywordResposeObject = data.diagnosisSearchResult.diagnosisCodeSearchResponse.diagnosisCodeSearchItems.filter((data) => data.diagnosisQualifier == '02' && data.diagnosisType == '1');

          this.noOfRecords = this.diagnosisKeywordResposeObject.length;

          this.noOfPages = Math.ceil(this.diagnosisKeywordResposeObject.length / 20);
          this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.currentPage * 20 <= this.noOfRecords ? this.currentPage * 20 : this.noOfRecords);
          this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice(((this.currentPage - 1) * 20), (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords)));
          this.modalService.dismissAll();
          this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});
        } else {
          this.modalService.dismissAll();
          this.modalService.open(this.diagnosisCode, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
    } else {
      this.loadDiagnosisHistory([]);
    }
  }

   // Load previous page in Diagnosis Keyword Search DataTable
   loadPreviousDiagnosisKeywords() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.currentPage * 20 <= this.noOfRecords ? this.currentPage * 20 : this.noOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), this.diagnosisCodeChecked);
    }
  }

  // Load next page in Diagnosis Keyword Search DataTable
  loadNextDiagnosisKeywords() {
    if (this.currentPage < this.noOfPages) {
      this.currentPage++;
      this.recordsDisplayedCount = "" + ((this.currentPage - 1) * 20 + 1) + " - " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), this.diagnosisCodeChecked);
    }

  }

  // Selecting a Diagnosis Keyword from the DataTable
  selectDiagnosisKeywordClicked() {
    //console.log("DiagnosisCodechecked:" + this.diagnosisCodeChecked);
    if (this.diagnosisCodeChecked != "") {
      var data = this.diagnosisKeywordResposeObject.filter((data) => data.diagnosisCode == this.diagnosisCodeChecked);
      this.diagnosisList = this.diagnosisKeywordResposeObject;   
      this.DiagnosisCode?.setValue(data[0].diagnosisCode.trim() + " " + data[0].diagnosisDescription.trim());
      this.searchFailed = false;
      this.modalService.dismissAll();
    }
  }


  // To handle sorting event on Dianogis keyword DataTable headers click
  onDiagnosisHeaderClick(cellData: TableClickEvent<DiagnosisCodeSearchItem>) {

  }

  //To handle select event on Diagnosis Keyword items in DataTable 
  onDiagnosisCellClick(cellData: TableClickEvent<DiagnosisCodeSearchItem>) {
    if (cellData.cell?.data?.diagnosisCode != this.diagnosisCodeChecked) {
      this.diagnosisCodeChecked = cellData.cell?.data?.diagnosisCode;
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), cellData.cell?.data?.diagnosisCode);
    } else if (cellData.cell?.data?.diagnosisCode == this.diagnosisCodeChecked) {
      this.diagnosisCodeChecked = "";
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
    } else {
      this.loadDiagnosisHistory(this.diagnosisKeywordResposeObject.slice((this.currentPage - 1) * 20, this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords), cellData.cell?.data?.diagnosisCode);
    }


  }

  pharmacyLoader:boolean=false;
  addPharmacy(){
    debugger;
     this.pharmacySearchHistory = [];
      this.pharmacyLoader=true;
     
    let request :PharmacySearchRequest = new PharmacySearchRequest();
    let plan:PlanSearch = new PlanSearch();
    this.addPharmacyForm.controls['zipcode'].setValue(this.memberDetails.address.zip1);
    let addr:Address = new Address();
    request.address=addr;
    request.address.zip = this.memberDetails.address.zip1;
    request.searchRadius = "5";
    request.dispenserClassCode=[""];
    request.primaryProviderTypeCode=[""];
    request.planSearch = plan;
    request.planSearch.planCode= ""+this.memberDetails.planId;
    request.planSearch.planEffectiveDate = ""+this.memberDetails.planEffectiveDate;
    request.pharmacyName = "";
    request.sourceSystemInstance = this.memberDetails.instanceId;
    this.zipCodeSearched=request.address.zip;
    this.pharmacynoOfRecords=0;
    
    this.drugSearchHelper.getPharmacydetails(request).then((response: PharmacySearchResponse) => {
      // this.selectedMedicine[0].selectedPharmacyDetail = response;
      if(response.pharmacySearchResponse!=undefined && response.status.respCode=='200'){
        // this.pharmacySearchHistory=[];
        this.pharmacyresposeObject = response.pharmacySearchResponse.pharmacies;
        // this.pharmacyresposeObject=this.pharmacyresposeObject.sort((a, b) => a.pharmacyName.localeCompare(b.pharmacyName));
        this.pharmacynoOfRecords = response.pharmacySearchResponse.pharmacies.length;
        this.pharmacynoOfPages = Math.ceil(this.pharmacynoOfRecords / 8);
          this.pharmacyrecordsDisplayedCount = "" + ((this.currentPage - 1) * 8 + 1) + " - " + (this.currentPage * 8 <= this.pharmacynoOfRecords ? this.currentPage * 8: this.pharmacynoOfRecords);
        this.loadPharmacyHistory(this.pharmacyresposeObject.slice(((this.currentPage - 1) * 8), (this.pharmacynoOfRecords >= this.currentPage * 8 ? this.currentPage * 8 : this.pharmacynoOfRecords)));
        
      }
      else if(response.pharmacySearchResponse!=undefined && response.status.respCode=='404'){
        // this.pharmacySearchHistory=[];
        this.pharmacynoOfRecords=0;
        this.pharmacynoOfPages=0;
        this.errorMessage = "No pharmacies found within"+" "+this.addPharmacyForm.controls['distance'].value+" " +"mile(s) of"+" "+this.addPharmacyForm.controls['zipcode'].value;
      }
      else{
        this.errorMessage = "No pharmacies found within"+" "+this.addPharmacyForm.controls['distance'].value+" "+"mile(s) of"+" "+this.addPharmacyForm.controls['zipcode'].value;
      }
  
      this.pharmacyLoader=false;
    }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
  this.modalService.open(this.addPharmacyTemplate, { modalDialogClass: 'content', size: 'lg' ,backdrop:'static'});

}

searchPharmacy(value:any){
  debugger;
  this.pharmacySearchHistory=[];
  this.pharmacyLoader=true;
  //debugger;
  if(value.zipcode!=undefined){
    this.blnFormSubmitted=false;

    let request :PharmacySearchRequest = new PharmacySearchRequest();
    let plan:PlanSearch = new PlanSearch();
    let addr:Address =new Address();
    addr.zip = value.zipcode;
    request.address = addr;
    request.searchRadius = value.distance;
    request.dispenserClassCode=[""];
    request.primaryProviderTypeCode=[""];
    request.planSearch = plan;
    request.planSearch.planCode= ""+this.memberDetails.planId;
    request.planSearch.planEffectiveDate = ""+this.memberDetails.planEffectiveDate;
    request.pharmacyName = value.pharmacyName;
    this.zipCodeSearched=request.address.zip;
    this.pharmacynoOfRecords=0;
    debugger;
    request.sourceSystemInstance = this.memberDetails.instanceId;
    this.drugSearchHelper.getPharmacydetails(request).then((response: PharmacySearchResponse) => {
      // this.selectedMedicine[0].selectedPharmacyDetail = response;
      if(response.pharmacySearchResponse!=undefined && response.status.respCode=='200'){
        debugger;
        // this.pharmacySearchHistory=[];
        this.pharmacyresposeObject = response.pharmacySearchResponse.pharmacies;
        this.pharmacynoOfRecords = response.pharmacySearchResponse.pharmacies.length;
        this.pharmacynoOfPages = Math.ceil(this.pharmacynoOfRecords / 8);
          this.pharmacyrecordsDisplayedCount = "" + ((this.currentPage - 1) * 8 + 1) + " - " + (this.currentPage * 8 <= this.pharmacynoOfRecords ? this.currentPage * 8: this.pharmacynoOfRecords);
        this.loadPharmacyHistory(this.pharmacyresposeObject.slice(((this.currentPage - 1) * 8), (this.pharmacynoOfRecords >= this.currentPage * 8 ? this.currentPage * 8 : this.pharmacynoOfRecords)));
        
      }
      else if(response.pharmacySearchResponse!=undefined && response.status.respCode=='404'){
        //debugger;
        // this.pharmacySearchHistory=[];
        this.pharmacynoOfRecords=0;
        this.pharmacynoOfPages=0;
        this.errorMessage = "No pharmacies found within"+" "+this.addPharmacyForm.controls['distance'].value+" "+"mile(s) of"+" "+this.addPharmacyForm.controls['zipcode'].value;
      }
      else{
        this.errorMessage = "No pharmacies found within"+" "+this.addPharmacyForm.controls['distance'].value+" "+"mile(s) of"+" "+this.addPharmacyForm.controls['zipcode'].value;
      }
      this.pharmacyLoader=false;
    }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
}
else{
  this.blnFormSubmitted =  true;
}
}

// Pharmacy Search and Pagination
pharmacySearchHeaders: TableHeaderCellData[] = [];;
pharmacySearchHistory: TableRowData<Pharmacy>[] = [];

private pharmacyFirstHeader: TableHeaderCellData = new TableHeaderCellData();
private pharmacySecondHeader: TableHeaderCellData = new TableHeaderCellData();
private pharmacyThirdHeader: TableHeaderCellData = new TableHeaderCellData();
private pharmacyForthHeader: TableHeaderCellData = new TableHeaderCellData();


pharmacynoOfPages: number = 0;
pharmacynoOfRecords: number = 0;
pharmacyrecordsDisplayedCount: string = "";
pharmacycurrentPage = 1;
pharmacyresposeObject: Pharmacy[] = [];

// To load Diagniosis Keyword Search items to DataTable
private loadPharmacyHistory(rawData: Pharmacy[]): void {
  debugger;
  this.pharmacySearchHistory = [];
  rawData.forEach((history: Pharmacy) => {
        const row: TableRowData<Pharmacy> = new TableRowData<Pharmacy>();
  
        const firstCell: TableCellData<Pharmacy> = new TableCellData();
        firstCell.data = history;
        firstCell.sortable = true;
        firstCell.template = this.pharmacyNameTemplate;
        row.cells.push(firstCell);
  
        const secondCell: TableCellData<Pharmacy> = new TableCellData();
        secondCell.data = history;
        secondCell.template = this.pharmacyAddressTemplate;
        row.cells.push(secondCell);
  
        const thirdCell: TableCellData<Pharmacy> = new TableCellData();
        thirdCell.data = history;
        thirdCell.template = this.pharmacydistanceTemplate;
        row.cells.push(thirdCell);
  
        const forthCell: TableCellData<Pharmacy> = new TableCellData();
        forthCell.data = history;
        forthCell.template = this.actionPharmacySearchSelect;
        row.cells.push(forthCell);
  
        this.pharmacySearchHistory.push(row);
  });
}

onPharmacyHeaderClick(cellData: TableClickEvent<Pharmacy>) {
  // debugger;
  //   this.logger.userAction("Sorting pharmacy.");
  //   if (cellData.column == 0) {
  //     if (cellData.cell?.sortAscending == false) {
  //       debugger;
  //       // this.recentMembersHistory = [];
  //     this.loadPharmacyHistory(this.pharmacyresposeObject.sort((a,b)=>{
  //       return parseInt(a.pharmacyId) - parseInt(b.pharmacyId);
  //     }));
  //           } else {
  //             this.loadPharmacyHistory(this.pharmacyresposeObject.sort((a,b)=>{
  //               return parseInt(b.pharmacyId) - parseInt(a.pharmacyId);
  //           }));
  //         }
  //       }
      }

      onPharmacyCellClick(cellData: TableClickEvent<Pharmacy>) {
        //debugger;
       // sessionStorage.clear();
        if(cellData.column==1||cellData.column==2||cellData.column==3||cellData.column==4)
        this.drugSearchHelper.clearSelectedPharmacy();
        this.drugSearchHelper.storeSelectedPharmacy(cellData.cell?.data);

        sessionStorage.setItem("selectedPharmacyDetails",JSON.stringify(cellData.cell?.data?.physicalLocation));
         this.pharmacyDetails= this.drugSearchHelper.getSelectedPharmacy();
         this.pharmacySelectedDetails= JSON.parse(""+sessionStorage.getItem('selectedPharmacyDetails'));
         this.modalService.dismissAll();
        this.blnPharmacySelected=true;
        console.log(this.pharmacyDetails?.pharmacyName);
      }

   // Load previous page in pharmacy Search DataTable
   loadPreviousPharmacies() {
    debugger;
    // this.pharmacySearchHistory=[];
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pharmacyrecordsDisplayedCount = "" + ((this.currentPage - 1) * 8 + 1) + " - " + (this.currentPage * 8 <= this.pharmacynoOfRecords ? this.currentPage * 8 : this.pharmacynoOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadPharmacyHistory(this.pharmacyresposeObject.slice((this.currentPage - 1) * 8, this.pharmacynoOfRecords >= this.currentPage * 8 ? this.currentPage * 8 : this.pharmacynoOfRecords));
    }
  }

  // Load next page inpharmacy Search DataTable
  loadNextPharmacies() {
    debugger;
    // this.pharmacySearchHistory =[];
    if (this.currentPage < this.pharmacynoOfPages) {
      this.currentPage++;
      this.pharmacyrecordsDisplayedCount = "" + ((this.currentPage - 1) * 8 + 1) + " - " + (this.pharmacynoOfRecords >= this.currentPage * 8 ? this.currentPage * 8 : this.pharmacynoOfRecords);
      //console.log("Min: " + ((this.currentPage - 1) * 20) + ", Max: " + (this.noOfRecords >= this.currentPage * 20 ? this.currentPage * 20 : this.noOfRecords));
      this.loadPharmacyHistory(this.pharmacyresposeObject.slice((this.currentPage - 1) * 8, this.pharmacynoOfRecords >= this.currentPage * 8 ? this.currentPage * 8 : this.pharmacynoOfRecords));
    }

  }

  drugSearchSubmit(){
    debugger; 
    
    let SelectedMedicineObject: SelectedMedicine = new SelectedMedicine();
    let memberDetailResponseObject: MemberDetailWebResponsehemi = new MemberDetailWebResponsehemi();
    let drugSearchItemObject:DrugSearchItem = new DrugSearchItem();
    let diagnosisCodeObject:DiagnosisCodeSearchItem = new DiagnosisCodeSearchItem();

    //  this.selectedMember = this.selectedMedicine[0].memberDetailResponsse;
    memberDetailResponseObject = this.selectedMember;
    if(this.currentSelectedDrug!=undefined){
    drugSearchItemObject = this.currentSelectedDrug[0];
    this.drugSearchHelper.storeSelectedMedicine(this.currentSelectedDrug[0]);
    //  diagnosisCodeObject = this.diagnosisArry;
    }
    SelectedMedicineObject.memberDetailResponse= memberDetailResponseObject;
    SelectedMedicineObject.drugSearchItem = drugSearchItemObject ;
    SelectedMedicineObject.quantytyCalculate = this.quantityDesc;
    SelectedMedicineObject.DiagnosisCode = ""+this.DiagnosisCode?.value;
    SelectedMedicineObject.Quantity = ""+this.Quantity?.value;
    SelectedMedicineObject.Days = ""+this.Days?.value;
    SelectedMedicineObject.quantytyDesc=this.quantityDesc;
    SelectedMedicineObject.selectedMember=this.memberDetails;
    SelectedMedicineObject.daw=this.DAW;
    if(this.profileService.loginuser.prescriber?.roleTypeId=="11"){
    SelectedMedicineObject.pharmacyDetailItem=this.drugsearchHelper.getSelectedPharmacistPharmacy();
    }
    else{
    SelectedMedicineObject.pharmacySearchItem=this.drugSearchHelper.getSelectedPharmacy();
    }
    // SelectedMedicineObject.diagnosisCodeSearchItem = diagnosisCodeObject;
    sessionStorage.setItem("selectedMedicine", JSON.stringify(SelectedMedicineObject));
    if(!this.blnInitiatePA && this.addMedicationForm.controls['drugSearchModel'].invalid || this.addMedicationForm.controls['MedStrength'].invalid || this.addPharmacyForm.invalid && this.blnPharmacistChangeButton==false){
      debugger;
      this.blnDrugSearchFormSubmitted = false;
      this.contextualErrorCode = false;
    }
    else{
      debugger;
    this.blnDrugSearchFormSubmitted = true;
    this.contextualErrorCode = true;
    if(this.profileService.USER_ROLE==12 || this.profileService.USER_ROLE==11){
      sessionStorage.setItem("blnSelectPrescriberInCheckCoverage","true");
    this.router.navigateByUrl('/PriorAuthchecker/PrescriberSearch');
    }
    else{
    this.router.navigateByUrl('/PriorAuthChecker/VerifyPrescriber');
    }
    }
     // Adding unique items to recent seraches array
    //  if (this.recentSearches.length == 0) {
    //   debugger;
    //   this.recentSearches.push(this.currentSelectedDrug[0]);
    // } else if (this.recentSearches.map((drugItem) => drugItem.productId).indexOf(this.currentSelectedDrug[0].productId) == -1) {
    //   this.recentSearches.push(this.currentSelectedDrug[0]);
    // }
    // End of Adding unique items to recent seraches array
    
    if (this.recentSearches.length > 0) {
      debugger;
      // if(this.recentSearches.filter((item)=>item.productId!=this.currentSelectedDrug[0].productId)){
      // this.recentSearches.push( drugSearchItemObject) ; 
      // }
      // this.recentSearches = Array.from(new Set(this.recentSearches));
      sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearches));
     } 
    else{
      this.modalService.dismissAll();
    }
  }
    drugSubmit(){
      debugger;
      let SelectedMedicineObject: SelectedMedicine = new SelectedMedicine();
    let memberDetailResponseObject: MemberDetailWebResponsehemi = new MemberDetailWebResponsehemi();
    let drugSearchItemObject:DrugSearchItem = new DrugSearchItem();
    let diagnosisCodeObject:DiagnosisCodeSearchItem = new DiagnosisCodeSearchItem();

    //  this.selectedMember = this.selectedMedicine[0].memberDetailResponsse;
    memberDetailResponseObject = this.selectedMember;
    drugSearchItemObject = this.currentSelectedDrug[0];
    //  diagnosisCodeObject = this.diagnosisArry;
    SelectedMedicineObject.memberDetailResponse = memberDetailResponseObject;
    SelectedMedicineObject.drugSearchItem = drugSearchItemObject ;
    SelectedMedicineObject.quantytyCalculate = this.quantityDesc;
    SelectedMedicineObject.DiagnosisCode = ""+this.DiagnosisCode?.value;
    SelectedMedicineObject.Quantity = ""+this.Quantity?.value;
    SelectedMedicineObject.Days = ""+this.Days?.value;
    SelectedMedicineObject.selectedMember=this.memberDetails;
    SelectedMedicineObject.quantytyDesc=this.quantityDesc;
    SelectedMedicineObject.pharmacySearchItem=this.drugSearchHelper.getSelectedPharmacy();
    SelectedMedicineObject.daw=this.DAW;

    // SelectedMedicineObject.diagnosisCodeSearchItem = diagnosisCodeObject;
    sessionStorage.setItem("selectedMedicine", JSON.stringify(SelectedMedicineObject));
    if(this.blnInitiatePA && this.addMedicationForm.controls['drugSearchModel'].invalid || this.addMedicationForm.controls['MedStrength'].invalid || this.addPharmacyForm.controls['Quantity'].invalid || this.addPharmacyForm.controls['Days'].invalid ){
      this.blnDrugSearchFormSubmitted = false;
      this.contextualErrorCode = false;
    }
    else{
    this.blnDrugSearchFormSubmitted = true;
    this.contextualErrorCode = true;
    let request: Drugsearchwebrequest = new Drugsearchwebrequest();
    request.ndc = this.currentSelectedDrug[0].productId;
    request.instanceId = ""+this.memberSearchHelper.getSelectedMember()?.instanceId;
    this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
     this.drugDetails = response.response.drugSearchItems;
     console.log(this.drugDetails);
     sessionStorage.setItem("drugDetails",""+this.drugDetails);
     if(this.profileService.USER_ROLE==13){
      sessionStorage.setItem("blnSelectPrescriberInEPACheckCoverage","true");
      this.router.navigateByUrl('ePA/verifyProider');
     }
     else{
    this.router.navigateByUrl('ePA/ePAsubmit');
     }
    });
    }
    if (this.recentSearches.length > 0) {
      debugger;
      // if(this.recentSearches.filter((item)=>item.productId!=this.currentSelectedDrug[0].productId)){
      // this.recentSearches.push( drugSearchItemObject) ; 
      // }
      // this.recentSearches = Array.from(new Set(this.recentSearches));
      sessionStorage.setItem("recentDrugSearches",JSON.stringify(this.recentSearches));
     } 
  }


  calculateQuantity() {
    debugger;
    if (this.Quantity?.valid && this.Days?.valid && this.currentSelectedDrug!=undefined) {
      let object = this.quantityService.quantityCalculate(parseInt(""+this.Quantity?.value), this.currentSelectedDrug[0]);
      this.quantityCalculate = parseFloat(object.qtycaliculated);
      this.quantityEntered = object.qtyEntered;
      this.quantityDesc = object.qtydesc;
      this.quantityCalculated = "Based on quantity entered, " + object.qtydesc + " will be submitted.";
      //console.log("quantitycalculated", object);
      // sessionStorage.setItem("quantityCalculated",this.quantityDesc);
    } else {
      this.quantityCalculated = "";
    }
  }

  savefavourite(drugDescription: string, productId: string) {
    debugger;
    this.logger.userAction("Save Favourite medication.");
    //console.log("Saveing:" + drugDescription + " : " + productId + " : " + this.selectedDrugItems[index].productIdQlfr + " : " + index);
    let webrequest: AddFavRootObject = new AddFavRootObject();
    let medicationPrescribedObject: AddMedicationPrescribed = new AddMedicationPrescribed()
    let drugCodedObject: DrugCoded = new DrugCoded();
    let drugCodedObjectArr: DrugCoded[] = [];
    drugCodedObjectArr.push(drugCodedObject);
    drugCodedObject.code = productId;
    webrequest.medicationPrescribed = medicationPrescribedObject;
    webrequest.medicationPrescribed.drugDescription = drugDescription;
    webrequest.medicationPrescribed.drugCoded = drugCodedObjectArr;
    webrequest.medicationPrescribed.productIdQlfr = this.currentSelectedDrug[0].productIdQlfr;
    webrequest.medicationPrescribed.drugInfoId = "0";
    webrequest.loggedPresbrId = "" + this.profileService.loginuser?.prescriber?.presbrId;
    webrequest.loggedUuid = "" + this.profileService.loginuser?.prescriber?.uuid;
    this.drugSearchHelper.addFavouriteDrug(webrequest).then((data: FavDrugStatusResponse) => {
      debugger;
      //console.log("Saving Data:" + data.status.statusDesc + data.status.statusDesc.indexOf('Limit exceed'));
      if (data.status.statusCode == "Success" && data.status.statusDesc.indexOf('Limit exceed') < 0) {
        debugger;
        //console.log("Saved");
        this.savedSearchesDrugCodes.push(productId);
        this.reloadSavedSearches();
      } else if (data.status.statusDesc.indexOf('Limit exceed') >= 0) {
        this.errorText = "Your saved drug limit is ten. Please remove an existing saved drug to continue.";
        this.modalService.open(this.content, { modalDialogClass: 'content' ,backdrop:'static'});
      }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
    });
  }


  removefavourite(productID: string | undefined) {
    debugger;
    this.logger.userAction("Remove favourite Medication Clicked.");
    var temp: TableRowData<FavoriteDrugsDetail>[];
    temp = [];
    var drugInfoId: string | null | undefined = "";
    this.savedSearchHistory.forEach((element: TableRowData<FavoriteDrugsDetail>) => {
      var tempRow: TableRowData<FavoriteDrugsDetail> = new TableRowData<FavoriteDrugsDetail>();
      element.cells.forEach((item: TableCellData<FavoriteDrugsDetail>) => {
        //console.log(item.data?.drugCoded[0].code + '==' + productID);  //testing
        //console.log(item.data?.drugCoded[0].code == productID);  //testing

        if (item.data?.drugCoded[0].code == productID && drugInfoId == "") {
          //console.log("hello:" + item.data?.drugCoded[0].code + '==' + productID);
          //console.log("Item Data" + item.data?.drugInfoId);
          drugInfoId = item.data?.drugInfoId;

        } else {
          tempRow.cells.push(item);
        }
      });
      temp.push(tempRow);

    });
    this.savedSearchHistory = temp;
    //console.log("UnFavourite The Drug:" + drugInfoId + ":" + productID);



    let webrequest: RemoveFavRootObject = new RemoveFavRootObject();
    let medicationPrescribed: MedicationPrescribed = new MedicationPrescribed();
    medicationPrescribed.drugInfoId = drugInfoId;
    webrequest.medicationPrescribed = medicationPrescribed;
    webrequest.loggedPresbrId = ""+this.profileService.loginuser?.prescriber?.presbrId;
    webrequest.loggedUuid = ""+this.profileService.loginuser?.prescriber?.uuid;

    this.drugSearchHelper.removeFavouriteDrug(webrequest).then((data: FavDrugStatusResponse) => {
      //console.log(data);
      if (data.status.statusCode == "Success") {
        this.savedSearchesDrugCodes = this.savedSearchesDrugCodes.filter(data => data != productID);
        this.savedSearchHistory = [];
        this.reloadSavedSearches();
      }
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
    }).catch((reason: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs',backdrop:'static' });
    });
  }


  // To set the favourite star value for the claim
  checkFavourites(medStrength: string) {
    // //console.log("Fav Index:" + this.savedSearchesDrugCodes.indexOf(medStrength) + "" + this.savedSearchesDrugCodes.length);
    return this.savedSearchesDrugCodes.indexOf(medStrength);
  }
  
  show(){
    this.blnShowHide = false;
  }
  hide(){
    this.blnShowHide = true;
  }

  changeMember(){
    this.blnMemberEdit = true;
    sessionStorage.setItem("blnMemberEdit",""+this.blnMemberEdit);
    this.goto('/ePA/MemberSearch');
  }
  back(event: any): void {
      this.logger.userAction("Back clicked.");
      event.preventDefault();
      this.router.navigateByUrl("/PriorAuthchecker/MemberSearch");
  }
  back1(event: any): void {
    this.logger.userAction("Back clicked.");
    event.preventDefault();
    this.router.navigateByUrl("/ePA/MemberSearch");
}
  cancelRequest(){
    this.modalService.open(this.cancelPAConfirmationModal,{size:'md',backdrop:'static'});
  }
  cancel(){
    this.router.navigateByUrl("/home");
  }

  // To handle sorting event on recentSearches headers click
  onRecentHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

    if (headerData.column == 0) {
      if (headerData.cell?.sortAscending == false) {
       
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(a.productId) - parseInt(b.productId);
        }));
      } else {
       
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(b.productId) - parseInt(a.productId);
        }));
      }
    }
  }

  //To handle select event on recent seraches items 
  onRecentCellClick(cellData: TableClickEvent<DrugSearchItem>) {
    debugger;
    if (cellData.column == 0 || cellData.column==1 || cellData.column==2) {
      // For Setting the Medication Name
      this.logger.userAction("Selecting Recent Medication");
      this.addMedicationForm.reset();
      var productID: string | undefined = cellData.cell?.data?.productId;
      var MedName: string | undefined = cellData.cell?.data?.productNameExt ;
      //console.log("drugDetails:" + drugDetails);
      this.drugSearchModel?.setValue(MedName);

      // For Setting the Mediaction Strength
      let request: Drugsearchwebrequest = new Drugsearchwebrequest();
      request.ndc = ""+cellData.cell?.data?.productId;
      this.drugSearchHelper.getDrugSearchItems(request).then((response: Drugsearchwebresponse) => {
        if (response.status.respCode == '200') {
          debugger;
          this.dropdown = response.response.drugSearchItems;
          this.formLoading = false;
          this.MedStrength?.enable();
          var drugItem: DrugSearchItem[] = this.dropdown.filter(data => data.productId == productID);
          this.MedStrength?.setValue(drugItem[0].productId);
          this.modalService.dismissAll();
          this.onChangeMediactionStrenth();
        } else {
          this.drugSearchModel?.setErrors({ 'incorrect': true });
          this.formLoading = false;
          this.dropdown = [];
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
      });
    }
  }

  updateDAW() {
    this.DAW=='0'?this.DAW='1':this.DAW='0';
    }

    private getCurrentPageMembers(): DrugSearchItem[] {
      debugger;
  
      if (this.recentSearches.length <= this.recordsPerPage) {
        return this.recentSearches;
      }
      else {
        const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
        const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
        let result = this.recentSearches.slice(startIndex, endIndex);
        return result;
      }
  
    }
    onPageChange(pageNumber: any) {
      debugger;
      this.currentPageNumber = pageNumber;
      this.loadRecentSearchHistory(this.recentSearches);
    }
    private updateTotalPagesCount(): void {
      this.totalPagesCount = (this.recentSearches.length % this.recordsPerPage) != 0 ? Math.floor(this.recentSearches.length / this.recordsPerPage) + 1 : Math.floor(this.recentSearches.length / this.recordsPerPage);
    }

    refreshPage(){
      debugger;
      this.pharmacyLoader = true;
      this.ngOnInit();
    }
}
