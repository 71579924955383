<!-- <div class="container-fluid bg-white">
    <div class="row">
        <div class="col-sm-6 bg-white">
            <span class="paragraph">Search by Pharmacy Name</span>
        </div> 
    </div>
</div> -->
<div class="col-md-12 col-sm-12 col-xs-12">
    <!----<div class="col-lg-10 col-xl-8 offset-xl-2 offset-lg-1">-->
        <ul class="nav nav-tabs nav-justified" role="tablist">
             <li class="nav-item">
                 <a class="nav-link text-center" class="active" aria-controls="PA_Notifications" href="javascript:void(0)">Notifications</a>
             </li>
        </ul>
</div>
<div class="tab-content" style="border-top: none;" id="myTabContent">
    <div class="tab-pane fade show active" id="pharmacy" role="tabpanel" aria-labelledby="pharmacy_name_tab">
        <div class="card card_size"
            style="background-color: #fff!important;border-radius: 0 0 2px 2px;box-shadow: 0 4px 6px rgba(0,0,0,.2);padding-top: 25px;border-top:  #fff!important">
            <div class="row" style="margin: 0;padding-right: 15px;padding-left: 15px;">
                <div class="card-body nopadding">
                    <form [formGroup]="Pharmacy_Form" id="Pharmacy_Form"
                        (ngSubmit)="onSubmitPharmacy(Pharmacy_Form.value)">
                        <div class="form-group form_width" style="display: flex; flex-direction: column;">
                            <div class="row" >
                                <div class="col-8 offset-1" style="padding-left: 15px; padding-right: 15px;">
                                    <div class="card-text text-muted"
                                        style="color: #626f7d; margin-bottom: 12px; font-weight: 500;font-size :16px;">
                                        Pharmacy
                                        Name
                                        (optional)</div>
                                    <input type="text" formControlName="Pharmacy_Name" class="form-control"
                                        minlength="3" maxlength="35" (focus)="insideTheBox()" (blur)="outsideTheBox()"
                                        [ngClass]="{'error_border':Pharmacy_Form.controls.Pharmacy_Name.errors?.['minlength'] && show}"
                                        style="height:44px;border:2px solid #626f7d;background-color:#fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;">
                                    <div *ngIf="Pharmacy_Form.controls.Pharmacy_Name.errors?.['minlength'] && show"
                                        style="position: relative;padding-top: 10px;padding-bottom: 10px ;align-items: center;display: flex;">
                                        <span class="error-msg">Please enter at least 3 characters.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding: 25px 0;">
                                <div class="col-8 offset-1 nopadding " style="display: flex;">
                                    <div class="col-6 " style="padding-right: 15px;padding-left: 15px;">
                                        <p
                                            style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size :16px;">
                                            ZIP Code</p>
                                        <input type="text" formControlName="Zip_Code" class="form-control" minlength="5"
                                            maxlength="5" required
                                            style="height:44px;border:2px solid #626f7d;background-color:#fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;"
                                            (focus)="insideTheBox()" (blur)="outsideTheBox()" [ngClass]="{'error_border':(Pharmacy_Form.controls.Zip_Code.errors?.['pattern'])
            ||(Pharmacy_Form.controls.Zip_Code.errors?.['minlength'] && show)
            ||(((submittedPharmacyForm==true && Pharmacy_Form.controls.Zip_Code.errors?.['required'])||(Pharmacy_Form.controls.Zip_Code.touched && Pharmacy_Form.controls.Zip_Code.errors?.['required'])) && show)
            ,'error_border_shadow':Pharmacy_Form.controls.Zip_Code.errors?.['pattern']}">
                                        <div
                                            style="position: relative;padding-top: 10px;padding-bottom: 10px ;align-items: center;display: flex;">

                                            <span
                                                *ngIf="Pharmacy_Form.controls.Zip_Code.errors?.['pattern'];else invalid4"
                                                class="error-msg">Invalid character.</span>
                                            <ng-template #invalid4>
                                                <span class="error-msg"
                                                    *ngIf="Pharmacy_Form.controls.Zip_Code.errors?.['minlength'] && show;else invalid5">Please
                                                    enter a valid ZIP code.</span>
                                            </ng-template>
                                            <ng-template #invalid5>
                                                <span class="error-msg"
                                                    *ngIf="((submittedPharmacyForm==true && Pharmacy_Form.controls.Zip_Code.errors?.['required'])||(Pharmacy_Form.controls.Zip_Code.touched && Pharmacy_Form.controls.Zip_Code.errors?.['required'])) && show">Please
                                                    enter a valid ZIP code.</span>
                                            </ng-template>

                                        </div>
                                    </div>
                                    <div class="col-6" style="padding-right: 15px;padding-left: 15px;">
                                        <p
                                            style="color: #626f7d; text-transform: capitalize; margin-bottom: 12px; font-weight: 500;font-size :16px;">
                                            Distance</p>
                                        <select name="distance" formControlName="Distance"
                                            style="width: 100%; height: 44px;border: 2px solid #626f7d;background-color: #fff;font-size: 16px;">
                                            <option value="1">1 mile</option>
                                            <option value="5">5 miles</option>
                                            <option value="10">10 miles</option>
                                            <option value="20">20 miles</option>
                                            <option value="25">25 miles</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" style="margin: 0;">
                <div class="card-footer"
                    style="border-top: #fff; background-color: rgba(242, 246, 249, 1);padding: 0px;">
                    <div class="container c5">
                        <div class="cancel">
                            <span class="underline font_bold_no_color" id='underline1'
                                (mouseover)="underline('underline1')" (mouseout)="removeUnderline('underline1')"
                                (click)="cancel()" style="cursor: pointer;">Cancel</span>
                        </div>
                        <div class=" card-link clear underline font_bold_no_color" href="javascript:void(0)"
                            id='underline2' (mouseover)="underline('underline2')"
                            (mouseout)="removeUnderline('underline2')" (click)="clearClick3()" style="cursor: pointer;">
                            Clear</div>
                        <button class=" btn btn-primary search_button font_bold_no_color" type="submit"
                            form="Pharmacy_Form" style="cursor: pointer;">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--datapoulation code under npi id -->
<div style="position: relative;">
    <div *ngIf="pharmacyList.length>0">
        <div class="container dataTable" style="display: flex;flex-direction: row; padding-top: 30px;">
            <div class="listLength" style="font-weight: 200;font-size: 48px;font-style: normal;color: #424249;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;
          padding: 0;">{{pharmacyList.length}}</div>
            <div class=" container foundAndshowing"
                style="display: flex;flex-direction: column;padding-left: 10px;padding-right: 0%;">
                <div class="container found"
                    style="display:flex;padding-left: 0%;padding-right: 0%;padding-top: 15px;flex-direction: row;">
                    <div
                        style="line-height: 20px;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-size: 18px;color: #555559;padding-right: 20px;">
                        Pharmacy found
                    </div>
                    <hr class="hidden-xs"
                        style="margin-top: 10px;margin-bottom: 10px;margin-right: 19px;border-color: rgb(117, 116, 116);flex-grow: 1;">

                </div>

                <div class="showing"
                    style="padding-top: 5px;color: #626f7d!important;font-size: 14px;line-height: 21px;word-wrap: break-word;font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-weight: 400;font-style: normal;text-align: left;">
                    <div *ngIf="pharmacyList.length>10">
                        Showing
                        {{((currentPageNumber-1)*recordsPerPage)+1}} of {{currentPageNumber * recordsPerPage}} pharmacy
                    </div>
                    <div *ngIf="pharmacyList.length<10">
                        Showing
                        {{((currentPageNumber-1)*recordsPerPage)+1}} of {{pharmacyList.length}} pharmacy
                    </div>
                </div>
            </div>
        </div>
    </div>

    <datatable *ngIf="pharmacyList.length>0" [headers]="PharmacyHeaders" [rows]="pharmacySearchHistory"
        [type]="datatableType" (onHeaderClicked)="onPharmacyHeaderClick($event)"
        (onCellClicked)="onPharmacyCellClick($event)" aria-labelledby="pharmacyResults">
    </datatable>

    <div class="container-fluid pt-5" *ngIf="totalEntries>1">
        <div class="row">
            <div class="col text-center">
                <pagination aria-label="Pharmacyresults pagination" [totalPages]="totalPagesCount"
                    (onPageChange)="onPharmacyPageChange($event)" style="display: flex;justify-content: center;">
                </pagination>

            </div>
        </div>
    </div>


    <div class="col"
        style="margin-top: 15px;margin-bottom: 15px;padding: 35px;background-color:#d4d1d1;font-family: Frutiger-Light,Helvetica,Arial,sans-serif;font-size: 24px;color: #565555;vertical-align: middle;text-align: center;"
        *ngIf="totalPagesCount==0 && (submittedPharmacyForm==true) && (Pharmacy_Form.valid)">
        No search results found.
    </div>
</div>
<ng-template #PharmacyNameTemplate let-data="data">
    <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="PharmacyNameTemplate">
        <p class="large">
            <span class="fontbold">{{data.pharmacyName}}</span>
        </p>
    </div>
</ng-template>

<ng-template #PharmacyAddressTemplate let-data="data">
    <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="PharmacyAddressTemplate">
        <p style="font-size: 14px !important;">
            <span>{{data.physicalLocation.address1}}&nbsp;{{data.physicalLocation.address2}}
                <br />
                {{data.physicalLocation.city}}&nbsp;{{data.physicalLocation.state}}&nbsp;{{data.physicalLocation.zip1}}</span>
        </p>
    </div>
</ng-template>
<ng-template #DistanceTemplate let-data="data">
    <div style="padding: 10px 20px;" aria-labelledby="DistanceTemplate">
        <p class="large" *ngIf="data.physicalLocation.distance < 1"> &lt;1 mi</p>
        <p class="large" *ngIf="data.physicalLocation.distance >= 1">{{data.physicalLocation.distance | number:'1.2-2'}} mi</p>
    </div>
</ng-template>

<ng-template #actionPharmacySearchSelect let-data="data">
    <div style="text-transform: capitalize;padding: 10px 20px;" aria-labelledby="actionPharmacySearchSelect">
        <div style="text-align: center;">
            <a class="text_underline" href="javascript:void(0)" (click)="addpharmaciestoList(data)"
                style="font-size:16px !important;cursor: pointer;color: #0470c5 !important;">Select</a>
        </div>
    </div>
</ng-template>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body">
      <h3 class="text-center text-capitalize">Searching...</h3>
    </div>
  </ng-template>