import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { environment} from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  context_path:string=environment.sso_contextpath;

  constructor(protected router: Router) {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);

  }

  ngOnInit(): void {
  }
  goToPrivacyLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.privacyurl, "_blank");
  }
  goToTermsLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.termsurl, "_blank");
  }
  goToContactusLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.contactusurl, "_blank");
  }
}
