import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationhelperService {

  constructor(private http:HttpClient, private router:Router, private profileService:ProfileService) {
  }

  getRegistrationStatus():Promise<boolean>{
    
    const promise:Promise<boolean> = new Promise<boolean>((resolve:any,reject:any)=>{
      let selfregistrationdetails: string | null = sessionStorage.getItem('selfregistration');
      if(selfregistrationdetails!=null){
        
        const details : any = JSON.parse(selfregistrationdetails);
        let headers:any = new HttpHeaders({"endpoint":environment.registration_status_url.replace('{optumId}',details.ohid),"X-CSRF-Token":this.profileService.getCsrfToken()});
        this.http.post( environment.proxyurl,null,{headers:headers}).subscribe((response:any)=>{         
            if(response!=undefined && response.status!=undefined
              && response.status.statusCode!=undefined && response.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){
                
                if(parseInt(response.status.dbStatusCode)==0 || parseInt(response.status.dbStatusCode)==1 || parseInt(response.status.dbStatusCode)==4)
                {
                  details.currentpage = response;
                  sessionStorage.setItem('selfregistration', JSON.stringify(details));
                  resolve(true);
                }
                else{
                  //2 - Exception in API
                  //3 - Given Optum ID is invalid or OP API throwing error.
                  this.router.navigateByUrl("/error");
                  reject(false);  
                }
            } else{
              this.router.navigateByUrl("/error");
              reject(false);  
            }
        },(error:any)=>{
          this.router.navigateByUrl("/error");
          reject(false);  
        });
      } else{
        this.router.navigateByUrl("/error");
        reject(false);
      }
    });
    return promise;
  }
}
