import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { downloadRTBRegressionSheetRequest } from 'src/app/modals/benefitinquiry/downloadRTBRegressionSheetRequest';
import { downloadRTBRegressionSheetResponse } from 'src/app/modals/benefitinquiry/downloadRTBRegressionSheetResponse';
import { getRTBDetailsRequest } from 'src/app/modals/benefitinquiry/getRTBDetailsRequest';
import { getRTBDetailsResponse } from 'src/app/modals/benefitinquiry/getRTBDetailsResponse';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class BenefitinquiryService {

  constructor(private http: HttpClient,private profileService:ProfileService ) { }
  url = environment.proxyurl

  postGetRTBDetails(webrequest: getRTBDetailsRequest): Promise<getRTBDetailsResponse> {
    const promise: Promise<getRTBDetailsResponse> = new Promise<getRTBDetailsResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getRTBDetailsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.provisoningproxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }
  postDownloadRTBRegressionSheet(webrequest: downloadRTBRegressionSheetRequest): Promise<downloadRTBRegressionSheetResponse> {
    const promise: Promise<downloadRTBRegressionSheetResponse> = new Promise<downloadRTBRegressionSheetResponse>((resolve, reject) => {
      debugger;
      let header=new HttpHeaders({"endpoint":environment.downloadRTBRegressionSheetUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.provisoningproxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        
        if (response.response!=undefined &&response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        reject(error);
      });
    });
    return promise;
  }

  postRTBRegressionUpload(presbrId: string, file: File): Observable<HttpEvent<any>> {
    debugger;
    const formData: FormData = new FormData();
    formData.append('file', file);
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('presbrId', presbrId);
    httpParams = httpParams.append('fileName', file.name);
    // httpParams = httpParams.append('link',environment.uploadFileUrl);
    let header = new HttpHeaders({ "endpoint": environment.RTBRegressionUploadUrl.replace("{presbrId}", presbrId).replace("{file}", file.name) ,"X-CSRF-Token":this.profileService.getCsrfToken()});
    const req = new HttpRequest('POST', environment.proxyurlProvisioningFileUpload, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: header,
      params: httpParams
    });

    return this.http.request(req);
  }

  
}
