import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { AdditionalField, DiagnosisCodeSearchResponses, DrugSearchItem, Drugsearchwebresponse, FavDrugStatusResponse, FavoriteDrugsDetail, FavoriteDrugsDetailResponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { BehaviorSubject } from 'rxjs';
import { PreferredPharmacyWebrequest } from 'src/app/modals/preferredpharmacy/preferredpharmacywebrequest';
import { PreferredPharmacyWebresponse } from 'src/app/modals/preferredpharmacy/preferredpharmacywebresponse';
import { AddFavRootObject, Diagnosissearchwebrequest, Drugsearchwebrequest, MemberSearchItem, RemoveFavRootObject } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { PharmacySearchRequest } from 'src/app/modals/drugSearch/pharmaySearchrequest';
import { Pharmacy, PharmacySearchResponse } from 'src/app/modals/drugSearch/pharmacySearchresponse';
import { MemberSearchV5Item } from 'src/app/modals/membersearch/membersearchwebresponse';
import { Injectable } from '@angular/core';
import { PharmacyDetailItem } from 'src/app/modals/registration/SteponePharmacyDetailsResponse';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})

export class DrugsearchService {
  savedDrugs: BehaviorSubject<FavoriteDrugsDetail[]> = new BehaviorSubject<FavoriteDrugsDetail[]>([]);

  constructor(private http: HttpClient, private logger: LoggerService, private profileService:ProfileService) { }

  getFavouriteDrugs(presbrId: string, UUID: string): Promise<FavoriteDrugsDetailResponse> {
    const promise: Promise<FavoriteDrugsDetailResponse> = new Promise<FavoriteDrugsDetailResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getDrugFavoritesUrl.replace("{presbrId}", presbrId).replace("{UUID}", UUID),"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, null,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
          && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_SUCCESS_NBR) {
          this.savedDrugs.next(response.favoriteDrugsDetail);
          resolve(response);
        } else {
          this.savedDrugs.next([]);
          resolve(response);
        }
      }, (error: any) => {
        this.savedDrugs.next([]);
        this.logger.error(error);
        reject(error);
      });
    });
    return promise;
  }

  getPreferredPharmacies(webrequest:PreferredPharmacyWebrequest): Promise<PreferredPharmacyWebresponse> {
    const promise: Promise<PreferredPharmacyWebresponse> = new Promise<PreferredPharmacyWebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.preferredPharmacyUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        debugger;
        if (response.claimSearchResponse!=undefined && response.status != undefined
          && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
              resolve(response);
            } else {
              this.logger.log(response);
              reject(response);
            }
          }, (error: any) => {
            this.logger.error(error);
            reject(error);
          });
        });
        return promise;
  }


getDrugSearchItems(ndc: Drugsearchwebrequest): Promise<Drugsearchwebresponse> {
  const promise: Promise<Drugsearchwebresponse> = new Promise<Drugsearchwebresponse>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.getDrugSearchDetailsUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, ndc,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined && response.response != undefined && response.response.drugSearchItems != undefined && Array.isArray(response.response.drugSearchItems)
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
      ) {
        if (response.response != undefined && response.response.drugSearchItems != undefined && Array.isArray(response.response.drugSearchItems)) {
          response.response.drugSearchItems.forEach((item: DrugSearchItem) => {
            item.detailedDrugName = this.getDrugDetailedName(item);
          });
        }
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}
getDrugDetailedName(drugSearchItem: DrugSearchItem): string {

  let otcCode: string = "";
  let packageSize: string = "";
  let packageSizeUnitOfMeasure: string = "";
  let packageDesc: string = "";
  let labelName: string = "";

  if (drugSearchItem.otcCode != undefined && (drugSearchItem.otcCode.toLowerCase() == 'p' || drugSearchItem.otcCode.toLowerCase() == 'o')) {
    otcCode = " (OTC)";
  }

  if (drugSearchItem.prodNameAbbr != undefined) {
    //removes special characters equivalent to whitespace into whitespaces.
    labelName = drugSearchItem.prodNameAbbr.replace(/\s+/g, " ").trim();
  }

  if (drugSearchItem.additionalField.length > 0) {

    drugSearchItem.additionalField.forEach((field: AdditionalField) => {
      switch (field.fieldDescription.toLowerCase()) {
        case 'packagesizeunitofmeasure':
          packageSizeUnitOfMeasure = field.fieldStringValue;
          break;
        case 'packagedesc':
          packageDesc = this.sentenceCase(field.fieldStringValue);
          break;
        case 'packagesize':
          if (field.fieldDecimalValue != undefined) {
            packageSize = parseFloat(field.fieldDecimalValue).toString();
          }
          break;
      }
    });
    if (packageDesc.trim().length > 0) {
      packageDesc += '(s)';
    } else {
      packageDesc = '';
    }
  } else {
    return '';
  }

  if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator.toLowerCase() == 'b') {
    return labelName + otcCode;
  }
  else {
    if (drugSearchItem.breakableIndicator != undefined && drugSearchItem.breakableIndicator.toLowerCase() == 'u') {
      if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0) {
        return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
      }
      else if (packageSizeUnitOfMeasure.trim().length == 0 && packageDesc.trim().length == 0) {
        return labelName + otcCode;
      }
      else if (packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length > 0 && packageSize.trim().length == 0) {
        return labelName + ', ' + packageSizeUnitOfMeasure + ' ' + packageDesc + otcCode;
      }
      else if (packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length > 0 && packageDesc.trim().length == 0) {
        return labelName + ', ' + packageSize + ' ' + packageSizeUnitOfMeasure + otcCode;
      }
      else if (packageDesc.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0 && packageSize.trim().length == 0) {
        return labelName + ', ' + packageDesc + otcCode;
      }
      else if (packageDesc.trim().length > 0 && packageSize.trim().length > 0 && packageSizeUnitOfMeasure.trim().length == 0) {
        return labelName + ', ' + packageDesc + otcCode;
      }
      else if (packageSizeUnitOfMeasure.trim().length > 0 && packageSize.trim().length == 0 && packageDesc.trim().length == 0) {
        return labelName + otcCode;
      }
      else {
        return labelName + otcCode;
      }
    }
    else {
      return labelName + otcCode;
    }
  }

  return '';
}

private sentenceCase(str: string): string {
  if ((str === null) || (str === ''))
    return "";
  else
    str = str.toString();

  return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}


diagnosisCodeSearch(webrequest: Diagnosissearchwebrequest): Promise<DiagnosisCodeSearchResponses> {
  debugger;
  const promise: Promise<DiagnosisCodeSearchResponses> = new Promise<DiagnosisCodeSearchResponses>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.diagnosisCodeSearchUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}

diagnosisDescriptionSearch(webrequest: Diagnosissearchwebrequest): Promise<DiagnosisCodeSearchResponses> {
  const promise: Promise<DiagnosisCodeSearchResponses> = new Promise<DiagnosisCodeSearchResponses>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.diagnosisDescriptionSearchUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}

getPharmacydetails(webrequest: PharmacySearchRequest): Promise<PharmacySearchResponse> {
  const promise: Promise<PharmacySearchResponse> = new Promise<PharmacySearchResponse>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.getPharmacySearchDetailshemiUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      debugger;
      if (response.status != undefined  && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT||
          response.status.respCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}
getPharmacydetailshemi(webrequest: PharmacySearchRequest): Promise<PharmacySearchResponse> {
  const promise: Promise<PharmacySearchResponse> = new Promise<PharmacySearchResponse>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.getPharmacySearchDetailshemiUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined && response.pharmacySearchResponse!=undefined 
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT||
          response.status.respCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}


public addFavouriteDrug(webrequest: AddFavRootObject): Promise<FavDrugStatusResponse> {
  debugger;
  const promise: Promise<FavDrugStatusResponse> = new Promise<FavDrugStatusResponse>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.favouriteDrugUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}

public removeFavouriteDrug(webrequest: RemoveFavRootObject): Promise<FavDrugStatusResponse> {
  const promise: Promise<FavDrugStatusResponse> = new Promise<FavDrugStatusResponse>((resolve, reject) => {
    let header=new HttpHeaders({"endpoint":environment.unFavouriteDrugUrl,"X-CSRF-Token":this.profileService.getCsrfToken()});
    this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      if (response.status != undefined
        && response.status.statusCode != undefined
        && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
          response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
      ) {
        resolve(response);
      } else {
        this.logger.log(response);
        reject(response);
      }
    }, (error: any) => {
      this.logger.error(error);
      reject(error);
    });
  });
  return promise;
}

storeSelectedMedicine(medicine: DrugSearchItem): void {
  if (medicine != undefined && medicine != null)
    sessionStorage.setItem('drugdetails', JSON.stringify(medicine));
}

getSelectedMedicine(): DrugSearchItem | undefined {
  let medicineJson: string | null = sessionStorage.getItem('drugdetails');

  try {
    if (medicineJson != null) {
      const medicine: DrugSearchItem = JSON.parse(medicineJson);
      return medicine;
    }
  } catch (e) {
    console.log("Error in parsing the selected medicine from browser session.");
  }

  return undefined;
}

clearSelectedMedicine(): void {
  sessionStorage.removeItem('drugdetails');
}

storeSelectedPharmacy(pharmacy: Pharmacy | undefined): void {
  if (pharmacy != undefined && pharmacy != null)
    sessionStorage.setItem('selectedpharmacy', JSON.stringify(pharmacy));
}

getSelectedPharmacy(): Pharmacy | undefined {
  debugger;
  let pharmacyJson: string | null = sessionStorage.getItem('selectedpharmacy');

  try {
    if (pharmacyJson != null) {
      const pharmacy: Pharmacy = JSON.parse(pharmacyJson);
      return pharmacy;
    }
  } catch (e) {
    console.log("Error in parsing the selected pharmacy from browser session.");
  }

  return undefined;
}

storeSelectedPharmacistPharmacy(pharmacy: PharmacyDetailItem | undefined): void {
  if (pharmacy != undefined && pharmacy != null)
    sessionStorage.setItem('selectedpharmacy', JSON.stringify(pharmacy));
}

getSelectedPharmacistPharmacy():PharmacyDetailItem | undefined {
  debugger;
  let pharmacyJson: string | null = sessionStorage.getItem('selectedpharmacy');

  try {
    if (pharmacyJson != null) {
      const pharmacy: PharmacyDetailItem = JSON.parse(pharmacyJson);
      return pharmacy;
    }
  } catch (e) {
    console.log("Error in parsing the selected pharmacy from browser session.");
  }

  return undefined;
}

clearSelectedPharmacy(): void {
  sessionStorage.removeItem('selectedpharmacy');
}


}



