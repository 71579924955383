import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { DataTableType, TableCellData, TableClickEvent, TableHeaderCellData, TableRowData } from 'src/app/modals/datatable/datatable';
//import { Address } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { Address, PlanSearch, SteponePharmacyRequesthemi } from 'src/app/modals/registration/SteponePharmacyRequest';
import { Pharmacy,  SteponePharmacyResponsehemi } from 'src/app/modals/registration/SteponePharmacyResponse';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pharmacysearch',
  templateUrl: './pharmacysearch.component.html',
  styleUrls: ['./pharmacysearch.component.css']
})
export class PharmacysearchComponent implements OnInit {
 
  @Output() errorHandling = new EventEmitter<any>();

  @Output() selectPharmacyNPI = new EventEmitter<any>();

  @Input() addedPharmacyList:any;

  @Output() pharmacySearchHistorie = new EventEmitter<any>();

  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;


  @ViewChild("PharmacyNameTemplate", { static: true })
  PharmacyNameTemplate?: TemplateRef<any>


  @ViewChild("PharmacyAddressTemplate", { static: true })
  PharmacyAddressTemplate?: TemplateRef<any>

  @ViewChild("DistanceTemplate", { static: true })
  DistanceTemplate?: TemplateRef<any>

  @ViewChild("actionPharmacySearchSelect", { static: true })
  actionPharmacySearchSelect?: TemplateRef<any>

  ngOnInit() {
    sessionStorage.setItem("blnAddUserAdmin",""+true);


  }
  constructor(private fb: FormBuilder,private router: Router, private registrationService: registrationService, private modalService: NgbModal,) {
    this.firstHeader.content = "Name";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.secondHeader.content = "Primary Address";
    this.thirdHeader.content = "NPI";
    this.fourthHeader.content = "Distance";
    this.fifthHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    this.fifthHeader.cssClass = "tablesmallheader25";

    this.PharmacyHeaders = [];
    this.PharmacyHeaders.push(this.firstHeader);
    this.PharmacyHeaders.push(this.secondHeader);
    this.PharmacyHeaders.push(this.fourthHeader);
  }

  datatableType: DataTableType = DataTableType.HIGHLIGHT_ROWS;
  Pharmacy_Form = this.fb.group({
    Pharmacy_Name: [''],
    Zip_Code: ['', [Validators.required, Validators.pattern('[0-9]*$')]],
    Distance: ['5']
  });
  Pharmacy_Role = this.fb.group({
    pharmacyRoleValue: ['1']
  });
  showErrorPopup: boolean = false;
  noSearchResult: boolean = false;
  submittedPharmacyForm: boolean = false;
  pharmacyList: Pharmacy[] = [];
  PharmacyHeaders !: TableHeaderCellData[];
  pharmacySearchHistory!: TableRowData<Pharmacy>[];
  currentPageNumber: number = 1;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalEntries: number = 0;
  totalPagesCount: number = -1;
  filterlist: any;
  activeTab: number = 1;
  timeoutError!:string;
  nomatchesFound!:string;
  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private fourthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();

  MailOrderNPI1: string = '1669498515';
  MailOrderNPI2: string = '1497704431';

  show: boolean = true;

  underline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.add('underlined');
  }

  removeUnderline(elementID: string) {
    const div = document.getElementById(elementID);
    div?.classList.remove('underlined');
  }

  insideTheBox() {
    this.show = false;
  }

  outsideTheBox() {
    this.show = true;
  }

  clearSearchHistory() {
    this.pharmacyList = [];
    this.totalPagesCount = -1;
  }

  closeAllPopups() {
    this.showErrorPopup = false;
    this.noSearchResult = false;
  }

  private sortPharmacy(): void {
    this.pharmacyList.sort((member1: Pharmacy, member2:Pharmacy ) => {
      if (this.firstHeader.sortAscending) {
        const result = member1.pharmacyName.localeCompare(member2.pharmacyName);
        return result !== 0 ? result : member1.pharmacyName.localeCompare(member2.pharmacyName);
      } else {
        const result = member2.pharmacyName.localeCompare(member2.pharmacyName);
        return result !== 0 ? result : member1.pharmacyName.localeCompare(member2.pharmacyName);
      }
    });
  }

  private updateTotalPharmacyPagesCount(): void {
    this.totalPagesCount = (this.pharmacyList.length % this.recordsPerPage) != 0 ? Math.floor(this.pharmacyList.length / this.recordsPerPage) + 1 : Math.floor(this.pharmacyList.length / this.recordsPerPage);
  }

  private loadPharmacySearchItems(rawData: Pharmacy[]): void {
    debugger;
    this.pharmacySearchHistory = [];
    rawData.forEach((item: Pharmacy) => {
      const row: TableRowData<Pharmacy> = new TableRowData<Pharmacy>();
      const firstCell: TableCellData<Pharmacy> = new TableCellData();
      firstCell.data = item;
      firstCell.template = this.PharmacyNameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<Pharmacy> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.PharmacyAddressTemplate;
      row.cells.push(secondCell);
      console.log(secondCell);
      const thirdCell: TableCellData<Pharmacy> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.DistanceTemplate;
      row.cells.push(thirdCell);

      const fourthCell: TableCellData<Pharmacy> = new TableCellData();
      fourthCell.data = item;
      fourthCell.template = this.actionPharmacySearchSelect;
      row.cells.push(fourthCell);

      this.pharmacySearchHistory.push(row);

    });
    this.pharmacySearchHistorie.emit(this.pharmacySearchHistory);
    // console.log("prescriber array");
    // console.log(this.prescriberSearchHistory);
  }

  private getPharmacyCurrentPageMembers(): Pharmacy[] {
    this.totalEntries = this.pharmacyList.length;
    if (this.pharmacyList.length <= this.recordsPerPage) {
      return this.pharmacyList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.pharmacyList.slice(startIndex, endIndex);
      return result;
    }
  }

  openNosearchResultPopup() {
    this.noSearchResult = true;
  }

  openErrorPopup() {
    this.showErrorPopup = true;
  }

  cancel() {
    this.router.navigateByUrl("/provision/addusers");

  }

  clearClick3() {
    this.Pharmacy_Form.get('Distance')?.setValue('5');
    this.Pharmacy_Form.get('Pharmacy_Name')?.setValue('');
    this.Pharmacy_Form.get('Zip_Code')?.setValue('');
  }

  onSubmitPharmacy(value: any) {
    debugger;
    this.closeAllPopups();

    //code for session storage
    if (this.Pharmacy_Role.value?.pharmacyRoleValue == '1') {
      sessionStorage.setItem('selectedPharmacyRole', '11');
    }
    else if (this.Pharmacy_Role.value?.pharmacyRoleValue == '2') {
      sessionStorage.setItem('selectedPharmacyRole', '13');
    }

    this.submittedPharmacyForm = true;

    sessionStorage.clear;
    sessionStorage.setItem('PharmacyName', value?.Pharmacy_Name);
    sessionStorage.setItem('PharmacyZipCode', value?.Zip_Code);
    sessionStorage.setItem('PharmacyDistance', value?.Distance);

    // this.logger.userAction("Search pharmacy via Zip code.");

    if (this.Pharmacy_Form.valid) {
      //clear previous pharmacy/prescriber Search results
      this.clearSearchHistory();

      var pharmacy = new SteponePharmacyRequesthemi();
      pharmacy.planSearch=new PlanSearch();
      pharmacy.address= new Address();   
      pharmacy.address.zip =''+ value?.Zip_Code;
      pharmacy.searchRadius = value?.Distance;    
      pharmacy.dispenserClassCode=[""];
      pharmacy.primaryProviderTypeCode=[""];
      pharmacy.planSearch.planCode="";
      pharmacy.planSearch.planEffectiveDate="";
      pharmacy.pharmacyName = value?.Pharmacy_Name;
      pharmacy.sourceSystemInstance="";

      this.pharmacySearchHistory = [];
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content', windowClass: 'loadingContent',backdrop:'static' });

      this.registrationService.postPharmacyhemi(pharmacy).then((data: SteponePharmacyResponsehemi) => {
        debugger;
        //console.log(data);     
        if (data.status.respCode == "200") {

          //code to print "< 1mile" if distance is 0.0
          // for (let i = 0; i < data.pharmacySearchResponse.pharmacies.length; i++) {
          //   if (data.pharmacySearchResponse.pharmacies[i].physicalLocation.distance == '0.0') {
          //     data.pharmacySearchResponse.pharmacies[i].physicalLocation.distance = '<1';
          //   }
          // }
          //code to lowercase address1 and city and name
          for (let i = 0; i < data.pharmacySearchResponse.pharmacies.length; i++) {
            data.pharmacySearchResponse.pharmacies[i].physicalLocation.address1 = data.pharmacySearchResponse.pharmacies[i].physicalLocation.address1.toLowerCase();
            data.pharmacySearchResponse.pharmacies[i].physicalLocation.city = data.pharmacySearchResponse.pharmacies[i].physicalLocation.city.toLowerCase();           
            data.pharmacySearchResponse.pharmacies[i].pharmacyName=data.pharmacySearchResponse.pharmacies[i].pharmacyName.toLowerCase();
          }


          this.filterlist = data.pharmacySearchResponse.pharmacies;
          for (let i = 0; i < this.filterlist.length; i++) {

            if (this.filterlist[i].ncpdp != undefined && this.filterlist[i].ncpdp.length < 8 && (this.filterlist[i].npi != undefined && this.filterlist[i].npi.length > 0) && (this.filterlist[i].npi != this.MailOrderNPI1 && this.filterlist[i].npi != this.MailOrderNPI2)) {
              this.pharmacyList.push(this.filterlist[i])
            }
          }
          this.sortPharmacy();

          this.pharmacyList = this.pharmacyList.slice(0, 100);
          console.log(this.pharmacyList);
          this.currentPageNumber = 1;
          this.updateTotalPharmacyPagesCount();
          this.loadPharmacySearchItems(this.getPharmacyCurrentPageMembers());
          this.modalService.dismissAll();

        }
        else if (data.status.respCode == "404") {
         this.modalService.dismissAll();
          this.openNosearchResultPopup()
          this.pharmacyList = [];
          this.currentPageNumber = 1;
          this.totalPagesCount = 0;
        }
        else {
          // this.openErrorPopup();
          this.showErrorPopup = false;
          this.modalService.dismissAll();
          if(data.status.statusCode=="Failure"){
            sessionStorage.setItem("timeoutErrorvalue","true");     
            this.timeoutError = ""+sessionStorage.getItem("timeoutErrorvalue"); 
            this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
          }
        }
      }, (error: any) => {
        debugger;
        this.modalService.dismissAll();
         if(error.status.respCode==APPCONSTANTS.API_RESPONSE_NO_RECORDS){
          debugger;
          sessionStorage.setItem("nomatchingrecordsvalue","true");
          this.nomatchesFound = ""+sessionStorage.getItem("nomatchingrecordsvalue");
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
         if(error.status.respCode=="504"){
          debugger;
          sessionStorage.setItem("timeoutErrorvalue","true");     
          this.timeoutError = ""+sessionStorage.getItem("timeoutErrorvalue"); 
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
      });
        // this.modalService.dismissAll();
        // this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }
  }
  onPharmacyPageChange(pageNumber: any) {

    this.currentPageNumber = pageNumber;
    this.loadPharmacySearchItems(this.getCurrentPharmacyPageMembers());
  }

  private getCurrentPharmacyPageMembers(): Pharmacy[] {

    this.totalEntries = this.pharmacyList.length;
    if (this.pharmacyList.length <= this.recordsPerPage) {
      return this.pharmacyList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.pharmacyList.slice(startIndex, endIndex);
      return result;
    }
  }

  onPharmacyHeaderClick(headerClickEvent: TableClickEvent<TableHeaderCellData>) {
    if (headerClickEvent.cell != undefined) {
      this.sortPharmacy();
      this.loadPharmacySearchItems(this.getPharmacyCurrentPageMembers());
    }
  }

  onPharmacyCellClick(cellData: TableClickEvent<Pharmacy>) {



        // pharmacy1.npi = '' + cellData.cell?.data?.npi;
        
    
  }

  addpharmaciestoList(item:Pharmacy){
    debugger;
     this.selectPharmacyNPI.emit(item);
  }



}
