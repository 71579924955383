<div>
  <div [ngClass]="{'back1':oldSessionLayout,'back3':newSessionLayout}">
    <div class="container-fluid " [ngClass]="{'back2':oldSessionLayout,'back4':newSessionLayout}">
        <div class="container-fluid c1">
            <div class="row" style="margin: 0px;">
              <div class="col-2" style="padding-right: 0px;width: auto;">
              <img class="header_pcms_logo responsive font_color" src="assets/images/u407.jpeg" alt="HTML5 Icon">
              </div>
              <div class="col" style="padding: 0px;margin-top: 10px;margin-bottom: 10px">
                <div class="page_header header_font" >
                  PreCheck MyScript Registration
                </div>
              </div>
            </div>         
        </div>
        <div class="container c4" style="padding-left:0;">   
            <div class="tab-content justify-content-center" >
              <div class="tab-pane fade show active"  role="tabpanel" >
                <div class="row" style="margin: 0px;" *ngIf="newSessionLayout">
                  <div style="width: 50%;padding-left:5px">
                  <h1 style="position: relative;color: #fff !important;margin-bottom: 0;margin-top: 50px;white-space: nowrap;" class="ng-binding">Welcome Back!</h1>
                  <h6 style="position: relative;color: #fff;margin-top: 37px;font-size: 18px;" class="ng-binding">We sent a verification PIN to  <b class="ng-binding">{{faxNumber}}</b>.</h6>
                  <h6 style="margin-bottom: 3px;margin-bottom: 3px;position: relative;color: #fff;margin-top: 37px;font-size: 18px;" class="ng-binding">Enter your PIN below to verify your</h6>
                  <h6 style="margin-top: 0px;color: #fff;font-size: 18px;" class="ng-binding">PreCheck MyScript registration.</h6>
                  </div>
                  <div style="width:50%;text-align: center;padding-left: 0;position: relative;top: -45px;">
                  <img src="assets/images/u1.jpeg" alt="HTML5 Icon" style="width: 390px;height: 360px;">
                  </div>
                  </div>
                <div *ngIf="oldSessionLayout" style="padding-top: 52px;padding-bottom: 10px;font-family: Frutiger-Roman, Helvetica, Arial, sans-serif;
                font-size: 18px;color: #fff;">
                    Step 3 of 3: Enter PIN
                </div>
                <div class="card card_size"  [ngClass]="{'old_card':oldSessionLayout,'new_card':newSessionLayout}">
                  <div class="card-body" style="padding: 30px;">
                    <div *ngIf="oldSessionLayout" class="card-text text-muted " style="color: #424249 !important;  padding-bottom: 12px;font-size: 16px;">We sent a verification PIN to <span class="font_bold_no_color">{{faxNumber}}</span>. This PIN will expire in 3 days.</div>
                    <div class="card-text text-muted " [ngClass]="{'old_verfication_text':oldSessionLayout,'new_verfication_text':newSessionLayout}">Enter verification PIN</div>
                      <form [formGroup]="PIN_Form" id="fax-verfication" >
                        <div class="form-group">
            
                      <input [ngClass]="{'error_border':PIN_Form.controls.PIN_Field.errors?.['minlength'] && show || PIN_Form.controls.PIN_Field.errors?.['pattern'] || (exceeded && show) || (PIN_Form.valid &&invalidOtp && show),'error_border_shadow':PIN_Form.controls.PIN_Field.errors?.['pattern']}" formControlName="PIN_Field" (focus)="insideTheBox()" (blur)="outsideTheBox()" (input)="changeInInput(PIN_Form.value)" type="text" maxlength="6" minlength="6" class="form-control" 
                      style="height: 44px;border: 2px solid #626f7d;background-color: #fff;color: #050709;width: 100%;padding: 10px;border-radius: 0 0 2px 2px;font-size: 20px;text-align: center;">
                      <div style="position: relative;height: 20px;align-items: center;display: flex;">
                        
                      <span class="error-msg" *ngIf="(PIN_Form.controls.PIN_Field.errors?.['pattern']);else invalid0" >Invalid character.</span>
                      <ng-template #invalid0>
                      <span class="error-msg" *ngIf="PIN_Form.controls.PIN_Field.errors?.['minlength'] && show;else invalid1" >Please enter a 6-digit PIN.</span>
                      </ng-template>
                      <ng-template #invalid1>
                        <span class="error-msg" *ngIf=" exceeded && show" >The PIN you entered has expired.
                        
                        <span style="cursor: pointer;text-decoration: underline;" (click)="onResendPin(2)"> Request a new PIN</span>
                        </span>

                        <span class="error-msg" *ngIf="PIN_Form.valid &&invalidOtp && show" >We cannot verify the PIN you entered. Try entering it again or contact us at 1-866-842-3278.</span>
                      </ng-template>
                      </div>

                      <!-- <ng-template #invalid1>
                      <div *ngIf="PIN_Form.controls.PIN_Field.errors?.['required']">
                        <small class="text-danger">Prescriber NPI is required.</small>
                      </div>
                    </ng-template> -->
            
                        </div>
                      </form>
  
  
                  </div>
                  <div class="card-footer" style="border: none;background-color: rgba(242, 246, 249, 1);">  
                    <div class="container c5" style="padding-left: 9px;padding-right: 9px;" >
                       <div style="color: #424249 !important;;padding: 10px;padding-right: 0%;padding-left: 0%;font-weight: 500;font-size: 16px;">Didn't receive a fax?</div>
                        <div class="cancel font_bold_no_color" style="padding-left: 0;"><span style="cursor: pointer;font-size: 16px;" (click)="onResendPin(1)"> Resend my PIN</span></div>
                        <div class=" card-link clear font_bold_no_color" style="cursor: pointer;font-size: 16px;" (click)="openPopup()" >Cancel registration</div>
                        <button class=" btn btn-primary search_button font_bold_no_color" [disabled]="!isFormValid()" (click)="onSubmitPIN()" style="cursor: pointer;" type="submit" form="PIN_Form">Submit</button>
                      </div> 
                    </div>
                </div>
  
              </div>
              </div> 
      </div>
  
        <div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;">
          <div class="row" style="height: 6px;position: fixed;width: 101%;z-index: 1002;" [ngClass]="{'display_none':newSessionLayout}">
            <div class="col-12" style="background-color: rgba(251,176,60,1);"></div>
            <!-- <div class="col-4" style="background-color: rgba(254,239,216,1)"></div> -->
          </div>
          <div style="display:flex;flex-direction:row;justify-content: center;" *ngIf="showErrorPopup==true">
          <div style="display:flex;color: #c60f13;;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #fff4f4;
          width: 76%;flex-direction:row;"  >
           
           <i class="fa fa-ban" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
            font-size: 16px;flex-grow: 1;">We're unable to complete your request at this time. Please try again later.</span>
            <div class="fa fa-remove" style="font-size: 16px;cursor: pointer;" (click)="closeErrorPopup()"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="footer " class="container-fluid" style=" padding-top: 30px;position: absolute;bottom: 0%;"  >
        <div class="row " style=" background-color: #424249;">
          <div class="col-md-12 text-center" style="padding-right: 15px;
          padding-left: 15px;padding-top: 15px;">
            <p style="color: white">© 2023 Optum, Inc. All rights reserved
            <span> | </span>
            <span href="">Privacy Policy</span>
            <span> | </span>
            <span href="">Terms of Use</span>
            <span> | </span>
            <span href="">Terms of Use</span>
          </p>
          </div>
        </div>
    </div> -->
</div>
  </div>
  
  <!-- <div class="popup-greybackground" *ngIf="showPopup" ></div>
  <div class= "popup-container" *ngIf="showPopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <div class="popup-text">
      Are you sure you want to cancel this registration request?
      <br />
      You will not be able to access it after this point.      
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
    <button class="btn" (click)="onNoClick()" style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    margin: 0 10px;
    padding: 10px 20px;
    width: 75px;
    height: 45px;
    ">
      No
    </button>
    <button class="btn btn-primary" (click)="onYesClick()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 75px;height: 45px;">
      Yes
    </button>
  </div>
  </div>
  </div> -->

  <div class="popup-greybackground" *ngIf="showPopup" ></div>
<div class= "popup-container" *ngIf="showPopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" style="font-size:40px;" aria-hidden="true"></i></div>
    <div class="popup-text" style="margin-top:30px;margin-bottom:40px;">
      Are you sure you want to cancel this registration request?
      <br />
      You will not be able to access it after this point.      
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
    <button class="btn" (click)="onNoClick()" style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    margin: 0 10px;
    padding: 10px 20px;
    width: 75px;
    height: 45px;
    ">
      No
    </button>
    <button class="btn btn-primary" (click)="onYesClick()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 75px;height: 45px;">
      Yes
    </button>
  </div>
  </div>
</div>

  <div class="popup-greybackground" *ngIf="newPinSent" ></div>
  <div class= "popup-container" *ngIf="newPinSent">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <!-- <div class="popup-text">
      Your PIN has been resent. If you have not received your
      <br />
      PIN at the Fax Number you selected, please review the Fax   
      <br/>
      Number to ensure it is correct. You can resume your
      <br/>
      registration once you have received your PIN or you can
      <br/>
      call 1-866-842-3278    
    </div> -->
    <div class="popup-text">
      Your PIN has been resent. If you have not received your PIN at the Fax Number you selected, please review the Fax Number to ensure it is correct. You can resume your registration once you have received your PIN or you can call 1-866-842-3278    
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
    <button class="btn btn-primary" (click)="onOkClick()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding-left: 20px;padding-right: 20px;width: auto;height: 44px;">
      OK
    </button>
  </div>
  </div>
  </div>

  <div class="popup-greybackground" *ngIf="returnMarketPopopup" ></div>
  <div class= "popup-container" *ngIf="returnMarketPopopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <div class="popup-text">
      We are unable to complete your registration online.
      <br/>
      Please call 1-866-842-3278 to register.
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
    <button class="btn btn-primary" (click)="onClickReturnToMarket()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;height: 45px;">
      Return to Link Marketplace
    </button>
  </div>
  </div>
  </div>

  <div class="popup-greybackground" *ngIf="exceededOtpAttempt" ></div>
  <div class= "popup-container" *ngIf="exceededOtpAttempt">
    <div class="popup" >
      <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="popup-text">
        You have entered three incorrect PINs.
        <br/>
        <span style="cursor: pointer;text-decoration: underline;color: #0470c5;" (click)="onResendPin(2)"> Request a new PIN</span>
        or call 1-866-842-3278 for assistance.
      </div>
      <div style="display:flex;flex-direction:row;justify-content: center;">
      <button class="btn btn-primary" (click)="showNotExceededOtpAttempt()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
      padding: 10px 20px;height: 45px;">
        OK
      </button>
    </div>
    </div>
    </div>

    <div class="popup-greybackground" *ngIf="registrationComplete" ></div>
    <div class= "popup-container" *ngIf="registrationComplete">
      <div class="popup" >
        <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-check" style="font-size: 48px;
          color: #00AD68;
          padding: 10px;
          border: 3px solid #00AD68;
          border-radius: 40px;"></i></div>
        <div class="popup-text" style="font-size: 32px;">
          Registration complete!
        </div>
        <div style="display:flex;flex-direction:row;justify-content: center;">
        <button class="btn btn-primary" (click)="gotoDashboard()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
        padding: 10px 20px;height: 45px;">
          Start using PreCheck MyScript
        </button>
      </div>
      </div>
      </div>
  
      <section id="footer" class="d-print-none">
        <nav class="navbar navbar-expand-lg bg-light">
            <div class="container-fluid nav-footer text-center">
              <div class="row">
                  <div class="col">
                      <ul>
                      <li  aria-label="2024 Optum, Inc. All rights reserved">&#169; 2024 Optum, Inc. All rights reserved</li>
                      &nbsp;|&nbsp;
                      <li><a class="btn invisiblebtn btn-link " aria-label="Link to Privacy Policy" (click)="goToPrivacyLink()">Privacy Policy</a></li>
                      &nbsp;|&nbsp;
                      <li><a  class="btn invisiblebtn btn-link " aria-label="Link to Terms of Use" (click)="goToTermsLink()">Terms of Use</a></li>
                      &nbsp;|&nbsp;
                      <li><a class="btn invisiblebtn btn-link " aria-label="Link to Contact Us" (click)="goToContactusLink()">Contact Us</a></li>
                      </ul>
                  </div>
              </div>
            </div>
          </nav>
      </section>