<div class="container-fluid addressbook">
    <div class="row addressbook_headerrow back1">
        <div class="addressbook_headerimg col-xs-12 col-md-12">
            <div class="container-fluid manage_prescribers_container fadeout-widget" style="display: flex;">
                <div class="col-xs-6 col-md-6 col-lg-7 addressbook_headertext ng-scope">
                    <!-- <div class="container-fluid servererror" *ngIf="memberResultType==2 || memberResultType==3">
                        <div class="row">
                            <div class="col nopadding">
                                <div class="alert alert-danger shadow" role="alert">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <span>We're unable to complete your request at this time. Please try again later.</span>
                                    <i class="fa fa-remove" aria-label="Close"></i>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    
                    <!-- <div class="container-fluid timeout" *ngIf="this.timeOutValue">
                        <div class="row alert alert-danger shadow" role="alert">
                            <div class="col-10 nopadding">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                <span>We were unable to retrieve this information. Refresh the page to try again.</span>
                                <i class="col-2 mt-2 fa fa-remove" aria-label="Close"></i>
                            </div>
                        </div>
                    </div> -->
                    <div class="heading_mega ng-binding" *ngIf="this.noMatchingrecordsvalue=='false' && this.timeOutValue=='false'">Add a Pharmacy</div>
                    <div class="container-fluid noresultsinfo shadow mb-3" style="width:165%;"  *ngIf="this.noMatchingrecordsvalue=='true'">
                        <div class="row">
                            <div class="col nopadding">
                                <div class="alert alert-primary shadow" role="alert">
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <span>Info: No Search Results found</span> 
                                    <i class="fa fa-remove" aria-label="Close"  (click)="dismiss()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid timeout shadow mb-3" style="width:170%;" *ngIf="this.timeOutValue=='true'">
                        <div class="row alert alert-danger shadow" role="alert">
                            <div class="col nopadding">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                                <span>We were unable to retrieve this information. Refresh the page to try again.</span>
                                <i class="fa fa-remove" aria-label="Close"  (click)="dismiss()"></i>
                            </div>
                        </div>
                    </div>
                    <p style="max-width: 550px;">Search for a pharmacy using the Search tool below. Selected pharmacy  will appear in the "Ready to Verify" section on the right.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row addressbook_contentrow" style="margin-bottom: 100px;">
        <div class="col-xs-12 col-md-12 ng-scope">
            <div
                class="container-fluid registrationcontainer manage_prescribers_container home_dashboard_pullup ng-scope">
                <div class="row p-4" style="margin-left:-70px;margin-right:-70px;">
                    <div class="col-lg-7 col-md-6 col-xs-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xs-12">
                                    <!-- <app-select-prescriber (selectNPI)="selectFax($event)" (errorHandling)="errorPopupHandling($event)" [addedPrescribersList]="addedPrescribersList" (prescriberSearchHistory)="prescriberSearchHistory($event)"></app-select-prescriber> -->
                                    <pharmacysearch (selectPharmacyNPI)="selectPharmacyFax($event)" (errorHandling)="errorPopupHandling($event)" [addedPharmacyList]="addedPharmacyList" (pharmacySearchHistory)="pharmacySearchHistory($event)"></pharmacysearch>
                                </div>
                            </div>
                            <div class="row p-3" style="margin-top:15px;"  *ngIf="this.noMatchingrecordsvalue=='true'">
                                <div class="col-xs-12 noresults">
                                    No search results found.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-4 hidden-sm hidden-xs nopadding" style="position: relative; top: -8px;">
                        <div class="fadeout-widget">
                            <div class="content-card-holder">
                                <div class="content-card bg-white no-shadow p-1">
                                    <div class="ng-scope header">
                                        <div class="ng-binding ng-scope">
                                            <h3>Ready to verify</h3>
                                        </div>
                                    </div>
                                    <div class="body submissionDetailsBody paddingbottomtwenty">
                                        <div class="cardsection ng-scope">
                                            <div class="row">
                                                <div class="col-md-12 ng-binding">
                                                    <p>Use the Search tool on the left to find Prescribers to add to
                                                        your Address Book.</p>
                                                </div>
                                                <div class="col-md-12" *ngIf="this.userId!=undefined"><br>
                                                    <div>
                                                        <label>user: {{this.userId}}</label>
                                                    </div>
                                                    <br>
                                                     <div *ngIf="this.selectedRole!=-1 && (this.selectedRole==11 || this.selectedRole==13)">
                                                        <label>Count: {{this.addedPharmacyList.length}} of 1</label>
                                                    </div>
                        
                                                </div>
                                                
                                                 <div class="col-md-12 ng-scope" *ngIf="this.addedPharmacyList.length>0">
                                                    <div class="container-fluid nopadding" *ngFor="let item of addedPharmacyList;">
                                                        <div class="row addressbook_virtualcartitem ng-scope p-1">
                                                            <div class="col-xs-7 col-sm-8 ng-binding">
                                                                <span class="paragraph fontbold ng-binding">
                                                                     {{this.pharmacyName}}
                                                                </span><br>
                                                                <span class="paragraph ng-binding ng-scope">NPI:{{this.selectedPharmacyNPI}}</span> 
                                                             </div>
                                                            <div class="col-xs-5 col-sm-4 p-0 pb-3">
                                                                <span class="removeItem paragraph pull-right ng-binding" (click)="removeAddedPharmacy(item)">Remove</span>
                                                            </div>
                                                        </div>                          
                                                    </div>
                                                </div> 
                                                <div class="col-md-12 ng-scope"  *ngIf="this.addedPharmacyList.length>0">
                                                    <!-- <button type="button" class="btn btn-primary ng-binding p-2" style="width:100%" (click)="addPrescribers()" *ngIf="this.userId==undefined">Add prescribers</button> -->
                                                    <button type="button" class="btn btn-primary ng-binding p-2" style="width:100%" (click)="goto('/provision/addusers')" *ngIf="this.userId!=undefined">Add pharmacy</button>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>


<!-- <ng-template #addpresbrSuccess let-modal>
    <div class="content-card no-shadow progress-notification" style="position:relative;width:600px;">
        <div class="row text-center" style="margin:0px">
        <div class="col-xs-12"><i class="fa fa-check-circle" style="color:rgb(0,173,104);font-size:70px;" aria-hidden="true"></i></div>
        <div class="col-xs-12 text" style="padding-top:10px;" x-ms-format-detection="none">
        <h2 class="text-center nopadding ng-binding" style="margin:0px">The verification PIN(s) have been sent.</h2>
        <span class="text-center paragraph ng-binding">Next steps to gain access to the prescribers are:</span>
        </div>
        </div>
        <div class="row" style="margin-top:20px;margin-bottom:20px">
        <div class="col-xs-4 col-md-2 nopadding">
        <img style="width:75px;height:75px;" src="assets/images/fax_img.png">
        </div>
        <div class="col-xs-8 col-md-10 text-left"><p style="margin-top:5px" class="paragraph_small ng-binding">A fax with a PIN will be delievered to the prescriber with the number you chose. You can check the status of the request on the Manage Prescribers page.</p></div>
        </div>
        <div class="row" style="margin-top:20px;margin-bottom:20px">
        <div class="col-xs-4 col-md-2 nopadding">
        <img style="width:75px;height:75px;" src="assets/images/verifyprescriber_img.png">
        </div>
        <div class="col-xs-8 col-md-9 text-left"><p style="margin-top:5px" class="paragraph_small ng-binding" ng-bind-html="translation.addressbook.addprescribers.rsp_success_row2desc | verifyprecriberurl:true">The prescriber then must navigate to <a href="https://precheckmyscript-stage.uhcprovider.com/verifyProvider" target="_black">https://precheckmyscript-stage.uhcprovider.com/verifyProvider</a> and enter in their NPI and PIN from the fax.</p></div>
        </div>
        <div class="row" style="margin-top:20px;margin-bottom:20px">
        <div class="col-xs-4 col-md-2 nopadding">
        <img style="width:75px;height:75px;" src="assets/images/myprescribers_img.png">
        </div>
        <div class="col-xs-8 col-md-9 text-left"><p style="margin-top:5px" class="paragraph_small ng-binding">Once the prescriber approves your request, you will see them added to your My Prescribers section on the Manager Prescribers Page.</p></div>
        </div>
        <div class="row text-center">
        <div class="col-xs-12 text-center">
        <button type="button" style="width:auto;margin-top:10px;" class="btn btn-primary ng-binding p-2" (click)="goto('/prescribers/manage')">Continue to Manage Prescribers</button>
        </div>
        </div>
        </div>
</ng-template> -->

 <ng-template #selectFaxModal let-modal>
    <div class="row">
        <div class="col-xs-12">
            <div class="content-card-holder">
                <div class="content-cards bg-white no-shadow">
                     <div class="card-footer" style="padding: 36px;border-bottom: 2px solid #d8d8d8;padding-left:25px;">
                        <div class="cardsection ng-scope">
                            <!-- <h6 style="margin-bottom: 0px;"></h6>
                            <span class="paragraph ng-binding ng-scope float-start"> -->
                             <h6 style="margin-bottom: 0px;font-size: 18px;color: #050709;">{{this.pharmacyName | stringtosentencecase }} </h6> 
                            <p class="float-start">{{this.pharmacyAddress | stringtosentencecase }}</p>
                        </div>
                    </div>
                    <div class="body submissionDetailsBody paddingbottomtwenty">
                        <div class="cardsection ng-scope">
                            <div class="container-fluid nopadding verifyfaxform ng-scope">
                                <div class="row p-2">
                                    <div class="col-sm-10" *ngIf="this.blnSelectFax==true">
                                        <span class="paragraph ng-binding">Select the fax number associated with your office.</span>
                                    </div>
                                    <div class="col-sm-12" *ngIf="this.blnSelectFax==false">
                                      <span class="paragraph ng-binding">{{this.errorMessage}}</span>
                                   </div>
                                </div> 
                                 <!-- <div class="row">
                                    <div class="col-md-4 col-sm-6 nopadding ng-scope" *ngIf="this.blnSelectFax==true">
                                        <div class="btn-group" data-toggle="buttons" style="margin-bottom: 5px;" *ngFor="let item of pharmacyFaxDetails;index as i">
                                          <label class="btn" for="no_fax"> 
                                            <input type="radio" id="no_fax" name="faxnos">&nbsp;
  
                                              <span class="text ng-binding">{{item}}</span>
                                          </label>
      
                                        </div>
                                      </div>
                                </div> -->
                                <!-- <div class="row">
                                      <div class="col-sm-10 nopadding ng-scope">
                                        <label for="no_fax" *ngIf="this.blnSelectFax==true" style="margin-right: 6%;"> 
                                          <input type="radio" id="no_fax" name="faxnos">&nbsp;
  
                                            <span class="paragraph ng-binding">None of the fax numbers listed above are correct.</span>
                                        </label>
                                      </div> 
                                </div>  -->
  
                                <div class="form-check"
                                    style="display: flex;flex-direction: row;width:100%;padding-top: 5px;padding-left: 5px;flex-wrap: wrap;" *ngIf="this.blnSelectFax==true">
                                    <div
                                      style="font-size: 18px;color: #050709;width: 33%;padding-left:0px;margin-top: 15px;padding-bottom: 15px;"
                                      *ngFor="let fax of pharmacyFaxDetails" >
                                      <label (click)="filterStatus=fax" class="radioInput" style="cursor: pointer;">
                                        <input type="radio" id="{{fax}}" value="{{fax}}">
                                        <i class="fa fa-circle-o" *ngIf="filterStatus!==fax"></i>
                                        <i class="fa fa-dot-circle-o" *ngIf="filterStatus===fax"></i>
                                        <label for="{{fax}}" style="padding-left: 10px;cursor: pointer;">{{fax}}</label>
                                      </label>
                                    </div>
                                  </div>          
              
                                  <div class="form-check"
                                    style="padding-top: 15px;padding-bottom: 15px;padding-left: 0px; font-size: 18px; color: #050709;" *ngIf="this.blnSelectFax==true" (click)="modalPopup()">
                                    <label (click)="filterStatus='none'" class="radioInput">
                                      <input type="radio" value=''>
                                      <i class="fa fa-circle-o" *ngIf="filterStatus!=='none'"></i>
                                      <i class="fa fa-dot-circle-o" *ngIf="filterStatus==='none'"></i>
                                      <label style="padding-left: 10px;">None of the fax numbers listed above are correct.</label>
                                    </label>            
                                  </div>
  
  
                                <div class="row mt-4">
                                  <div class="col" style="padding-bottom: 15px">
                                      <button class="btn btn-primary float-end fontbold" *ngIf="this.blnSelectFax==false" (click)="dismiss()">Close</button>
                                      <button  class="btn btn-primary float-end fontbold" *ngIf="this.blnSelectFax==true" (click)="receiveNPI(this.pharmacyDetailList[0])">Continue</button>
                                      <a class="btn btn-link float-start ps-2" *ngIf="this.blnSelectFax==true" (click)="dismiss()">Cancel</a>
                                    </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </ng-template>  
  
  <ng-template #PharmacySelectError let-modal>
    <div class="modal-body text-center p-4" aria-labelledby="PresSelectError">
        <i class="fa fa-exclamation-circle m-1" ></i>
        <p class="large" [innerHtml]="errorMessage">
        </p>
        <button class="btn btn-secondary mt-2" (click)="modal.dismiss()">Ok</button>
    </div>
  </ng-template>
  
  <ng-template #maxPharmacylimitexceed let-modal>
    <div class="p-4">
      <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="text-center mt-2 p-2">
       You have reached the maximum number of prescribers <br>
       that can be added.Please remove a prescriber in order <br>
       to add a new one.</div>
      <div class="text-center p-2"><button
          class="btn btn-secondary"
          (click)="modal.dismiss()">OK</button></div>
    </div>
  </ng-template>

 <ng-template #noFaxSelected let-modal>
    <div class="p-4">
      <div class="row text-center"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
      <div class="text-center mt-2">
        We're sorry none of the fax number listed are correct. <br>
        You'll need to contact at 1-866-842-3278 to finish <br>
        registration.</div>
      <div class="row border-0 mt-4 p-3 "><button
          class="col-2 position-absolute top-80 start-50 translate-middle btn btn-secondary"
          (click)="modal.dismiss()">OK</button></div>
    </div>
  </ng-template>