<div>
  <div class="back1">
    <div class="container-fluid back2 ">
      <div class="container-fluid c1">
        <div class="row" style="margin: 0px;">
          <div class="col-2" style="padding-right: 0px;width: auto;">
            <img class="header_pcms_logo responsive font_color" src="assets/images/u407.jpeg" alt="HTML5 Icon">
          </div>
          <div class="col" style="padding: 0px;margin-top: 15px;margin-bottom: 10px">
            <div class="page_header header_font">
              PreCheck MyScript Registration
            </div>
          </div>
        </div>
      </div>
      <div class="container c4" style="padding-left:5px;padding-right: 5px;">
        <div class="tab-content justify-content-center">
          <div class="tab-pane fade show active" role="tabpanel">
            <div style="
                padding-top: 49px;
                padding-bottom: 5px;
                font-size: 18px;
                color: #fff;
                padding-left: 3px;">
              Step 2 of 3: Verify your fax number
            </div>
            <div class="card card_size" style="background-color: #fff!important;
                border-radius: 0 0 2px 2px;
                box-shadow: 0 4px 6px rgba(0,0,0,.2);">
              <div *ngIf="errorPharmacyScenarioMessage==false && errorPrescriberScenarioMessage ==false">
                <div class="card-header" style="height: 105px;padding: 25px;background-color: rgba(242, 246, 249, 1);">
                  <div *ngIf="userRoleFlag=='pharmacy'">
                    <div style=" padding-top: 6px;color: #424249;font-size: 18px;">
                      {{pharmacyName}}
                    </div>
                    <div
                      style="font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-style: normal;font-size: 16px;color: #424249;line-height: 16px;">
                      <span style="text-transform: capitalize;">{{address1}}</span>,&nbsp;<span
                        style="text-transform: capitalize;">{{city}}</span>,&nbsp;{{state}}&nbsp;{{zipCode}}
                    </div>
                  </div>
                  <div *ngIf="userRoleFlag=='prescriber'">
                    <div style=" padding-top: 6px;color: #424249;font-size: 18px;">
                      <span style="text-transform: capitalize;">{{firstname}}</span>&nbsp;<span
                        *ngIf="middleInitial!=''">{{middleInitial}}.&nbsp;</span><span
                        style="text-transform: capitalize;">{{lastname}}</span>,&nbsp;{{degree}}&nbsp;-&nbsp;NPI:&nbsp;{{NPINumber}}
                    </div>
                    <div
                      style="font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;font-style: normal;font-size: 16px;color: #424249;line-height: 16px;">
                      <span style="text-transform: capitalize;">{{address1}}</span>,&nbsp;<span
                        style="text-transform: capitalize;">{{address2}}</span>,&nbsp;{{state}},&nbsp;{{zipCode}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" style="margin-bottom: 12px;padding: 25px;margin:0px;">
                <div *ngIf="errorPrescriberScenarioMessage">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="paragraph">An error occured and the prescriber information could not be found. Try
                        refreshing the page</span>
                      <br>
                      <span class="paragraph">or contact us for assistance at </span><span
                        class="fax_style">1-866-842-3278.</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="errorPharmacyScenarioMessage">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="paragraph">An error occured and the pharmacy information could not be found. Try
                        refreshing the page</span>
                      <br>
                      <span class="paragraph">or contact us for assistance at </span><span
                        class="fax_style">1-866-842-3278.</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="noPrescriberFaxErrorCode">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="paragraph">We cannot find a fax associated with your prescriber.</span>
                      <br>
                      <span class="paragraph">Contact us for assistance at </span><span
                        class="fax_style">1-866-842-3278.</span>
                    </div>
                  </div>
                </div>
                <div *ngIf="noPharmacyFaxErrorCode">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="paragraph">We cannot find a fax associated with your pharmacy.</span>
                      <br>
                      <span class="paragraph">Contact us for assistance at </span><span
                        class="fax_style">1-866-842-3278.</span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="noPharmacyFaxErrorCode==false && noPrescriberFaxErrorCode==false  && errorPharmacyScenarioMessage==false && errorPrescriberScenarioMessage ==false">
                  <div class="card-text text-muted" style="color: #626f7d;font-weight: 500;font-size:16px;">Select the
                    fax number associated with your office.</div>

                  <form [formGroup]="selectedFax">
                    <!-- <div class="form-check" style="display: flex;flex-direction: row;width:66%;padding-top: 15px ;padding-left: 13px;">
                        <div style="font-size: 18px;color: #050709;width: 33%;padding:0 15px;margin-top: 15px;" *ngFor="let fax of faxes">
                          <input  formControlName="faxValue" class="form-check-input" type="radio" name="faxNumber" id="selectFax" value="{{fax}}" >
                          <label class="form-check-label" for="selectFax">
                            {{fax}}
                          </label>
                        </div>
                      </div> -->
                    <div class="form-check"
                      style="display: flex;flex-direction: row;width:70%;padding-top: 5px;padding-left: 0px;flex-wrap: wrap;">
                      <div
                        style="font-size: 18px;color: #050709;width: 33%;padding-left:0px;margin-top: 15px;padding-bottom: 15px;"
                        *ngFor="let fax of faxes">
                        <label (click)="filterStatus=fax" class="radioInput" style="cursor: pointer;">
                          <input formControlName="faxValue" type="radio" id="{{fax}}" value="{{fax}}">
                          <i class="fa fa-circle-o" *ngIf="filterStatus!==fax"></i>
                          <i class="fa fa-dot-circle-o" *ngIf="filterStatus===fax"></i>
                          <label for="{{fax}}" style="padding-left: 10px;cursor: pointer;">{{fax}}</label>
                        </label>
                      </div>
                    </div>


                    <div class="form-check"
                      style="padding-top: 15px;padding-left: 0px; font-size: 18px; color: #050709;">
                      <!--<input  class="form-check-input" type="radio" name="faxNumber" id="selectedFax" >
                         <label class="form-check-label" for="selectedFax">
                          None of the fax numbers listed above are correct.
                        </label> -->
                      <label (click)="setNoneErrorPopup()" class="radioInput" style="cursor: pointer;">
                        <input formControlName="faxValue" type="radio" value=''>
                        <i class="fa fa-circle-o" *ngIf="filterStatus!=='none'"></i>
                        <i class="fa fa-dot-circle-o" *ngIf="filterStatus==='none'"></i>
                        <label style="padding-left: 10px;cursor: pointer;">None of the fax numbers listed above are correct.</label>
                      </label>

                    </div>
                  </form>
                </div>

              </div>

            </div>
            <!-- <div class="container c5" >
                    <div class=" card-link cancel" style="cursor: pointer;font-size: 16px;" href="#" (click)="openPopup()" >Cancel registration</div>
                    <div class=" card-link clear" style="cursor: pointer;font-weight: 400;font-size: 16px;" href="#" (click)="onBackClick()">Back</div>
                    <button class=" btn btn-primary search_button" style="cursor: pointer;" type="submit" (click)="onNextClick()" form="Prescriber_NPI_Form">Next</button>
                  </div> -->
            <div class="card-footer" style="border-top: #fff; background-color: None;padding: 0px;">
              <div class="container c5">
                <div class="cancel">
                  <span class="underline font_bold_no_color" id='underline1' (mouseover)="underline('underline1')"
                    (mouseout)="removeUnderline('underline1')" (click)="openPopup()" style="cursor: pointer;">Cancel
                    registration</span>
                </div>
                <div class=" card-link clear underline font_bold_no_color" href="javascript:void(0)" id='underline2'
                  (mouseover)="underline('underline2')" (mouseout)="removeUnderline('underline2')"
                  (click)="onBackClick()" style="cursor: pointer;">Back</div>
                <button class=" btn btn-primary search_button font_bold_no_color" type="submit" form="selectedFax"
                  style="cursor: pointer;" [disabled]="!isFormValid()" (click)="onNextClick()">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" style="position: absolute;top:0; padding: 0;margin: 0;left: 0; right: 0;">
        <div class="row" style="height: 6px;position: fixed;
          width: 101%;
          z-index: 1002 ">
          <div class="col-8" style="background-color: rgba(251,176,60,1);"></div>
          <div class="col-4" style="background-color: rgba(254,239,216,1)"></div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content: center;position: relative;top: 8px;"
          *ngIf="showErrorPopup==true">
          <div style="display:flex;color: #c60f13;;padding-top : 25px;padding-right: 40px;padding-bottom: 25px; padding-left: 20px;border-radius:4px;background-color: #fff4f4;
          width: 76%;flex-direction:row;">

            <i class="fa fa-ban" style="font-size: 25px;padding-right: 10px;"></i>
            <span style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
            font-size: 16px;flex-grow: 1;">We're unable to complete your request at this time. Please try again
              later.</span>
            <div class="fa fa-remove" style="font-size: 16px;cursor:pointer" (click)="closeErrorPopup()"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="footer " class="container-fluid" style=" padding-top: 30px;position: absolute;bottom: 0%;"  >
        <div class="row " style=" background-color: #424249;">
          <div class="col-md-12 text-center" style="padding-right: 15px;
          padding-left: 15px;padding-top: 15px;">
            <p style="color: white">© 2023 Optum, Inc. All rights reserved
            <span> | </span>
            <span href="">Privacy Policy</span>
            <span> | </span>
            <span href="">Terms of Use</span>
            <span> | </span>
            <span href="">Terms of Use</span>
          </p>
          </div>
        </div>
    </div> -->
  </div>
</div>

<div class="popup-greybackground" *ngIf="showPopup"></div>
<div class="popup-container" *ngIf="showPopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle"
        aria-hidden="true"></i></div>
    <div class="popup-text">
      Are you sure you want to cancel this registration request?
      <br />
      You will not be able to access it after this point.
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
      <button class="btn" (click)="onNoClick()" style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    margin: 0 10px;
    padding: 10px 20px;
    width: 75px;
    height: 45px;
    ">
        No
      </button>
      <button class="btn btn-primary" (click)="onYesClick()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 75px;height: 45px;">
        Yes
      </button>
    </div>
  </div>
</div>

<div class="popup-greybackground" *ngIf="openNoneErrorPopup"></div>
<div class="popup-container" *ngIf="openNoneErrorPopup">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;padding-top: 10px;"><i
        class="fa fa-exclamation-circle" aria-hidden="true"></i></div>
    <div style="font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 15px;">
      We're sorry none of the fax numbers listed are correct.
      <br />
      You'll need to contact us at 1-866-842-3278 to finish
      <br />
      registration.
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
      <button class="btn btn-primary" (click)="removeNoneErrorPopup()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 14%;height: 44px;">
        Ok
      </button>
    </div>
  </div>
</div>

<section id="footer" class="d-print-none">
  <nav class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid nav-footer text-center">
        <div class="row">
            <div class="col">
                <ul>
                <li  aria-label="2024 Optum, Inc. All rights reserved">&#169; 2024 Optum, Inc. All rights reserved</li>
                &nbsp;|&nbsp;
                <li><a class="btn invisiblebtn btn-link " aria-label="Link to Privacy Policy" (click)="goToPrivacyLink()">Privacy Policy</a></li>
                &nbsp;|&nbsp;
                <li><a  class="btn invisiblebtn btn-link " aria-label="Link to Terms of Use" (click)="goToTermsLink()">Terms of Use</a></li>
                &nbsp;|&nbsp;
                <li><a class="btn invisiblebtn btn-link " aria-label="Link to Contact Us" (click)="goToContactusLink()">Contact Us</a></li>
                </ul>
            </div>
        </div>
      </div>
    </nav>
</section>