<div class="col-10 offset-1" style="background-color: rgba(242, 242, 242, 1);">
    <div class="col">

        <!-- Progress bar  -->
        <!-- <app-progress-tabs></app-progress-tabs> -->

        <!-- Search medication Heading and form  -->
        <div class=" container-fluid shadow" style="padding: 20px; background-color: white;">
            <div class="row">

                <div class="col-8" style="padding-top: 20px;">
                    <h5 class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset"
                        *ngIf="!this.blnInitiatePA">
                        STEP 2 OF 3: MEDICATION REQUEST INFORMATION
                    </h5>
                    <h5 class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset"
                        *ngIf="this.blnInitiatePA">
                        STEP 2 OF 4: MEDICATION REQUEST INFORMATION
                    </h5>
                </div>

                <!-- Saved and Recent Searches Buttons  -->
                <div class="col float-end" style="color: #0470c5; padding-top: 20px;">
                    <a class="btn invisiblebtn btn-link fontbold" (click)="showRecentSeraches()">Recent
                        Searches</a>
                    &nbsp;
                    |
                    &nbsp;
                    <a class="btn invisiblebtn btn-link fontbold" (click)="showSavedSeraches()">Saved Searches</a>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!blnDrugSearchFormSubmitted" style="height: 50px;">
            <div class="col">
                <div class="alert alert-danger">Please enter all required fields.</div>
            </div>
        </div>
        <form name="addMedicationForm" [formGroup]="addMedicationForm">
            <div class=" container-fluid shadow content-card mt-1" style="padding: 20px; background-color: white;">
                <div class="row" *ngIf="!this.blnInitiatePA">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <div class="mb-3">
                            <div class="form-group">
                                <label for="text" class="form-label float-left">Medication Name or NDC </label>
                                <div class="search" placement="bottom" triggers="hover:blur" aria-hidden="true">
                                    <i *ngIf="searchByNDC.length>0" class="fa fa-check"></i>
                                    <i *ngIf="searching" class="spinner-border spinner-border-sm"></i>
                                    <input (focus)="blnNDCFocus=true" (blur)="blnNDCFocus=false"
                                        *ngIf="!this.blnInitiatePA"
                                        [ngClass]="{'inpError':  (invalidNDC || !blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].valid) || 
                                        (blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].pristine && addMedicationForm.controls['drugSearchModel'].errors && 
                                        (addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['pattern'] || (!blnNDCFocus && addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']) )),'validInput':(searchByNDC.length>0)}"
                                        id="ndcname" type="text" class=" rounded-0 form-control inp"
                                        [ngbTypeahead]="searchNDC" #val (selectItem)="itemSelected($event)"
                                        (blur)="itemSelected($event)" formControlName="drugSearchModel" minlength="3"
                                        [editable]="true" required>
                                </div>
                                <!-- <div class="text-danger no-data shadow p-2"
                                    *ngIf="invalidNDC && drugSearchModel && drugSearchModel.errors && !drugSearchModel.errors?.['minlength'] && drugSearchModel.value==null && drugSearchModel">
                                        <br>
                                        <h3>Medication not found.</h3><span class="paragraph">
                                            No available NDC found matching the value entered.
                                             Please check the spelling.
                                        Medication
                                        may not be eligible for billing through Specialty Medication Access
                                        Portal. -->
                                <!-- </span><br>
                                </div>  -->
                                <!-- <div *ngIf="(!addMedicationForm.controls['drugSearchModel'].pristine)|| (addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors?.['minlength'] && !blnNDCFocus)">  -->
                                <!-- <div class="text-danger"
                                        *ngIf="addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors?.['minlength']">
                                        <small>Please enter min 3 characters.</small>
                                    </div> -->
                                <div *ngIf="drugSearchModel && drugSearchModel.invalid && drugSearchModel.dirty">
                                    <div class="text-danger"
                                        *ngIf="addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                        <small>Please enter min 3 characters.</small>
                                    </div>
                                    <div class="text-danger no-data shadow p-2"
                                        *ngIf="invalidNDC && addMedicationForm.controls['drugSearchModel'].errors && !addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                        <br>
                                        <h3 class="fontbold">Medication not found.</h3>
                                        <br>
                                        <span class="paragraph">
                                            Please check the spelling.
                                        </span><br>
                                    </div>

                                    <!-- <div class="text-danger" *ngIf="drugSearchModel.errors && !drugSearchModel.errors?.['minlength']"><small>No available NDC found matching the value entered.</small></div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-6 col-xs-11" *ngIf="!this.blnInitiatePA">
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="form-label">Medication Dose </label>&nbsp;
                            <div *ngIf="formLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true">
                            </div>
                            <select *ngIf="searchByNDC.length==0 && dropdown.length!=1"
                                class=" custom-disabled rounded-0 inp form-select"
                                (change)="onChangeMediactionStrenth()" placement="bottom" triggers="hover:blur"
                                formControlName="MedStrength" aria-hidden="true">
                                <option value="">Select Dose</option>
                                <option *ngFor="let item of dropdown| drugnamesort; let i = index"
                                    [value]="dropdown[i].productId">
                                    {{item.detailedDrugName}}
                                </option>
                            </select>
                            <input *ngIf="searchByNDC.length>0 || dropdown.length==1" aria-hidden="true" type="text"
                                class="custom-disabled rounded-0 form-control inp"
                                value="{{dropdown[0].detailedDrugName}}" required disabled>
                        </div>
                    </div>
                    <div class="col-1" style="padding: 27px;"
                        *ngIf="checkFavourites(addMedicationForm.controls['MedStrength'].value)==-1 && !this.blnInitiatePA">
                        <button class="btn notfavorite" aria-label="Make your default medication"
                            [attr.enableAction]="addMedicationForm.controls['drugSearchModel'].valid"
                            [ngStyle]="{'cursor':(!addMedicationForm.controls['drugSearchModel'].valid)?'not-allowed':'pointer'}"
                            (click)="savefavourite(this.currentSelectedDrug[0].detailedDrugName,addMedicationForm.controls['MedStrength'].value)"></button>
                    </div>
                    <div class="col-1" style="padding: 27px;"
                        *ngIf="checkFavourites(addMedicationForm.controls['MedStrength'].value)!=-1 && !this.blnInitiatePA">
                        <button class="btn favorite" aria-label="Make your default medication"
                            (click)="removefavourite(addMedicationForm.controls['MedStrength'].value)"></button>
                    </div>
                </div>
                <div class="row" *ngIf="this.blnInitiatePA">
                    <div class="col-8">
                        <div class="mb-3">
                            <div class="form-group">
                                <label for="text" class="form-label float-left">Medication Name or NDC </label>
                                <div class="search" placement="bottom" triggers="hover:blur" aria-hidden="true">
                                    <i *ngIf="searchByNDC.length>0" class="fa fa-check"></i>
                                    <i *ngIf="searching" class="spinner-border spinner-border-sm"></i>
                                    <input (focus)="blnNDCFocus=true" (blur)="blnNDCFocus=false"
                                        [ngClass]="{'inpError':  (invalidNDC || !blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].valid) || 
                                    (blnDrugSearchFormSubmitted && addMedicationForm.controls['drugSearchModel'] && !addMedicationForm.controls['drugSearchModel'].pristine && addMedicationForm.controls['drugSearchModel'].errors && 
                                    (addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['pattern'] || (!blnNDCFocus && addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']) )),'validInput':(searchByNDC.length>0)}"
                                        id="ndcname" type="text" class=" rounded-0 form-control inp"
                                        [ngbTypeahead]="searchNDC" #val (selectItem)="itemSelected($event)"
                                        (blur)="itemSelected($event)" formControlName="drugSearchModel" minlength="3"
                                        [editable]="true" required>
                                </div>
                                <!-- <div class="text-danger no-data shadow p-2"
                                *ngIf="invalidNDC && drugSearchModel && drugSearchModel.errors && !drugSearchModel.errors?.['minlength'] && drugSearchModel.value==null && drugSearchModel">
                                    <br>
                                    <h3>Medication not found.</h3><span class="paragraph">
                                        No available NDC found matching the value entered.
                                         Please check the spelling.
                                    Medication
                                    may not be eligible for billing through Specialty Medication Access
                                    Portal. -->
                                <!-- </span><br>
                            </div>  -->
                                <!-- <div *ngIf="(!addMedicationForm.controls['drugSearchModel'].pristine)|| (addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors?.['minlength'] && !blnNDCFocus)">  -->
                                <!-- <div class="text-danger"
                                    *ngIf="addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors?.['minlength']">
                                    <small>Please enter min 3 characters.</small>
                                </div> -->
                                <div *ngIf="drugSearchModel && drugSearchModel.invalid && drugSearchModel.dirty">
                                    <div class="text-danger"
                                        *ngIf="addMedicationForm.controls['drugSearchModel'].errors && addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                        <small>Please enter min 3 characters.</small>
                                    </div>
                                    <div class="text-danger no-data shadow p-2"
                                        *ngIf="invalidNDC && addMedicationForm.controls['drugSearchModel'].errors && !addMedicationForm.controls['drugSearchModel'].errors['minlength']">
                                        <br>
                                        <h3 class="fontbold">Medication not found.</h3>
                                        <br>
                                        <span class="paragraph">
                                            Please check the spelling.
                                        </span><br>
                                    </div>

                                    <!-- <div class="text-danger" *ngIf="drugSearchModel.errors && !drugSearchModel.errors?.['minlength']"><small>No available NDC found matching the value entered.</small></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" style="padding: 27px;"
                        *ngIf="checkFavourites(addMedicationForm.controls['MedStrength'].value)==-1">
                        <button class="btn notfavorite" aria-label="Make your default medication"
                            [attr.enableAction]="addMedicationForm.controls['drugSearchModel'].valid"
                            [ngStyle]="{'cursor':(!addMedicationForm.controls['drugSearchModel'].valid)?'not-allowed':'pointer'}"
                            (click)="savefavourite(this.currentSelectedDrug[0].detailedDrugName,addMedicationForm.controls['MedStrength'].value)"></button>
                    </div>
                    <div class="col-4" style="padding: 27px;"
                        *ngIf="checkFavourites(addMedicationForm.controls['MedStrength'].value)!=-1">
                        <button class="btn favorite" aria-label="Make your default medication"
                            (click)="removefavourite(addMedicationForm.controls['MedStrength'].value)"></button>
                    </div>
                </div>
                <div class="row" *ngIf="this.blnInitiatePA || this.blnDrugEdit==true">
                    <div class="col-8">
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="form-label">Medication Dose </label>
                            <div *ngIf="formLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true">
                            </div>
                            <select *ngIf="searchByNDC.length==0 && dropdown.length!=1"
                                class="custom-disabled rounded-0 inp form-select" (change)="onChangeMediactionStrenth()"
                                placement="bottom" triggers="hover:blur" formControlName="MedStrength"
                                aria-hidden="true">
                                <option value="">Select Strength</option>
                                <option *ngFor="let item of dropdown| drugnamesort; let i = index"
                                    [value]="dropdown[i].productId">
                                    {{item.detailedDrugName}}
                                </option>
                            </select>
                            <input *ngIf="searchByNDC.length>0 || dropdown.length==1" aria-hidden="true" type="text"
                                class="custom-disabled rounded-0 form-control inp"
                                value="{{dropdown[0].detailedDrugName}}" required disabled>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="this.blnInitiatePA">
                        <form [formGroup]="addPharmacyForm">
                            <div class="col" style="display:inline-block;vertical-align: middle;white-space:nowrap;">
                                <div
                                    style="display:inline-block;vertical-align: top;max-width:130px;white-space:normal;margin-left:10px;">
                                    <label class="inputFieldLabel" for="Quantity"
                                        style="margin-bottom: 5px;">Quantity</label>
                                    <input autocomplete="off" minlength="1" (focus)="blnQuantityFocus=true"
                                        (blur)="blnQuantityFocus=false"
                                        [ngClass]="{'inpError': (!blnDrugSearchFormSubmitted && !addPharmacyForm.controls['Quantity'].valid) || 
                            (!addPharmacyForm.controls['Quantity'].pristine && addPharmacyForm.controls['Quantity'].errors && 
                            (addPharmacyForm.controls['Quantity'].errors['pattern'] || (!blnQuantityFocus && addPharmacyForm.controls['Quantity'].errors['minlength'])))}"
                                        type="text" class="rounded-0 form-control inp" id="Quantity"
                                        aria-describedby="emailHelp" name="Quantity" formControlName="Quantity"
                                        maxlength="7" (keyup)="calculateQuantity()">

                                    <div class="text-danger text-center" style="position:absolute;">
                                        <ng-container
                                            *ngIf="addPharmacyForm.controls['Quantity'].errors && addPharmacyForm.controls['Quantity'].errors['pattern']">
                                            Invalid Character</ng-container>
                                    </div>
                                    <div class="text-danger text-center" style="position:absolute;">
                                        <ng-container
                                            *ngIf="!blnDrugSearchFormSubmitted && addPharmacyForm.controls['Quantity'].value==''">
                                            This is required</ng-container>
                                    </div>
                                    <div class="text-danger text-center" style="position:absolute;">
                                        <ng-container
                                            *ngIf="this.addPharmacyForm.controls['Quantity'].errors && addPharmacyForm.controls['Quantity'].errors['maxlength'] && !this.addPharmacyForm.controls['Quantity'].valid && !blnQuantityFocus">
                                            <span class="text-danger text-center"> Please check the</span><br><span
                                                class="text-danger text-center">quantity and try again</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col float-end"
                                style="display:inline-block;vertical-align: middle;white-space:nowrap;">
                                <div
                                    style="display:inline-block;margin:0px;vertical-align: top;max-width:130px;white-space:normal;">
                                    <label class="inputFieldLabel" for="Days"
                                        style="text-transform: none; margin-bottom: 5px; ">Days of Supply</label>
                                    <input autocomplete="off" minlength="1" (focus)="blnQuantityFocus=true"
                                        (blur)="blnQuantityFocus=false"
                                        [ngClass]="{'inpError': (!blnDrugSearchFormSubmitted && !addPharmacyForm.controls['Days'].valid) || 
                            (!addPharmacyForm.controls['Days'].pristine && addPharmacyForm.controls['Days'].errors && 
                            (addPharmacyForm.controls['Days'].errors['pattern'] || (!blnQuantityFocus && addPharmacyForm.controls['Days'].errors['minlength'])))}"
                                        type="text" class="rounded-0 form-control inp" id="Quantity"
                                        aria-describedby="emailHelp" name="Days" formControlName="Days" maxlength="3"
                                        minlength="1" (keyup)="calculateQuantity()" required>
                                    <div class="text-danger text-center" style="position:absolute;">
                                        <ng-container
                                            *ngIf="addPharmacyForm.controls['Days'].errors && addPharmacyForm.controls['Days'].errors['pattern']">
                                            Invalid Character</ng-container>
                                    </div>
                                    <div class="text-danger text-center" style="position:absolute;">
                                        <ng-container
                                            *ngIf="!blnDrugSearchFormSubmitted && addPharmacyForm.controls['Days'].value==''">
                                            This is required</ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class=" container-fluid" style="padding: 17px; background-color: white;"> -->
                <div class="row">
                    <div class="col-md-8 col-sm-6 col-xs-12">
                        <div class="checkbox" style="min-height: 25px;">
                            <input type="checkbox" class="form-check-input" id="daw" name="daw" (click)="updateDAW()"
                                style="width: 1.5em; height: 1.5em;border:2px solid gray;border-radius:0px">
                            <label class="inputFieldLabel" for="text" style="text-transform: none;">Dispense as written,
                                medically necessary</label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!this.blnInitiatePA">
                    <div class="col-xs-12">
                        <hr style="border-top: 2px solid #424249;">
                    </div>
                </div>
                <div class="row pt-2" *ngIf="!this.blnInitiatePA">
                    <!-- Diagnosis Code Field -->
                    <div class="col">
                        <label for="DaySupply" class="form-label float-start">ICD-10 Diagnosis Code for Medication
                            Prescribed (optional):</label>
                    </div>

                    <div class="col">
                        <a (click)="showDiagnosisCode()" class="form-label float-end underline">Diagnosis Code
                            Keyword
                            Search</a>
                    </div>

                    <div class="col-12">
                        <!-- <input type="text" class="form-control" id="DaySupply=" aria-describedby="emailHelp"> -->
                        <!-- <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="diagnosesModel" [ngbTypeahead]="diagnosisSearch"  placeholder="Wikipedia search" #val/> -->
                        <!-- <i *ngIf="loading" class="spinner-border spinner-border-sm"></i>
                    <input ng-minLength="3" id="typeahead-config" type="text" class=" rounded-0 form-control inp"
                        [ngbTypeahead]="diagnosisSearch" #val (selectItem)="diagnosisSelected($event)"
                        formControlName="DiagnosisCode">
                     -->
                        <div class="search" placement="bottom" aria-hidden="true">
                            <div *ngIf="loading" class="validating-text">validating&nbsp;&nbsp;</div>
                            <i *ngIf="loading" class="spinner-border spinner-border-sm"></i>
                            <input ng-minLength="3" id="typeahead-config" type="text"
                                class="custom-disabled rounded-0 form-control inp" [ngbTypeahead]="diagnosisSearch" #val
                                (selectItem)="diagnosisSelected($event)" (blur)="diagnosisSelected($event)"
                                [editable]="true"
                                [ngClass]="{'inpError':  (this.searchFailed)||(addMedicationForm.controls['DiagnosisCode'].errors && addMedicationForm.controls['DiagnosisCode'].errors['minlength'])}"
                                formControlName="DiagnosisCode">
                        </div>

                    </div>
                    <!-- <small *ngIf="searchingDiagnosisCode" class="form-text text-muted">searching...</small> -->
                    <div *ngIf="(DiagnosisCode && DiagnosisCode.invalid) || this.searchFailed">
                        <div class="text-danger" *ngIf="this.searchFailed">
                            <small>Diagnosis code must be entered in ICD-10 format.</small>
                        </div>
                        <div class="text-danger"
                            *ngIf="addMedicationForm.controls['DiagnosisCode'].errors && addMedicationForm.controls['DiagnosisCode'].errors['minlength']">
                            <small>Please enter min 3 char.</small>
                        </div>
                    </div>
                    <div><small>To potentially avoid a PA, enter the diagnosis code related to the medication
                            prescribed.</small></div>
                    <!-- <small class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</small> -->
                </div>
                <!-- </div> -->
            </div>
            <div class="footer pb-4 shadow" *ngIf="this.blnInitiatePA" style="background-color:rgba(242, 246, 249, 1);">
                <div class="container-fluid nopadding">
                    <div class="row">
                        <div class="col-md-9 col-xs-8 p-4" style="padding-top:10px;padding-bottom:10px;">
                            <a class="btn invisiblebtn btn-link fontbold" aria-label="Cancel PA Request"
                                (click)="cancelRequest()">
                                <span aria-hidden="true">Cancel request</span>
                            </a>
                        </div>
                        <div class="col-md-1 col-xs-1 pt-4" style="padding:10px 5px;"
                            *ngIf="blnMemberDetailPageSession=='false'">
                            <a class="btn invisiblebtn btn-link float-left fontbold"
                                aria-label="back to member search results" style="text-transform: none;">
                                <span aria-hidden="true" (click)="back1($event)">Back </span> </a>
                        </div>
                        <div class="col-md-1 col-xs-1 pt-4" style="padding:10px 5px;"
                            *ngIf="blnMemberDetailPageSession=='true'">
                            <a class="btn invisiblebtn btn-link float-left fontbold"
                                aria-label="back to member search results" style="text-transform: none;">
                                <span aria-hidden="true" (click)="goto('/searchMedicationClaims/MemberDetail')">Back
                                </span> </a>
                        </div>
                        <div class="col-md-2 col-xs-3 pt-4">
                            <button type="button" style="width:80%" aria-label="continue to verify provider"
                                class="btn btn-primary float-left fontbold" (click)="drugSubmit()">
                                <span aria-hidden="true">Next</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="col-10 container-fluid content-card mt-4 mb-1 offset-1" style="padding: 30px; background-color: white;"
    *ngIf="this.blnInitiatePA">
    <h4 class="header">PROGRESS SUMMARY
        <button class="btn btn-link float-end fontbold" *ngIf="blnShowHide" (click)="show()">Show &nbsp;
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
        <button class="btn btn-link float-end fontbold" *ngIf="!blnShowHide" (click)="hide()">Hide &nbsp;
            <i class="fa fa-minus" aria-hidden="true"></i>
        </button>
    </h4>
</div>

<!--Start Member Details -->
<div class="col-10 offset-1 mt-1" style="padding-bottom:50px" *ngIf="this.blnInitiatePA && !blnShowHide">
    <div class="p-4 bg-white">
        <h4 class="text-capitalize mt-3 mb-3">MEMBER INFORMATION
            <button class="btn btn-link float-end fontbold" (click)="changeMember()">Change member</button>
        </h4>
    </div>
    <div class="m-0 p-3 bg-white border-3 border-top">
        <div class="m-2 mt-3 p-3 bg-light border-3 border-top row">
            <div class="col-6">
                <div class="row">
                    <div class="col-6 row text-muted">
                        <label>Name</label>
                        <label class="text-dark">{{this.selectedMember.memberItems[0].firstName}}</label>
                        <label class="text-dark">{{this.selectedMember.memberItems[0].lastName}}</label>
                    </div>
                    <div class="col-6 row text-muted">
                        <label>Member ID</label>
                        <label class="text-dark">{{this.selectedMember.memberItems[0].memberId}}</label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Address</label>
                        <label class="text-dark">{{ this.selectedMember.memberItems[0].contact.address1}},&nbsp;{{
                            this.selectedMember.memberItems[0].contact.city}},&nbsp;{{
                            this.selectedMember.memberItems[0].contact.state}},&nbsp;{{this.selectedMember.memberItems[0].contact.country}},&nbsp;{{
                            this.selectedMember.memberItems[0].contact.zip}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-4 row text-muted">
                        <label>Date of Birth</label>
                        <label class="text-dark">{{this.newDate}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Phone</label>
                        <label class="text-dark">{{this.selectedMember.memberItems[0].contact.phoneNumber}}</label>
                    </div>
                    <div class="col-4 row text-muted">
                        <label>Gender</label>
                        <label class="text-dark">{{this.selectedMember.memberItems[0].demographics.gender}}</label>
                    </div>
                </div>
                <!-- <div class="row mt-3">
                    <div class="col-12 row text-muted">
                        <label>Bin/PCN/Group</label>
                        <label class="text-dark">{{claimsummaryDetailsrequest.BinPCNGroup}}</label>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</div>
<!--End Member Details  -->

<div class="col-10 container-fluid shadow content-card mt-1 offset-1" style="padding: 20px; background-color: white; "
    *ngIf="!this.blnInitiatePA">
    <div class="row">

        <div class="col-9" style="padding-top: 25px;padding-bottom: 25px;border-bottom:2px solid #ddd;width:100%;">
            <h4 class="header">MEMBER'S PHARMACY
            </h4>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-11">
            <span class="paragraph">Select your pharmacy preferences below. Pharmacy options are dependent upon
                member's benefit plan information.*</span>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-md-12">
            <h5 style="font-family: Frutiger-Bold, Helvetica, Arial, sans-serif;color: #424249;font-size: 18px;">
                Retail Pharmacy &nbsp;&nbsp;
                <button class="btn btn-link" role="button" (click)="addPharmacy()"
                    *ngIf="this.blnPharmacySelected==true && this.blnPharmacistChangeButton==false && this.blnShowErrorPharmacist==false">Change</button>
            </h5>
        </div>
    </div>
    <div class="row pt-1" *ngIf="this.blnPharmacySelected==false">
        <div class="col-md-12">
            <span>No saved pharmacy was found for <span
                    style="text-transform: capitalize;">{{this.memberDetails.firstName}}
                    {{this.memberDetails.lastName}} </span></span>
        </div>
    </div>
    <form [formGroup]="addPharmacyForm">
        <div class="row" style="margin: 0px;border-radius: 4px;padding: 55px 5px;border: 2px solid rgb(4, 112, 197);"
            *ngIf="this.blnPharmacySelected==true">
            <div class="col-md-12 col-sm-12 col-xs-12"
                style="color: #050709;font-size: 16px;font-family: 'Frutiger-Roman';font-style: normal;font-weight: normal;">
                <div style="display: inline-block;width: 100%;" *ngIf="this.blnShowErrorPharmacist==false">
                    <div style="display:inline-block;vertical-align: middle;white-space:normal;width:50%">
                        <div class="checkbox"
                            style="display:inline-block;margin:0px;vertical-align: middle;white-space:normal;padding-right:10px;">
                            <input type="checkbox" class="form-check-input" id="retailpharmacy"
                                [checked]="this.blnPharmacySelected==true"
                                style="width: 1.5em; height: 1.5em;border:2px solid gray;border-radius:0px">
                            <label for="retailpharmacy">
                            </label>
                        </div>
                        <div class="pharmacyDetails"
                            style="display:inline-block;padding-left:10px;vertical-align: middle;white-space:normal;">
                            <div class="paragraph" style="text-transform: capitalize;">
                                {{this.pharmacyDetails?.pharmacyName| stringtosentencecase}}
                            </div>
                            <div class="paragraph" style="text-transform: capitalize;">
                                {{this.pharmacySelectedDetails.address1 | stringtosentencecase}} </div>
                            <div class="paragraph" style="text-transform: capitalize;">
                                {{this.pharmacySelectedDetails.city |
                                stringtosentencecase}},&nbsp;{{this.pharmacySelectedDetails.state}}&nbsp;{{this.pharmacySelectedDetails.zip1}}
                            </div>
                            <!-- <div class="paragraph" style="text-transform: capitalize;"> {{this.pharmacySelectedDetails.zip1}}  </div> -->
                        </div>
                    </div>

                    <div style="display:inline-block;vertical-align: middle;white-space:nowrap;">
                        <div
                            style="display:inline-block;margin:0px;vertical-align: top;max-width:150px;white-space:normal;margin-right:10px;margin-left:10px;">
                            <label class="inputFieldLabel" for="Quantity" style="margin-bottom: 5px;">Quantity</label>
                            <input autocomplete="off" minlength="1" (focus)="blnQuantityFocus=true"
                                (blur)="blnQuantityFocus=false"
                                [ngClass]="{'inpError': (!blnDrugSearchFormSubmitted && !addPharmacyForm.controls['Quantity'].valid) || 
                        (!addPharmacyForm.controls['Quantity'].pristine && addPharmacyForm.controls['Quantity'].errors && 
                        (addPharmacyForm.controls['Quantity'].errors['pattern'] || (!blnQuantityFocus && addPharmacyForm.controls['Quantity'].errors['minlength'])))}"
                                type="text" class="rounded-0 form-control inp" id="Quantity"
                                aria-describedby="emailHelp" name="Quantity" formControlName="Quantity" maxlength="7"
                                (keyup)="calculateQuantity()">

                            <div class="text-danger text-center" style="position:absolute;">
                                <ng-container
                                    *ngIf="addPharmacyForm.controls['Quantity'].errors && addPharmacyForm.controls['Quantity'].errors['pattern']">
                                    Invalid Character</ng-container>
                            </div>
                            <div class="text-danger text-center" style="position:absolute;">
                                <ng-container
                                    *ngIf="!blnDrugSearchFormSubmitted && addPharmacyForm.controls['Quantity'].value==''">
                                    This is required</ng-container>
                            </div>
                            <div class="text-danger text-center" style="position:absolute;">
                                <ng-container
                                    *ngIf="this.addPharmacyForm.controls['Quantity'].errors && this.addPharmacyForm.controls['Quantity'].errors['maxlength'] && !blnQuantityFocus">
                                    <span class="text-danger text-center"> Please check the</span><br><span
                                        class="text-danger text-center">quantity and try again</span>
                                </ng-container>
                            </div>
                        </div>
                    </div>


                    <div style="display:inline-block;vertical-align: middle;white-space:nowrap;">
                        <div
                            style="display:inline-block;margin:0px;vertical-align: top;max-width:150px;white-space:normal;">
                            <label class="inputFieldLabel" for="Days"
                                style="text-transform: none; margin-bottom: 5px; ">Days of Supply</label>
                            <input autocomplete="off" minlength="1" (focus)="blnQuantityFocus=true"
                                (blur)="blnQuantityFocus=false"
                                [ngClass]="{'inpError': (!blnDrugSearchFormSubmitted && !addPharmacyForm.controls['Days'].valid) || 
                        (!addPharmacyForm.controls['Days'].pristine && addPharmacyForm.controls['Days'].errors && 
                        (addPharmacyForm.controls['Days'].errors['pattern'] || (!blnQuantityFocus && addPharmacyForm.controls['Days'].errors['minlength'])))}"
                                type="text" class="rounded-0 form-control inp" id="Quantity"
                                aria-describedby="emailHelp" name="Days" formControlName="Days" maxlength="3"
                                minlength="1" (keyup)="calculateQuantity()" required>
                            <div class="text-danger text-center" style="position:absolute;">
                                <ng-container
                                    *ngIf="addPharmacyForm.controls['Days'].errors && addPharmacyForm.controls['Days'].errors['pattern']">
                                    Invalid Character</ng-container>
                            </div>
                            <div class="text-danger text-center" style="position:absolute;">
                                <ng-container
                                    *ngIf="!blnDrugSearchFormSubmitted && addPharmacyForm.controls['Days'].value==''">
                                    This is required</ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display:inline-block;vertical-align: middle;white-space:normal;width:100%"
                    *ngIf="this.blnShowErrorPharmacist==true">
                    <!-- <div *ngIf="pharmacyLoader"><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> -->
                    <span>We were unable to retrieve this information.<button class="btn btn-link"
                            (click)="refreshPage()">Refresh page</button></span>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 offset-6" style="float:right;" @fade
                *ngIf="quantityCalculated!=''">
                <div class="form-label rounded" *ngIf="quantityCalculated!=''"
                    style="background-color: #dce9f3; color: #1565c0;font-size:14px;margin:15px 0px 0px 0px;height:auto;padding:2px;">
                    <strong>{{quantityCalculated}}</strong></div>
            </div>
        </div>
    </form>
    <div class="row pt-2" *ngIf="this.blnPharmacySelected==false">
        <div class="col-md-3 col-xs-4 pb-2" style="margin-top: 15px;">
            <button type="button" aria-haspopup="true" role="button" class=" btn btn-secondary"
                style="text-transform: none; font-family:Frutiger-Bold,Helvetica,Arial,sans-serif" tabindex="-1"
                (click)="addPharmacy()">
                Add pharmacy
            </button>
        </div>
        <div class="col-md-4 col-xs-5" style="margin-top:10px;padding-left:0px;" *ngIf="!contextualErrorCode">
            <div class="page_header">
                <div class="contextual" role="alert" aria-atomic="true">
                    <div class="alert_error arrow_left">
                        <span class="alertmsg"></span>
                        <span class="msg">Please add a pharmacy.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-sm-12 col-md-7"
            style="display:flex;padding-right:0px;font-size:12px;color:#626F7D;line-height:18px;">
            <div style="display: inline-block;">*</div>
            <div class="text">This benefit plan may allow the member to obtain prescriptions through other
                pharmacies. Check plan materials or call the number on the back of the member's card.</div>
        </div>
    </div>
</div>
<div class="row col-10 offset-2" *ngIf="!this.blnInitiatePA" style="margin-top: 15px; margin-bottom: 60px;">
    <div class="col-xs-9 col-sm-6 col-md-8 col-lg-7 col-lg-offset-1 text-left"></div>
    <div class="col-1 offset-1 me-5 pe-4" style="padding: 10px 5px; text-align: right;"
        *ngIf="blnMemberDetailPageSession=='false'">
        <button class="btn btn-link float-end fontbold" aria-label="back to member search results"
            style="text-transform: none;width:100%" (click)="back($event)">Back </button>
    </div>
    <div class="col-1 offset-1 me-5 pe-4" style="padding: 10px 5px; text-align: right;"
        *ngIf="blnMemberDetailPageSession=='true'">
        <button class="btn btn-link float-end fontbold" aria-label="back to member search results"
            style="text-transform: none;width:100%" (click)="goto('/searchMedicationClaims/MemberDetail')">Back
        </button>
    </div>
    <div class="col-1 ms-5">
        <button type="button" style="width:200%;height:44px;" aria-label="continue to verify provider"
            class="btn btn-primary float-end fontbold" (click)="drugSearchSubmit()">Continue</button> &nbsp;&nbsp;
    </div>
</div>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body">
        <h3 class="text-center">Loading...</h3>
    </div>
</ng-template>


<!-- Templates for Data Table -->
<ng-template #drugNameTemplate let-data="data">
    <p class="large">&nbsp;{{data.drugDescription}}</p>
</ng-template>

<ng-template #actionRemove let-data="data">
    <div class="row">
        <div class="col-8">
            <a class="large underline text-danger" enableAction="true">Remove</a>
        </div>
    </div>
</ng-template>

<ng-template #actionSelect let-data="data">
    <div class="row">
        <div class="col-4 p-2">
            <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;"></i>
        </div>
    </div>
</ng-template>

<!-- Recent Searches Modal -->
<ng-template #recentSearchesModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Medication Search History</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <datatable [headers]="recentSearchHeaders" [rows]="recentSearchHistory"
            (onHeaderClicked)="onRecentHeaderClick($event)" (onCellClicked)="onRecentCellClick($event)">
        </datatable>
    </div>
    <div class="modal-body" *ngIf="this.recentSearchHistory.length==0">
        <h6 class="p-3">{{this.message}}</h6>
    </div>

    <div class="container-fluid" *ngIf="totalPagesCount>1 && this.recentSearches.length>5">
        <div class="row">
            <div class="col text-center p-2 offset-4">
                <pagination [totalPages]="totalPagesCount" (onPageChange)="onPageChange($event)"></pagination>
            </div>
        </div>
    </div>

</ng-template>

<!-- Saved Searches Modal -->
<ng-template #savedSearches let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Saved Medications</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
    </div>
    <div class="modal-body">
        <datatable [headers]="savedSearchHeaders" [rows]="savedSearchHistory"
            (onHeaderClicked)="onSavedHeaderClick($event)" (onCellClicked)="onSavedCellClick($event)">
        </datatable>
    </div>
    <div class="modal-body" *ngIf="this.savedSearchHistory.length==0">
        <h6>{{this.nosavedMessage}}</h6>
    </div>
</ng-template>

<ng-template #errorPopup let-modal>
    <div class="p-3">
        <div class="row text-center"><i class="fa fa-ban text-danger" aria-hidden="true"></i></div>
        <!-- <div class="text-center mt-2">{{errorPopupText}}</div> -->
        <div class="row border-0 mt-4 p-3 "><button
                class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-outline-primary"
                (click)="modal.dismiss()">OK</button></div>
    </div>
</ng-template>

<!-- Diagnosis Code Modal -->
<ng-template #diagnosisCode let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Search For A Diagnosis Code By Keyword</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div *ngIf="diagnosisDescription && searchDiagnosisClicked && !diagnosisDescription.valid"
        style="padding: 15px; background-color:#fff4f4;">
        <label style="color:#c60f13;"><strong>
                Please enter min 2 characters.
            </strong></label>
    </div>
    <div class="modal-body">
        <!--  NPI Seach Form-->
        <form [formGroup]="diagnosisForm">

            <div style="padding:10px;">
                <label for="text" class="form-label float-left">Enter a Diagnosis Description</label>
                <div class="row">
                    <div class="search col-10">
                        <i class="fa fa-search"></i>
                        <input type="text" class="form-control inp rounded-0"
                            [ngClass]="{'inpSubmittedError alert-danger': (diagnosisDescription && !diagnosisDescription.valid && !diagnosisDescription.pristine)||(diagnosisDescription && searchDiagnosisClicked && !diagnosisDescription.valid)}"
                            id="typeahead-config" type="text" formControlName="diagnosisDescription"
                            style="text-indent: 20px;">
                        <div *ngIf="diagnosisDescription && diagnosisDescription.invalid && diagnosisDescription.dirty">
                            <div class="text-danger"
                                *ngIf="diagnosisDescription && diagnosisDescription.errors?.['minlength'] && !searchDiagnosisClicked">
                                <small>Please enter min 2 characters.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <button type="button" (click)="searchDiagnosisKeyword()"
                            class="btn btn-p float-end">Search</button>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="noOfRecords>0">

                    <div class="col-6">
                        <label class="form-label"><strong>{{noOfRecords}}</strong> results found.</label>
                    </div>
                    <div class="col-6">
                        <!-- Arrow Button for Previous and Next items in list -->
                        <label class="form-label float-end">{{recordsDisplayedCount}} of {{noOfRecords}} &nbsp;
                            <a (click)="loadPreviousDiagnosisKeywords()"><i class="fa fa-chevron-left"
                                    [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                    aria-hidden="true"></i></a>
                            &nbsp;
                            <a (click)="loadNextDiagnosisKeywords()"><i class="fa fa-chevron-right arrowActive"
                                    [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                    aria-hidden="true"></i>
                            </a>
                        </label>
                    </div>
                </div>
                <datatable *ngIf="noOfRecords>0" [headers]="diagnosisSearchHeaders" [rows]="diagnosisSearchHistory"
                    (onHeaderClicked)="onDiagnosisHeaderClick($event)" (onCellClicked)="onDiagnosisCellClick($event)">
                </datatable>
                <!-- <div class="row mt-3" *ngIf="noOfRecords>0">
                    <div class="col-6 float-end">
                         Arrow Button for Previous and Next items in list -->
                <!-- <label class="form-label float-end">{{recordsDisplayedCount}} of {{noOfRecords}} &nbsp;
                            <a (click)="loadPreviousDiagnosisKeywords()"><i class="fa fa-chevron-left"
                                    [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                    aria-hidden="true"></i></a>
                            &nbsp;
                            <a (click)="loadNextDiagnosisKeywords()"><i class="fa fa-chevron-right arrowActive"
                                    [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                    aria-hidden="true"></i>
                            </a>
                        </label> -->
                <!-- </div>
                </div>  -->

                <!-- Select and Cancel buttons -->
                <div *ngIf="noOfRecords>0" class="modal-footer">
                    <button type="button" class="btn btn-p" (click)="modal.close('Close click')">Cancel</button>
                    <button type="button" (click)="selectDiagnosisKeywordClicked()" class="btn btn-p">Select</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!-- Templates for Data Table -->
<ng-template #drugNameTemplate let-data="data">
    <p class="large">&nbsp;{{data.drugDescription}}</p>
</ng-template>

<ng-template #actionRemove let-data="data">
    <div class="row">
        <div class="col-8">
            <a class="large underline text-danger" enableAction="true">Remove</a>
        </div>
    </div>
</ng-template>

<ng-template #actionSelect let-data="data">
    <div class="row">
        <div class="col-4 p-2">
            <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;"></i>
        </div>
    </div>
</ng-template>

<ng-template #recentDrugPrdouctId let-data="data">
    <div class="p-3">
        <p class="large">&nbsp;{{data.productId}}</p>
    </div>
</ng-template>
<ng-template #recentDrugNameTemplate let-data="data">
    <p class="large">{{data.detailedDrugName}}</p>
</ng-template>
<ng-template #actionRecentSearchRemove let-data="data">
    <div class="row">
        <div class="col-8">
            <a class="large underline text-danger mt-3">Remove</a>
        </div>
    </div>
</ng-template>

<ng-template #actionRecentSearchSelect let-data="data">
    <div class="row">
        <div class="col-4 p-2 text-center">
            <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;"></i>
        </div>
    </div>
</ng-template>

<ng-template #diagnosisCodeTemplate let-data="data">
    <p class="large">{{data.diagnosisCode.trim()}}</p>
</ng-template>

<ng-template #diagnosisKeywordTemplate let-data="data">
    <p class="large">{{data.diagnosisDescription.trim()}}</p>
</ng-template>

<ng-template #diagnosisCodeCheckboxTemplate let-data="data">
    <input class="form-check-input" style="margin-left:10px;" enableAction="true" type="checkbox"
        name="diagnosisCodeCheckbox" [checked]="data.selected">
</ng-template>


<ng-template #pharmacyNameTemplate let-data="data">
    <p class="large"><b>{{data.pharmacyName | stringtosentencecase}}</b></p>
</ng-template>

<ng-template #pharmacyAddressTemplate let-data="data">
    <p class="large"><b>{{data.physicalLocation.address1 | stringtosentencecase}}, {{data.physicalLocation.city |
            stringtosentencecase}}, {{data.physicalLocation.state | stringtosentencecase}}, {{data.physicalLocation.zip
            | stringtosentencecase}}</b></p>
</ng-template>
<ng-template #pharmacydistanceTemplate let-data="data">
    <p class="large" *ngIf="data.physicalLocation.distance < 1"><b>
            <1 mi </b>
    </p>
    <p class="large" *ngIf="data.physicalLocation.distance >= 1"><b>{{data.physicalLocation.distance | number:'1.2-2'}}
            mi</b></p>
</ng-template>
<ng-template #actionPharmacySearchSelect let-data="data">
    <div class="row">
        <div class="col-4 p-2">
            <a
                style="font-size: 14px!important; font-family: Frutiger-Bold, Helvetica, Arial, sans-serif; color: #0470c5;">select</a>
        </div>
    </div>
</ng-template>



<!--END of Templates for Data Table -->


<!-- Template for add pharmacy modal popup-->
<ng-template #addPharmacyTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add Pharmacy</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <!--  NPI Seach Form-->
        <form [formGroup]="addPharmacyForm">
            <div class="container-fluid nopadding">
                <div class="row">
                    <div class="col-xs-6 col-sm-3 col-md-2" style="padding-right: 10px;">
                        <div class="col-md-12">
                            <label for="zipcode" class="form-label required">ZIP code</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text" style="width: 100%; padding: 10px;" maxlength="5" name="zipcode"
                                formControlName="zipcode" aria-required="true" (focus)="blnZipCodeFocus=true"
                                (blur)="blnZipCodeFocus=false"
                                [ngClass]="{'inpError': (blnFormSubmitted && !addPharmacyForm.controls['zipcode'].valid)|| (addPharmacyForm.controls['zipcode'].value=='' ) || 
                (!addPharmacyForm.controls['zipcode'].pristine && addPharmacyForm.controls['zipcode'].errors && 
                (addPharmacyForm.controls['zipcode'].errors['pattern'] || (!blnZipCodeFocus && addPharmacyForm.controls['zipcode'].errors['minlength'])))}">
                        </div>
                        <div class="text-danger">
                            <ng-container
                                *ngIf="addPharmacyForm.controls['zipcode'] && addPharmacyForm.controls['zipcode'].errors && addPharmacyForm.controls['zipcode'].errors['pattern']">
                                Invalid Character
                            </ng-container>
                            <ng-container *ngIf="addPharmacyForm.controls['zipcode'] && addPharmacyForm.controls['zipcode'].errors && !addPharmacyForm.controls['zipcode'].errors['pattern'] && 
                            addPharmacyForm.controls['zipcode'].errors['minlength']">
                                Please enter a valid ZIP code.
                            </ng-container>
                            <ng-container
                                *ngIf="!blnZipCodeFocus && addPharmacyForm.controls['zipcode'].value==''||(addPharmacyForm.controls['zipcode'].value==''&& blnFormSubmitted)">
                                Please enter a valid ZIP code.
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-2" style="padding-left:20px;">
                        <div class="container-fluid nopadding">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="form-label required">Distance</label>
                                </div>
                                <div class="col-md-12">
                                    <select class="rounded-0 inp form-select dropdown responsive float-end"
                                        formControlName="distance" style="width: 100%;height: 44px;">
                                        <option value="1">1 &nbsp; miles</option>
                                        <option selected value="5">5 &nbsp; miles</option>
                                        <option value="10">10 &nbsp; miles</option>
                                        <option value="15">15 &nbsp; miles</option>
                                        <option value="25">25 &nbsp; miles</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-8 col-sm-4 col-md-6" style="width: 50%;padding-right: 0px;padding-left:20px;">
                        <label for="text" class="form-label float-left">Pharmacy Name (optional)</label>
                        <input type="text" class="form-control inp rounded-0" id="diagnosisDescription" type="text"
                            formControlName="pharmacyName" style="text-indent: 20px; width: 100%; height: 44px;">
                    </div>
                    <div class="col-xs-4 col-sm-1 col-md-1" style="width: 12%;padding-right: 0px;margin-top: 27px;">
                        <button type="button" class="btn btn-p" tabindex="5" style="padding: 0px;height: 44px;"
                            data-index="5" (click)="searchPharmacy(addPharmacyForm.value)">
                            <i class="fa fa-search"></i>
                            Search
                        </button>
                    </div>
                    <!-- <div class="col-xs-6 col-sm-3 col-md-2" style="padding-right: 0px;">
                        <label for="zipcode" class="form-label required">ZIP code</label>
                        <input type="text" maxlength="5" name="zipcode" formControlName="inputZip"
                            class="rounded-1 form-control inp" aria-required="true"
                            [ngClass]="{'inpError': (blnFormSubmitted && !addPharmacyForm.controls['zipcode'].valid) || 
                (!addPharmacyForm.controls['zipcode'].pristine && addPharmacyForm.controls['zipcode'].errors && 
                (addPharmacyForm.controls['zipcode'].errors['pattern'] || (!blnZipCodeFocus && addPharmacyForm.controls['zipcode'].errors['minlength'])))}" />
                    </div>
                    <div class="text-danger">
                        <ng-container
                            *ngIf="addPharmacyForm.controls['zipcode'] && addPharmacyForm.controls['zipcode'].errors && addPharmacyForm.controls['zipcode'].errors['pattern']">
                            Invalid character
                        </ng-container>
                        <ng-container
                            *ngIf="addPharmacyForm.controls['zipcode'] && addPharmacyForm.controls['zipcode'].errors && !addPharmacyForm.controls['zipcode'].errors['pattern'] && 
                        addPharmacyForm.controls['zipcode'].errors['minlength'] && (blnFormSubmitted || !blnZipCodeFocus)">
                            Please enter a valid ZIP code.
                        </ng-container>
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-2" style="padding-right: 0px;padding-left:20px;">
                    <div class="container-fluid nopadding">
                        <div class="row">
                        <div class="col-md-12">
                            <label>Distance</label>
                        </div>
                        <div class="col-md-12">
                            <select class="rounded-0 inp form-select dropdown responsive float-end">
                                <option>1 &nbsp; miles</option>
                                <option>5 &nbsp; miles</option>
                                <option>10 &nbsp; miles</option>
                                <option>15 &nbsp; miles</option>
                                <option>25 &nbsp; miles</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-xs-8 col-sm-4 col-md-6" style="width: 52%;padding-right: 0px;padding-left:20px;">
                        <label for="text" class="form-label float-left">Pharmacy Name (opional)</label>
                        <div class="row">
                            <div class="search col-10">
                                <i class="fa fa-search"></i>
                                <input type="text" class="form-control inp rounded-0"
                                    [ngClass]="{'inpSubmittedError alert-danger': (diagnosisDescription && !diagnosisDescription.valid && !diagnosisDescription.pristine)||(diagnosisDescription && searchDiagnosisClicked && !diagnosisDescription.valid)}"
                                    id="typeahead-config" type="text" formControlName="diagnosisDescription"
                                    style="text-indent: 20px;">
                                <div
                                    *ngIf="diagnosisDescription && diagnosisDescription.invalid && diagnosisDescription.dirty">
                                    <div class="text-danger"
                                        *ngIf="diagnosisDescription && diagnosisDescription.errors?.['minlength'] && !searchDiagnosisClicked">
                                        <small>Please enter min 2 characters.</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-1 col-md-1"
                                style="width: 12%;padding-right: 0px;margin-top: 27px;">
                                <button type="button" (click)="searchDiagnosisKeyword()"
                                    class="btn btn-p float-end">Search</button>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="row mt-3" *ngIf="noOfRecords>0">

                        <div class="col-6">
                            <label class="form-label"><strong>{{noOfRecords}}</strong> results found.</label>
                        </div>
                        <div class="col-6">
                            Arrow Button for Previous and Next items in list
                            <label class="form-label float-end">{{recordsDisplayedCount}} of {{noOfRecords}} &nbsp;
                                <a (click)="loadPreviousDiagnosisKeywords()"><i class="fa fa-chevron-left"
                                        [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                        aria-hidden="true"></i></a>
                                &nbsp;
                                <a (click)="loadNextDiagnosisKeywords()"><i class="fa fa-chevron-right arrowActive"
                                        [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                        aria-hidden="true"></i>
                                </a>
                            </label>
                        </div>
                    </div>
                     <datatable *ngIf="noOfRecords>0" [headers]="diagnosisSearchHeaders" [rows]="diagnosisSearchHistory"
                        (onHeaderClicked)="onDiagnosisHeaderClick($event)"
                        (onCellClicked)="onDiagnosisCellClick($event)">
                    </datatable>
                    <div *ngIf="noOfRecords>0" class="modal-footer">
                        <button type="button" class="btn btn-p" (click)="modal.close('Close click')">Cancel</button>
                        <button type="button" (click)="selectDiagnosisKeywordClicked()"
                            class="btn btn-p">Select</button>
                    </div>  -->
                </div>

                <div class="row pt-4 pb-4" style="height: 22px;">
                    <div class="col-sm-12">
                        <span style="text-transform: none;line-height: normal;">Select a new pharmacy below.
                            {{this.pharmacynoOfRecords}} pharmacies within
                            {{addPharmacyForm.controls['distance'].value}} mile(s) of
                            {{this.zipCodeSearched}}</span>

                    </div>
                </div>

                <div class="col-sm-12 text-center" id="pharmacyLoader" *ngIf="pharmacyLoader">
                    <div><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i><span style="font-size: 16px;">
                            &nbsp;Finding nearby pharmacies ...</span></div>
                </div>
            </div>
        </form>

        <datatable [headers]="pharmacySearchHeaders" [rows]="pharmacySearchHistory"
            (onHeaderClicked)="onPharmacyHeaderClick($event)" (onCellClicked)="onPharmacyCellClick($event)">
        </datatable>
        <div class="modal-body" *ngIf="this.pharmacySearchHistory.length==0">
            <h6>{{this.errorMessage}}</h6>
        </div>
        <div class="row" style="margin-top: 15px;" *ngIf="this.pharmacySearchHistory.length!=0">
            <div class="col-sm-12">
                <span style="text-transform: none;">Showing {{this.pharmacyrecordsDisplayedCount}} of
                    {{this.pharmacynoOfRecords}} pharmacies</span>
                <span class="float-end">
                    <a (click)="loadPreviousPharmacies()"><i class="fa fa-chevron-left"
                            [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}" aria-hidden="true"
                            *ngIf="this.pharmacySearchHistory!=[]"></i></a>
                    &nbsp;
                    <a (click)="loadNextPharmacies()"><i class="fa fa-chevron-right"
                            [ngClass]="{arrowDisabled: currentPage==pharmacynoOfPages, arrowActive: currentPage<pharmacynoOfPages}"
                            aria-hidden="true" *ngIf="this.pharmacySearchHistory!=[]"></i>
                    </a>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Error Modal -->
<ng-template #errorContent let-modal>
    <div class="p-3" role="dialog" aria-labelledby="errorContent">
        <div class="row text-center"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2" tabindex="-1" aria-modal="true">
            {{errorText}}
        </div>
        <div class="modal-footer border-0 mt-5">
            <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                (click)="modal.dismiss()">ok</button>
        </div>
    </div>
</ng-template>

<ng-template #cancelPAConfirmation let-modal>
    <div class="content-card text-center">
        <div class="card-content">
            <i class="fa fa-exclamation-circle"></i>
            <br /><br />
            <p class="large">
                Are you sure you want to cancel this PA request?
                <br /><br />
                PA Reference ID: {{this.paRefID}}
            </p>
            <br /><br />
            <button class="btn btn-secondary" (click)="modal.dismiss();">No</button>
            &nbsp;&nbsp;
            <button class="btn btn-primary" (click)="modal.dismiss();cancel()">Yes</button>
        </div>
    </div>
</ng-template>